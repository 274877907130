import styled from '@emotion/styled/macro';
import { css, IconButton as MuiIconButton } from '@mui/material';
import { SidebarWrapper } from '../../../../components/sidebar/controls';

export const Wrapper = styled(SidebarWrapper)(
  () => css`
    width: 100%;
    min-height: 13rem;
  `
);

export const SidebarIconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};

    ${theme.breakpoints.down('md')} {
      border-radius: ${theme.shape.borderRadius}px;
    }
  `
) as typeof MuiIconButton;
