import { User } from 'domain/model/user';
import Splitter from 'presentation/components/common/splitter';
import UserBalance from 'presentation/features/user/components/balance';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getPersonFullName } from 'presentation/utils';
import { HeaderUserShort } from '../short';
import { Email, Text } from './controls';

type HeaderUserFullProps = {
  readonly user: User;
  readonly balance: Nullable<number>;
  readonly isFetching?: boolean;
};

export const HeaderUserFull = (props: HeaderUserFullProps) => {
  const { user, balance, isFetching } = props;

  return (
    <MPGrid
      container
      spacing={2}
      wrap='nowrap'
    >
      <MPGrid
        item
        xs={2.5}
      >
        <HeaderUserShort
          size='small'
          user={user}
          isFetching={isFetching}
          balance={null}
        />
      </MPGrid>
      <MPGrid
        item
        xs={9.5}
        zero
      >
        <Text>{getPersonFullName(user)}</Text>
        <Splitter size={0.5} />
        <Email
          color='text.secondary'
          variant='body2'
          component='div'
          title={user.email}
        >
          {user.email}
        </Email>
        {balance !== null && (
          <>
            <Splitter size={1} />
            <UserBalance value={balance} />
          </>
        )}
      </MPGrid>
    </MPGrid>
  );
};
