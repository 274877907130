import React from 'react';

export type TradeOfferSidebarDialogsContextType = {
  readonly openUnavailableDialog: () => void;
  readonly openReactivationDialog: () => void;
  readonly openHelpDialog: () => void;
  readonly onActivate: () => void;
  readonly onShowAllOffers: () => void;
}

const TradeOfferSidebarDialogsContext = React.createContext<TradeOfferSidebarDialogsContextType>(
  null as unknown as TradeOfferSidebarDialogsContextType
);

export default TradeOfferSidebarDialogsContext;
