import { Typography } from '@mui/material';
import { ECorpOfferPromotionType } from '../../../../../../../../../domain/model/enums';
import { EDateTimeFormat } from '../../../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../../../utils/date';
import { getCorpOfferPromotionLabel } from '../../../../../../utils';
import useCorpOfferSidebar from '../../../provider/useCorpOfferSidebar';
import { Wrapper } from './controls';

type PromotionTypeTextProps = {
  readonly promotionType: ECorpOfferPromotionType;
};

const PromotionTypeText = ({ promotionType }: PromotionTypeTextProps) => {
  return (
    <Typography
      variant='body2'
      color='black'
    >
      {getCorpOfferPromotionLabel(promotionType)}
    </Typography>
  );
};

const SidebarPromotionDateActivateNow = () => {
  return (
    <Typography
      variant='body2'
      color='textSuccess'
    >
      получен только что
    </Typography>
  );
};

type TradeOfferSidebarPromotionDateProps = {
  readonly appointmentDate: string;
};

const SidebarPromotionDate = (props: TradeOfferSidebarPromotionDateProps) => {
  const { appointmentDate } = props;
  return (
    <Typography variant='body2'>
      от {utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)}
    </Typography>
  );
};

const CorpOfferPromotionTypeAdapter = () => {
  const { corpOffer, isActivationReceivedNow, activation } = useCorpOfferSidebar();

  if (!corpOffer) {
    return null;
  }

  return (
    <Wrapper>
      <PromotionTypeText promotionType={corpOffer.promotionType} />
      {isActivationReceivedNow && <SidebarPromotionDateActivateNow />}
      {!isActivationReceivedNow && activation.lastActivation?.appointmentDate && (
        <SidebarPromotionDate appointmentDate={activation.lastActivation.appointmentDate} />
      )}
    </Wrapper>
  );
};

export default CorpOfferPromotionTypeAdapter;
