import { Typography } from '@mui/material';
import SidebarMessage from '../../../../../components/sidebar/message';

export const CorpOfferSidebarBalanceNotEnoughWarnMessage = () => {
  return (
    <SidebarMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        У вас недостаточно баллов
      </Typography>
    </SidebarMessage>
  );
};
