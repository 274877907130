import { AxiosStatic, CancelToken } from 'axios';
import activation from './activation';
import address from './address';
import banner from './banner';
import cms from './cms';
import corpOffer from './corpOffer';
import files from './files';
import notification from './notification';
import order from './order';
import partner from './partner';
import product from './productOffer';
import tradeOffer from './tradeOffer';
import user from './user';
import userEvent from './userEvent';
import booking from './booking';

const apiDefinition = {
  files,
  user,
  tradeOffer,
  corpOffer,
  partner,
  notification,
  banner,
  userEvent,
  activation,
  cms,
  product,
  order,
  address,
  booking,
};

export const createCancelToken = (axios: AxiosStatic, signal: AbortSignal): CancelToken => {
  const source = axios.CancelToken.source();

  signal.addEventListener('abort', () => {
    source.cancel();
  });

  return source.token;
};

export default apiDefinition;
