import { Category } from 'domain/model/nsi';
import { BreadcrumbObject } from 'presentation/components/common/breadcrumb/expanded';
import OfferBreadcrumbs from 'presentation/features/offer/components/breadcrumbs';
import { EAppCategories } from 'presentation/types';
import { getBookingOffersListRoute, getBookingOffersSearchRoute } from '../../entry';
import { FC } from 'react';

type BookingOfferBreadcrumbsProps = {
  readonly offerCategories: Nullable<Category[]>;
  readonly dictionary: Nullable<Category[]>;
};

const BookingOfferBreadcrumbs: FC<BookingOfferBreadcrumbsProps> = ({ offerCategories, dictionary }) => {
  const getBreadcrumbLink = (category: Category) => getBookingOffersSearchRoute({ categoryId: category.id });

  const startCategory: BreadcrumbObject = {
    id: '',
    name: EAppCategories.BookingOffers,
    parentId: null,
    link: getBookingOffersListRoute(),
  };

  return (
    <OfferBreadcrumbs
      startCategory={startCategory}
      offerCategories={offerCategories ?? null}
      dictionary={dictionary}
      linkFactory={getBreadcrumbLink}
    />
  );
};

export default BookingOfferBreadcrumbs;
