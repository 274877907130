import { EOfferActivateError, EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { CorpOfferSidebarBalanceNotEnoughWarnMessage } from './balanceNotEnough';
import { CorpOfferSidebarCodesEndsMessage } from './codesEnds';
import { CorpOfferSidebarBusinessErrorMessage } from './businessError';
import { CorpOfferSidebarPendingWarnMessage } from './pendingWarn';
import { CorpOfferSidebarRejectedWarnMessage } from './rejectedWarn';
import { CurrentActivateErrrorPausedOfferWarnMessage } from './currentActivateErrrorPausedOffer';

export const CorpOfferSidebarWarnMessagesAdapter = () => {
  const { corpOffer, isActivationReceivedNow, activation } = useCorpOfferSidebar();

  const isCodesEnded: boolean = (corpOffer?.notUsedOfferCount ?? 0) === 0;

  const { isUserBalanceNotEnough } = activation;

  return useMemo(() => {
    if (!corpOffer) {
      return null;
    } else {
      return (
        <>
          {activation.activationError && activation.activationError.type !== EOfferActivateError.ProfileNotFilled && (
            <CorpOfferSidebarBusinessErrorMessage error={activation.activationError} />
          )}

          {isCodesEnded && isActivationReceivedNow && (
            <CorpOfferSidebarCodesEndsMessage promotionType={corpOffer.promotionType} />
          )}

          {activation.lastActivation?.status === EOfferActivationStatus.Pending && (
            <CorpOfferSidebarPendingWarnMessage />
          )}

          {(corpOffer?.status === EOfferStatus.Active || isActivationReceivedNow) &&
            isUserBalanceNotEnough &&
            !isCodesEnded && <CorpOfferSidebarBalanceNotEnoughWarnMessage />}

          {!isActivationReceivedNow &&
            corpOffer?.status !== EOfferStatus.Archived &&
            activation?.lastActivation?.status === EOfferActivationStatus.Rejected && (
              <CorpOfferSidebarRejectedWarnMessage />
            )}

          {isActivationReceivedNow &&
            corpOffer?.status === EOfferStatus.Paused &&
            activation?.lastActivation?.status === EOfferActivationStatus.Rejected && (
              <CurrentActivateErrrorPausedOfferWarnMessage />
            )}
        </>
      );
    }
  }, [
    corpOffer,
    activation.activationError,
    activation.lastActivation?.status,
    isCodesEnded,
    isActivationReceivedNow,
    isUserBalanceNotEnough,
  ]);
};
