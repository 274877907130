import { EOfferStatus } from 'domain/model/enums';
import { OfferActivationButton } from 'presentation/features/offer/components/activationButton/personal';
import { useMemo } from 'react';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';

export const TradeOfferSidebarActivationAdapter = () => {
  const { tradeOffer, isActivationAvailable, activation, onActivate } = useTradeOfferSidebar();

  return useMemo(() => {
    if (isActivationAvailable && tradeOffer && tradeOffer?.status === EOfferStatus.Active) {
      return activation.lastActivation ? null : (
        <OfferActivationButton
          isFetching={activation.activationIsFetching}
          onActivate={onActivate}
        />
      );
    } else {
      return null;
    }
  }, [isActivationAvailable, tradeOffer, onActivate, activation.lastActivation, activation.activationIsFetching]);
};
