import { useHistory } from 'react-router';
import { getBookingOffersDetailsRoute } from '../../entry';
import BookingOfferTabs from '../tabs';
import { EBookingOfferDetailsTab } from '../types';
import useBookingOfferDetailsData from '../hooks/useBookingOfferDetailsData';

type BookingOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: EBookingOfferDetailsTab;
};

const BookingOfferDetailsTabsAdapter = ({ tab, id, guid }: BookingOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const { bookingOffer } = useBookingOfferDetailsData(id);

  const onChangeTab = (event: React.SyntheticEvent, newTab: EBookingOfferDetailsTab) => {
    history.replace(getBookingOffersDetailsRoute({ id, tab: newTab, guid }));
  };

  if (!bookingOffer) {
    return null;
  }

  return (
    <BookingOfferTabs
      tab={tab}
      id={id}
      bookingOffer={bookingOffer}
      onChange={onChangeTab}
    />
  );
};

export default BookingOfferDetailsTabsAdapter;
