import { Typography } from '@mui/material';
import { ECorpOfferPromotionType, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import { pluralize } from 'utils/pluralize';
import { getOfferNotUsedCountText } from '../../../../../utils';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { Wrapper } from './controls';

type CorpOfferSidebarLeftCodesProps = {
  readonly codesCount: number;
  readonly promotionType: ECorpOfferPromotionType;
};

const CorpOfferSidebarLeftCodes = ({ codesCount, promotionType }: CorpOfferSidebarLeftCodesProps) => {
  return (
    <Typography variant='body2'>
      {codesCount > 0 && (
        <>
          {pluralize(codesCount, ['Остался', 'Осталось', 'Осталось'])}{' '}
          {getOfferNotUsedCountText(codesCount, promotionType)}
        </>
      )}
    </Typography>
  );
};

export const CorpOfferSidebarLeftCodesAdapter = () => {
  const { corpOffer } = useCorpOfferSidebar();

  const notUsedOfferCount: number = corpOffer?.notUsedOfferCount ?? 0;

  return useMemo(
    () =>
      corpOffer?.status === EOfferStatus.Active ? (
        <Wrapper>
          <CorpOfferSidebarLeftCodes
            codesCount={notUsedOfferCount}
            promotionType={corpOffer.promotionType}
          />
        </Wrapper>
      ) : null,
    [corpOffer?.status, corpOffer?.promotionType, notUsedOfferCount]
  );
};
