import styled from '@emotion/styled';
import { IconButton as MuiIconButton, PaperProps } from '@mui/material';
import { forwardRef } from 'react';
import { SidebarWrapper } from '../../../../components/sidebar/controls';

export const Wrapper = styled(SidebarWrapper)`
  max-width: 100%;
`;

export const WidgetWrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Wrapper
      {...props}
      ref={ref}
    />
  ))
)`
  width: 100%;
  height: auto;
`;

export const SidebarIconButton = styled(MuiIconButton)`
  color: ${p => p.theme.palette.text.secondary};

  ${p => p.theme.breakpoints.down('md')} {
    border-radius: ${p => p.theme.shape.borderRadius}px;
  }
` as typeof MuiIconButton;
