import styled from '@emotion/styled/macro';
import { css, FormControlLabel, Grid } from '@mui/material';

export const SwitchControl = styled(FormControlLabel)(
  () => css`
    &.MuiFormControlLabel-labelPlacementStart {
      margin-left: 0;
      display: flex;
    }

    .MuiTypography-root {
      flex: 1;
    }
  `
);

export const SwitchControlGroup = styled(Grid)(
  () => css`
    display: inline-flex;
  `
);
