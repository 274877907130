import { EBookingOfferSortType } from 'domain/model/enums';
import { PriceRangeFilter } from 'domain/model/offer';
import { PaginationSize } from '../../../types';
import { Category } from '../../../../domain/model/nsi';

export type BookingOffersSearchListLocationState = Partial<PriceRangeFilter> & {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly guid?: UUID;
  readonly services?: UUID[];
  readonly sort?: EBookingOfferSortType[];
};

export type BookingOffersListLocationState = {
  readonly guid: UUID;
};

export enum EBookingUrlParam {
  MinPrice = 'minPrice',
  MaxPrice = 'maxPrice',
  Sort = 'sort',
  Services = 'services',
  Tab = 'tab',
}

export type UseBookingOfferList = {
  readonly onChangeSort: (sort: EBookingOfferSortType[]) => void;
  readonly onChangePage: (newPage: number) => void;
  readonly onChangePageSize: (newPageSize: PaginationSize) => void;
  readonly onChangePriceRange: (priceRange: PriceRangeFilter) => void;
  readonly onSelectService: (id: UUID) => void;
  readonly onClearSelectedServices: VoidFunction;
  readonly onChangeCategory: (category: Category) => void;
  readonly onReturnToTopCategory: (id: Nullable<UUID>) => void;
  readonly onResetFilters: VoidFunction;
};
