import { createSelector } from '@reduxjs/toolkit';
import { searchApi } from 'data/api/search';
import { RootState } from 'data/store/store';

export const searchOffersGuidSelector = (store: RootState) => store.search.offers.guid;

export const searchOffersSelector = (store: RootState) => store.search.offers;

export const searchOffersArgsSelector = (store: RootState) => store.search.offers.args;

export const searchOffersOfferTypeSelector = (store: RootState) => store.search.offers.args.offerType ?? null;

export const searchOffersCountSelector = (store: RootState) => {
  const args = store.search.offers.args;

  if (!args) {
    return 0;
  }

  const { data } = searchApi.endpoints.searchOffers.select(args)(store);

  return data?.totalCount ?? 0;
};

export const searchOffersIsErrorSelector = (store: RootState) => {
  const args = store.search.offers.args;

  if (!args) {
    return 0;
  }

  const { isError } = searchApi.endpoints.searchOffers.select(args)(store);
  return isError;
};

export const searchOffersIsFetchingSelector = (store: RootState) => {
  const args = store.search.offers.args;
  return args ? searchApi.endpoints.searchOffers.select(args)(store).isLoading : false;
};

export const searchOffersIsNewFetchingSelector = createSelector(
  searchOffersIsFetchingSelector,
  (store: RootState) => store.search.offers.isNewFetching,
  (isFetching, isNewFetching) => isNewFetching && isFetching
);

export const searchOffersNoDataSelector = createSelector(
  searchOffersCountSelector,
  searchOffersIsFetchingSelector,
  searchOffersIsErrorSelector,
  (count, isFetching, isError) => count === 0 && !isFetching && !isError
);
