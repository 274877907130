import { useGetUserFavoritesBookingsOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType } from 'domain/model/enums';
import CardList from 'presentation/components/common/cardList';
import { OfferFavoriteCommonContainer } from 'presentation/features/offer/favorite';
import { FC, useEffect } from 'react';
import BookingOfferListItem from '../../../../offer/booking/components/listItem';
import { TabProps } from './types';

export const UserFavoritesBookingsOffersContainer: FC<TabProps> = ({ guid, queryGuid }) => {
  const { data, error } = useGetUserFavoritesBookingsOffersQuery({ guid: queryGuid });
  const offers = data?.data;

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  if (!offers) {
    return null;
  }

  return (
    <CardList>
      {offers.map(offer => (
        <BookingOfferListItem
          key={offer.id}
          offer={offer}
          favorite={
            <OfferFavoriteCommonContainer
              stored /*чтобы не скрывался таб после убирания всех сердечек*/
              guid={guid}
              offerId={offer.id}
              offerType={EOfferType.Booking}
            />
          }
        />
      ))}
    </CardList>
  );
};
