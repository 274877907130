import { useGetCmsContainerBannersContentQuery } from 'data/api/cms';
import { CmsBanner, CmsFeatureContext } from 'domain/model/cms';
import { ECmsBannerLinkObjectType, ECmsContainerType } from 'domain/model/enums';
import CmsCategoryCaption from 'presentation/features/cms/components/categoryCaption';
import { FCC, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import GlobalLoaderContainer from '../../header/globalLoader/container';
import { getCorpOffersSearchRoute } from '../../offer/corp/entry';
import { getTradeOffersSearchRoute } from '../../offer/trade/entry';
import CmsComponentBanner from '../components/banner';
import CmsContainerWrapperGrid from '../components/containerWrapper/grid';
import { useGetCmsCategoryBannerList } from '../hooks/useGetCategoryBannerList';
import { CmsOfferCategoriesLayoutType } from '../layouts';
import { CmsContainerTyped } from '../types';

type ExtraProps = { context?: CmsFeatureContext };

export const CmsOfferCategories: CmsContainerTyped<CmsOfferCategoriesLayoutType, ExtraProps> = ({
  header,
  footer,
  layout: Layout,
  components,
  containerType,
  offerType,
  guid,
  onDataLoaded,
  context,
}) => {
  const history = useHistory();

  const bannerIds = useMemo<UUID[]>(() => components?.map(component => component.linkObjectId) ?? [], [components]);

  const { data: banners = [], isFetching } = useGetCmsContainerBannersContentQuery(
    { guid, bannerIds },
    { skip: !bannerIds.length }
  );

  const categoryBannerList = useGetCmsCategoryBannerList(banners);

  const onBannerClick = (banner: CmsBanner) => {
    switch (banner.linkObjectType) {
      case ECmsBannerLinkObjectType.TradeOfferCategory:
        history.push(getTradeOffersSearchRoute({ categoryId: banner.linkObjectId, partnerId: context?.partner?.id }));
        break;
      case ECmsBannerLinkObjectType.CorpOfferCategory:
        history.push(getCorpOffersSearchRoute({ categoryId: banner.linkObjectId, partnerId: context?.partner?.id }));
        break;
      default:
        console.error(
          `Некорректный тип баннера - ${banner.linkObjectType}, ожидается ${ECmsBannerLinkObjectType.TradeOfferCategory} или ${ECmsBannerLinkObjectType.CorpOfferCategory}`
        );
        break;
    }
  };

  //for the future when CategoryOffer increased just add specific types
  const Wrapper = useMemo<FCC>(() => {
    switch (containerType) {
      case ECmsContainerType.Category4Offer:
        return props => (
          <CmsContainerWrapperGrid
            mobileColumnsCount={2}
            {...props}
          />
        );
      default:
        return CmsContainerWrapperGrid;
    }
  }, [containerType]);

  useEffect(() => {
    if (banners && categoryBannerList && !isFetching) onDataLoaded?.();
  }, [banners, categoryBannerList, isFetching, onDataLoaded]);

  const Footer = footer;
  const hasContent = categoryBannerList && categoryBannerList.length > 0;

  if (!categoryBannerList.length) return null;

  return (
    <Layout
      header={header}
      footer={Footer && <Footer hasContent={hasContent} />}
      loader={isFetching && <GlobalLoaderContainer />}
    >
      <Wrapper>
        {categoryBannerList?.map(banner => (
          <CmsComponentBanner
            key={banner.id}
            banner={banner}
            objectType={offerType}
            onClick={() => onBannerClick(banner)}
            Caption={<CmsCategoryCaption>{banner.name}</CmsCategoryCaption>}
          />
        ))}
      </Wrapper>
    </Layout>
  );
};
