import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import OfferSidebarPriceInfo from '../../../../../components/sidebar/priceInfo';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';

export const CorpOfferSidebarPriceAdapter = () => {
  const { corpOffer, isActivationReceivedNow } = useCorpOfferSidebar();

  return useMemo(
    () =>
      corpOffer?.id &&
      (corpOffer.status === EOfferStatus.Active ||
        corpOffer.status === EOfferStatus.Upcoming ||
        isActivationReceivedNow) ? (
        <OfferSidebarPriceInfo
          price={corpOffer.price}
          originalPrice={corpOffer.originalPrice}
        />
      ) : null,
    [corpOffer?.id, corpOffer?.status, corpOffer?.price, corpOffer?.originalPrice, isActivationReceivedNow]
  );
};
