import styled from '@emotion/styled/macro';
import { svgIconClasses, Typography } from '@mui/material';

export const InfoWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Space = styled.div`
  flex: 1;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;

  .${svgIconClasses.root} {
    font-size: 1.25rem;

    ${p => p.theme.breakpoints.down('md')} {
      font-size: 1rem;
    }
  }
`;

export const Price = styled(Typography)`
  margin-left: ${p => p.theme.spacing(0.5)};
` as typeof Typography;

export const OriginalPrice = styled(Typography)`
  text-decoration: line-through;
  margin-left: ${p => p.theme.spacing()};
`;
