import { Typography } from '@mui/material';
import { PromotionButtonWrapper } from '../../trade/details/sidebar/controls';
import { DownloadLabel, StyledDottedButton, StyledDownloadIcon, TextLabel } from './controls';
import DownloadVoucherButton from '../../trade/details/sidebar/adapters/downloadVoucherButton';

type OfferPromotionDownloadProps = {
  readonly label: string;
  readonly isFetching: boolean;
  readonly onClick: () => void;
};

export const OfferPromotionDownload = (props: OfferPromotionDownloadProps) => {
  const { label, onClick, isFetching } = props;

  return (
    <PromotionButtonWrapper>
      <DownloadVoucherButton
        fullWidth
        isFetching={isFetching}
        buttonComponent={StyledDottedButton}
        variant='outlined'
        onClick={onClick}
      >
        <>
          <TextLabel color='textPrimary'>{label}</TextLabel>
          <DownloadLabel>
            <StyledDownloadIcon fontSize='micro' />
            <Typography variant='body2'>скачать</Typography>
          </DownloadLabel>
        </>
      </DownloadVoucherButton>
    </PromotionButtonWrapper>
  );
};
