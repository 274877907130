import { useGetCorpOfferDetailsQuery } from 'data/api/corpOffer';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';

const useCorpOfferDetailsData = (id: UUID) => {
  const {
    data: corpOffer,
    error: corpOfferFetchError,
    isError: isCorpOfferError,
    isFetching: isCorpOfferFetching,
    isLoading: isCorpOfferLoading,
    isSuccess: isCorpOfferFetched,
  } = useGetCorpOfferDetailsQuery({ id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (corpOfferFetchError) {
      ErrorHandler.handleHttpError(corpOfferFetchError);
    }
  }, [corpOfferFetchError]);

  return {
    corpOffer,
    corpOfferFetchError,
    isCorpOfferError,
    isCorpOfferFetching,
    isCorpOfferFetched,
    isCorpOfferLoading,
  };
};

export default useCorpOfferDetailsData;
