import React from 'react';

export type CorpOfferSidebarDialogsContextType = {
  readonly openUnavailableDialog: () => void;
  readonly openNdflHelpDialog: () => void;
  readonly openCertificateDialog: () => void;
  readonly onShowAllOffers: () => void;
};

const CorpOfferSidebarDialogsContext = React.createContext<CorpOfferSidebarDialogsContextType>(
  null as unknown as CorpOfferSidebarDialogsContextType
);

export default CorpOfferSidebarDialogsContext;
