import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import { UserProfileEditAttribute } from './attribute';
import { UserProfileEditAttributeProps } from './types';

type UserProfileEditAttributeEmailProps = UserProfileEditAttributeProps<'email'>;

export const UserProfileEditAttributeEmail = (props: UserProfileEditAttributeEmailProps) => {
  const { label, value, validation, isFetching, onChange } = props;

  return (
    <UserProfileEditAttribute {...props}>
      <MPFormInput
        label={label}
        value={value ?? ''}
        error={!!validation?.hasError}
        helperText={validation?.message}
        disabled={isFetching}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
      />
    </UserProfileEditAttribute>
  );
};
