import { FabProps } from '@mui/material';
import { AnonymousAvatar } from 'presentation/components/common/avatars';
import { MPFab } from 'presentation/theme/ui-kit/button';
import { forwardRef, MouseEvent } from 'react';

type HeaderUserAnonymousProps = Pick<FabProps, 'size'> & {
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const HeaderUserAnonymous = forwardRef((props: HeaderUserAnonymousProps, ref: any) => {
  const { size = 'medium', onClick } = props;

  return (
    <MPFab
      ref={ref}
      size={size}
      variant='squared'
      onClick={onClick}
    >
      <AnonymousAvatar size='inherit' />
    </MPFab>
  );
});
