import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { isValidPhoneFormat } from 'presentation/utils/phone';
import { useState } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';
import ConfirmButtons from '../../../../../theme/ui-kit/dialog/buttons';
import { MPPhoneInput } from '../../../../../theme/ui-kit/input';
import { ConfirmPhoneNumberDialogInternalProps, ConfirmPhoneNumberDialogProps } from './types';

export const IsPhoneInvalidFormatDialog = ({
  phone,
  onClose,
  onSubmit,
}: ConfirmPhoneNumberDialogProps & ConfirmPhoneNumberDialogInternalProps) => {
  const [localPhone, setLocalPhone] = useState<string>('');
  const [error, setError] = useState<Nullable<string>>(null);

  const onCancel = () => {
    onClose();
  };

  const onChangeInternal = (value: string) => {
    setError(null);
    setLocalPhone(value);
  };

  const onSubmitInternal = () => {
    const isValidPhone = localPhone && isValidPhoneFormat(localPhone);
    if (isValidPhone) {
      onSubmit(localPhone);
      setError(null);
    } else {
      setError('Введите корректный номер телефона');
    }
  };

  return (
    <MPConfirmDialog
      title='Телефон'
      open={true}
      disableEscapeKeyDown
      maxWidth={'xs'}
      buttons={
        <ConfirmButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onSubmitInternal}
          >
            Отправить
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onCancel}
          >
            Отмена
          </MPButton>
        </ConfirmButtons>
      }
      onClose={onCancel}
    >
      <Typography
        color='textPrimary'
        variant='body1'
      >
        Ваш номер{' '}
        <Typography
          display='inline'
          variant='subtitle1'
        >
          {phone}
        </Typography>{' '}
        введен в неверном формате.
        <Typography>Пожалуйста, введите номер телефона заново.</Typography>
      </Typography>
      <Splitter size={2} />
      <MPPhoneInput
        value={localPhone ?? ''}
        helperText={error}
        error={!!error}
        onChange={onChangeInternal}
      />
    </MPConfirmDialog>
  );
};
