import { Location } from 'history';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo } from 'react';
import { getPartnerPersonalAccountUrl, getSupportEmail } from '../../../../utils';
import Feedback from './feedback';
import HeaderLink from './link';

type GetLinksProps = {
  readonly canProductOffersView?: boolean;
  readonly canTradeOffersView?: boolean;
  readonly canCorpOffersView?: boolean;
};

export enum EHeaderLink {
  Products = 'products',
  Partner = 'partner',
  Support = 'support',
  Feedback = 'feedback',
}

export type HeaderLinksProps = GetLinksProps & {
  readonly isActiveLink: (to: string | Location) => boolean;
};

const HeaderLinks = () => {
  const linkComponents = useMemo(() => {
    const result = [];

    if (getPartnerPersonalAccountUrl()) {
      result.push({
        type: EHeaderLink.Partner,
        component: (
          <HeaderLink
            text='Личный кабинет партнёра'
            target='_blank'
            href={getPartnerPersonalAccountUrl()}
            component='a'
          />
        ),
      });
    }

    if (getSupportEmail()) {
      result.push({
        type: EHeaderLink.Support,
        component: (
          <HeaderLink
            text={getSupportEmail()}
            href={`mailto:${getSupportEmail()}`}
            target='_blank'
            component='a'
          />
        ),
      });
    }

    result.push({
      type: EHeaderLink.Feedback,
      component: <Feedback />,
    });

    return result;
  }, []);

  return (
    <MPGrid
      container
      spacing={4}
      alignItems='center'
      wrap='nowrap'
    >
      {linkComponents.map(({ component }, index) => (
        <MPGrid
          key={index}
          item
        >
          {component}
        </MPGrid>
      ))}
    </MPGrid>
  );
};

export default HeaderLinks;
