import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SearchOffersDataContainer from '../dataContainer';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { searchOffersArgsSelector, searchOffersGuidSelector } from '../store/selectors';

type SearchOffersDataAdapterProps = {
  readonly guid: UUID;
};

const SearchOffersDataAdapter = ({ guid }: SearchOffersDataAdapterProps) => {
  const handlers = useContextHandlers();

  const currentGuid = useSelector(searchOffersGuidSelector);
  const args = useSelector(searchOffersArgsSelector);

  return useMemo(
    () =>
      //  проверка на разность guid при апдейте компонента
      //  если разные - не рендерим, чтобы не показывать старые данные
      guid !== currentGuid || !args ? null : (
        <SearchOffersDataContainer
          guid={guid}
          args={args}
          onLoadMore={handlers.onLoadMore}
          onShowCard={handlers.onShowCard}
        />
      ),
    [currentGuid, args, guid, handlers.onLoadMore, handlers.onShowCard]
  );
};

export default SearchOffersDataAdapter;
