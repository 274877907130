export enum EDateFormat {
  Server = 'YYYY-MM-DD',
  DisplayDefault = 'DD.MM.YYYY',
  DisplayDefaultShort = 'DD.MM.YY',
  Human = 'D MMMM YYYY',
  HumanShort = 'D MMMM',
}

export enum EDateTimeFormat {
  Server = 'YYYY-MM-DDTHH:mm:ss',
  Human = 'D MMMM YYYY HH:mm',
  HumanShort = 'D MMMM HH:mm',
  DisplayDefault = 'DD.MM.YY - HH:mm',
}

export enum ETimeFormat {
  Default = 'HH:mm',
}

export enum EDateMask {
  PickerDefault = '__.__.____',
}
