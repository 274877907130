import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { CertificateButtonWrapper, StyledDottedButton } from './controls';

type CorpOfferSidebarCertificateActivationProps = {
  readonly disabled: boolean;
  readonly isFetching: boolean;
  readonly onBuy: () => void;
};

export const CorpOfferSidebarCertificateActivation = (props: CorpOfferSidebarCertificateActivationProps) => {
  const { disabled, isFetching, onBuy } = props;

  return (
    <CertificateButtonWrapper>
      <StyledDottedButton
        disabled={disabled}
        fetching={isFetching}
        fullWidth
        onClick={onBuy}
      >
        <Typography
          variant='h2'
          color={isFetching ? 'transparent' : undefined}
        >
          Обменять
        </Typography>
        {isFetching && <ContentLoader />}
      </StyledDottedButton>
    </CertificateButtonWrapper>
  );
};
