import { SearchOffersRequest } from 'data/api/search';
import { paginationSizeVariant } from 'domain/model/constants';
import { EOfferType } from 'domain/model/enums';
import { EAppCategories } from 'presentation/types';
import { getBookingOfferSearchResultsRoute, routing as bookingOfferRouting } from '../offer/booking/routes';
import { getCorpOfferSearchResultsRoute, routing as corpOfferRouting } from '../offer/corp/routes';
import { getTradeOfferSearchResultsRoute, routing as tradeOfferRouting } from '../offer/trade/routes';

export const searchOffersDefaultParams: SearchOffersRequest = {
  page: 1,
  pageSize: paginationSizeVariant[0],
  sort: null,
  offerType: [EOfferType.Corp, EOfferType.Trade, EOfferType.Booking],
  query: null,
};

export const searchSections = [
  {
    sectionPath: tradeOfferRouting.list,
    getSectionSearchRoute: getTradeOfferSearchResultsRoute,
  },
  {
    sectionPath: corpOfferRouting.list,
    getSectionSearchRoute: getCorpOfferSearchResultsRoute,
  },
  {
    sectionPath: bookingOfferRouting.list,
    getSectionSearchRoute: getBookingOfferSearchResultsRoute,
  },
];

export const searchTitleByOfferType: Record<EOfferType, EAppCategories> = {
  [EOfferType.Trade]: EAppCategories.TradeOffers,
  [EOfferType.Corp]: EAppCategories.CorpOffers,
  [EOfferType.Booking]: EAppCategories.BookingOffers,
  [EOfferType.Product]: EAppCategories.ProductOffers,
};
