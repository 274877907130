import OfferPromotionCopy from '../../../../../components/promotionCopy';

type TradeOfferSidebarCodePromotionProps = {
  readonly code: string;
  readonly onCopied?: () => void;
  readonly copied: boolean;
  readonly needCopy: boolean;
  readonly onNeedCopy: (value: boolean) => void;
};

export const TradeOfferSidebarCodePromotion = (props: TradeOfferSidebarCodePromotionProps) => {
  const { code, onCopied, onNeedCopy, copied, needCopy } = props;

  return (
    <OfferPromotionCopy
      label={code}
      onCopy={onCopied}
      onNeedCopy={onNeedCopy}
      copied={copied}
      needCopy={needCopy}
    />
  );
};
