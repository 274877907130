import React from 'react';
import { WidgetWrapper } from './controls';

type SidebarWidgetWrapperProps = {
  readonly children: React.ReactNode;
};

const TradeOfferSidebarWidgetWrapper = React.forwardRef(({ children }: SidebarWidgetWrapperProps, ref: any) => {
  return <WidgetWrapper ref={ref}>{children}</WidgetWrapper>;
});

export default TradeOfferSidebarWidgetWrapper;
