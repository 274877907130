import { Typography } from '@mui/material';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { Wrapper } from './controls';

const OfferCodeAdapter = () => {
  const { corpOffer } = useCorpOfferSidebar();
  if (!corpOffer) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Предложение: {corpOffer.code}</Typography>
    </Wrapper>
  );
};

export default OfferCodeAdapter;
