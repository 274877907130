import { ECurrencyType } from 'domain/model/enums';
import { ELocaleShort } from './presentation/types';

export const locale = {
  short: ELocaleShort.Ru,
  full: 'ru-RU',
  isoCode: 'Rub',
};

export const localeCurrencyMap = {
  [ECurrencyType.Point]: locale.full,
};

export enum currencySymbols {
  RU = '₽',
}
