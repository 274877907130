import { AppConfigurator } from 'system/appConfigurator';
import { EAppFeature } from '../../../../types';
import TechConfigContext from '../contexts/techConfigContext';

type TechConfigProviderProps = {
  readonly children: any;
};

const TechConfigProvider = ({ children }: TechConfigProviderProps) => {
  const isDebug = AppConfigurator.getInstance().isDebug();

  const hasFeature = (feature: EAppFeature) => {
    return AppConfigurator.getInstance().hasFeature(feature);
  };

  const state = {
    isDebug,
    hasFeature,
  };

  return <TechConfigContext.Provider value={state}>{children}</TechConfigContext.Provider>;
};

export default TechConfigProvider;
