import styled from '@emotion/styled';
import { CarouselCommonWrapper } from '../../../../../../components/common/carousels/gallery/controls';

type BadgeContentWrapperProps = {
  readonly backgroundColor: string;
};

export const BadgeContentWrapper = styled.div<BadgeContentWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;

  > * {
    position: absolute;
    top: 0;
    right: 0;
  }

  > *:first-child {
    width: 50px;
    font-size: 51px;
    color: ${p => p.backgroundColor};
    path {
      fill: ${p => p.backgroundColor};
    }
  }

  > *:last-child {
    top: 7px;
    right: 7px;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${CarouselCommonWrapper} {
    //max-height: 80vh;
  }
`;
