export enum ECatalogUrlParam {
  Category = 'category',
}

export enum ECatalogTab {
  TradeOffers = 'trade-offers',
  ProductOffers = 'product-offers',
  CorpOffers = 'corp-offers',
  BookingOffers = 'booking-offers',
}

export type CatalogTabInfo = {
  readonly label: string;
  readonly value: ECatalogTab;
};
