import { EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';
import { TradeOfferSidebarArchivedMessage } from './archived';
import { TradeOfferSidebarPausedMessage } from './paused';
import { TradeOfferSidebarUnavailableMessage } from './unavailable';
import { TradeOfferSidebarUpcomingMessage } from './upcoming';

export const TradeOfferSidebarInfoMessagesAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, isActivationAvailable } = useTradeOfferSidebar();

  return useMemo(() => {
    if (!tradeOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return <TradeOfferSidebarUnavailableMessage />;
      } else {
        return (
          <>
            {tradeOffer.status === EOfferStatus.Upcoming && (
              <TradeOfferSidebarUpcomingMessage startDate={tradeOffer.startDate} />
            )}

            {tradeOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && (
              <TradeOfferSidebarPausedMessage />
            )}

            {tradeOffer.status === EOfferStatus.Archived && <TradeOfferSidebarArchivedMessage />}
          </>
        );
      }
    }
  }, [tradeOffer, isActivationAvailable, isActivationReceivedNow]);
};
