import { useSubscribeToUserOfferMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { EOfferType, OfferPromotionType } from 'domain/model/enums';
import { ProductOfferShort } from 'domain/model/productOffer';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { User } from 'domain/model/user';
import { useCallback, useEffect } from 'react';
import { isOfferSubscriptionSupportedByPromotionType } from '../utils';

type UseUserOfferSubscriptionProps = {
  readonly offer: Nullable<TradeOfferShort | CorpOfferShort | ProductOfferShort>;
  readonly offerType: EOfferType;
  readonly promotionType?: OfferPromotionType;
  readonly user: User;
};

export type OfferSubscriptionState = {
  readonly isEnabled: boolean;
  readonly isFetching: boolean;
  readonly isSupported: boolean;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe: () => void;
};

const useOfferSubscription = (props: UseUserOfferSubscriptionProps): OfferSubscriptionState => {
  const { offer, offerType, promotionType } = props;

  const [subscribeToOffer, subscribeToOfferResult] = useSubscribeToUserOfferMutation();

  const { isLoading: isFetching, error } = subscribeToOfferResult;

  const onSubscribe = useCallback(() => {
    subscribeToOffer({
      offerId: offer?.id ?? '',
      offerType,
      subscribe: true,
    });
  }, [offer?.id, offerType, subscribeToOffer]);

  const onUnSubscribe = useCallback(() => {
    subscribeToOffer({
      offerId: offer?.id ?? '',
      offerType,
      subscribe: false,
    });
  }, [offer?.id, offerType, subscribeToOffer]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const isEnabled = offer?.subscribe ?? false;
  const isSupported = !promotionType || isOfferSubscriptionSupportedByPromotionType(promotionType);

  return {
    isEnabled,
    isFetching,
    isSupported,
    onSubscribe,
    onUnSubscribe,
  };
};

export default useOfferSubscription;
