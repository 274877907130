import { createApi } from '@reduxjs/toolkit/query/react';
import { EOfferStatus, EOfferType, OfferSortTypes } from 'domain/model/enums';
import { axiosBaseQuery } from './utils';

export enum ECorpOfferServicesTag {
  Details = 'CorpOffer-Details',
  List = 'CorpOffer-List',
  ByPartnerList = 'CorpOffer-ByPartnerList',
  FavoriteList = 'CorpOffer-FavoriteList',
  Activations = 'CorpOffer-Activations',
}

export enum EAgreementServicesTag {
  Check = 'Check',
}

export enum ETradeOfferServicesTag {
  Details = 'TradeOffer-Details',
  List = 'TradeOffer-List',
  ByPartnerList = 'TradeOffer-ByPartnerList',
  FavoriteList = 'TradeOffer-FavoriteList',
  Activations = 'TradeOffer-Activations',
}

export enum EBookingOfferServicesTag {
  Details = 'BookingOffer-Details',
  List = 'BookingOffer-List',
  ByPartnerList = 'BookingOffer-ByPartnerList',
  FavoriteList = 'BookingOffer-FavoriteList',
}

export enum EProductOfferServicesTag {
  Details = 'ProductOffer-Details',
  List = 'ProductOffer-List',
  ByPartnerList = 'ProductOffer-ByPartnerList',
  FavoriteList = 'ProductOffer-FavoriteList',
}

export enum EUserServicesTag {
  Current = 'User-Current',
  Notification = 'User-Notification',
  Location = 'User-Location',
  Activations = 'User-Activations',
  ActivationsCount = 'User-ActivationsCount',
  Favorites = 'User-Favorites',
  OrdersCount = 'User-OrdersCount',
}

export enum ECartServicesTag {
  Cart = 'Cart',
}

export enum EOrderServicesTag {
  Orders = 'Order',
}

export enum ECacheServicesTag {
  Common = 'common',
}

export enum ESearchServicesTag {
  Offers = 'Search-Offers',
}

export const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    ...Object.values(ETradeOfferServicesTag),
    ...Object.values(EBookingOfferServicesTag),
    ...Object.values(ECorpOfferServicesTag),
    ...Object.values(EProductOfferServicesTag),
    ...Object.values(EUserServicesTag),
    ...Object.values(ECartServicesTag),
    ...Object.values(EOrderServicesTag),
    ...Object.values(ECacheServicesTag),
    ...Object.values(EAgreementServicesTag),
    ...Object.values(ESearchServicesTag),
  ],
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

// used to share data from queries/mutation between different screens
export const cacheKeys = {
  order: { fixedCacheKey: 'orders-data-cache-key' },
};

export type OfferListRequest = {
  readonly search: {
    readonly name: Nullable<string>;
    readonly categories: Nullable<UUID[]>;
    readonly statuses?: EOfferStatus[];
    readonly partnerId?: Nullable<UUID>;
  };
  readonly page: number;
  readonly pageSize: number;
  readonly sort: Nullable<OfferSortTypes[]>;
};

export type OfferDetailsRequest = {
  readonly id: UUID;
};

export type OffersCountRequest = {
  readonly search: {
    readonly name: Nullable<string>;
    readonly categories: Nullable<UUID[]>;
  };
};

export type OffersCountResponse = {
  readonly count: number;
  readonly discriminator: string;
};

export type OfferFavoriteActionRequest = {
  readonly id: UUID;
  readonly offerType: EOfferType;
};
