import * as H from 'history';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Location } from 'history';
import { v4 as uuidv4 } from 'uuid';
import BookingOfferScreen from '../../../screen/bookingOffer';
import BookingOfferOrder from '../../../screen/bookingOfferOrder';
import BookingOffersScreen from '../../../screen/bookingOffers';
import { ECatalogUrlParam } from '../../catalog/types';
import { EGlobalUrlParam } from '../../header/utils';
import { EBookingOfferDetailsTab } from './details/types';
import { BookingOffersListLocationState, BookingOffersSearchListLocationState, EBookingUrlParam } from './types';
import BookingOffersSearchResultsScreen from '../../../screen/bookingOffers/search';
import { routing } from './routes';

type GetBookingOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: EBookingOfferDetailsTab;
};

type GetBookingOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export const getBookingOffersSearchRoute = (
  props: BookingOffersSearchListLocationState
): H.Location<Pick<BookingOffersSearchListLocationState, 'guid'>> => {
  const { name, categoryId, guid, minPrice, maxPrice, sort, services } = props;

  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(ECatalogUrlParam.Category, categoryId);
  }

  if (typeof minPrice === 'number') {
    params.append(EBookingUrlParam.MinPrice, String(minPrice));
  }

  if (typeof maxPrice === 'number') {
    params.append(EBookingUrlParam.MaxPrice, String(maxPrice));
  }

  if (sort) {
    params.append(EBookingUrlParam.Sort, sort.join('+'));
  }

  if (typeof services === 'object') {
    params.append(EBookingUrlParam.Services, services.join(','));
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersListRoute = (
  props?: GetBookingOffersListRouteProps
): Location<BookingOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersDetailsOrderRoute = ({
  id,
  guid,
}: GetBookingOfferDetailsRouteProps): H.Location<Pick<BookingOffersSearchListLocationState, 'guid'>> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  return {
    pathname: `${routing.order.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getBookingOffersDetailsRoute = ({
  id,
  tab,
  guid,
}: GetBookingOfferDetailsRouteProps): H.Location<Pick<BookingOffersSearchListLocationState, 'guid'>> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  if (tab) {
    params.append(EBookingUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const BookingOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={BookingOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={BookingOffersSearchResultsScreen}
      />
      <Route
        exact
        path={routing.details}
        component={BookingOfferScreen}
      />
      <Route
        exact
        path={routing.order}
        component={BookingOfferOrder}
      />
      <Redirect to={routing.list} />
    </Switch>
  );
};

export default BookingOfferEntry;
