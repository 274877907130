import { Typography } from '@mui/material';
import SidebarMessage from '../../../../../components/sidebar/message';

export const CorpOfferSidebarUnavailableMessage = () => {
  return (
    <SidebarMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        Предложение недоступно в вашем регионе или предназначено только для корпоративных пользователей
      </Typography>
    </SidebarMessage>
  );
};
