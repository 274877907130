import { SearchOffersRequest, useSearchOffersQuery } from 'data/api/search';
import ErrorHandler from 'data/network/errorHandler';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { EOfferShortDiscriminator, EOfferType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import CorpOfferListItem from 'presentation/features/offer/corp/components/listItem';
import { OfferFavoriteCommonContainer } from 'presentation/features/offer/favorite';
import TradeOfferListItem from 'presentation/features/offer/trade/components/listItem';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '../../auth/provider/useAuth';
import BookingOfferListItem from '../../offer/booking/components/listItem';
import { searchOffersIsFetchingSelector, searchOffersIsNewFetchingSelector } from './store/selectors';

type SearchOffersDataContainerProps = {
  readonly guid: UUID;
  readonly args: SearchOffersRequest;
  readonly onLoadMore: () => void;
  readonly onShowCard: (offer: TradeOfferShort | CorpOfferShort) => void;
};

const SearchOffersDataContainer = (props: SearchOffersDataContainerProps) => {
  const { guid, args, onLoadMore, onShowCard } = props;

  const { isAuthenticated } = useAuth();
  const isFetching = useSelector(searchOffersIsFetchingSelector);
  const isNewFetching = useSelector(searchOffersIsNewFetchingSelector);

  const { data, error, isSuccess } = useSearchOffersQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const offers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!offers && offers.length > 0;
  const isMoreFetching = !!offers && isFetching;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      <CardPageableList
        span={3}
        lgSpan={4}
        totalCount={totalCount}
        disableLoadMore={isMoreFetching}
        onLoadMore={onLoadMore}
      >
        {offers.map(offer => (
          <Fragment key={offer.id}>
            {offer.discriminator === EOfferShortDiscriminator.Trade && (
              <InView
                active={isSuccess && !isFetching}
                sessionKey={sessionKey}
                data={offer}
                onShow={onShowCard}
              >
                <TradeOfferListItem
                  offer={offer}
                  favorite={
                    isAuthenticated && (
                      <OfferFavoriteCommonContainer
                        guid={guid}
                        offerId={offer.id}
                        offerType={EOfferType.Trade}
                      />
                    )
                  }
                />
              </InView>
            )}
            {offer.discriminator === EOfferShortDiscriminator.Corp && (
              <InView
                active={isSuccess && !isFetching}
                sessionKey={sessionKey}
                data={offer}
                onShow={onShowCard}
              >
                <CorpOfferListItem
                  offer={offer}
                  favorite={
                    isAuthenticated && (
                      <OfferFavoriteCommonContainer
                        guid={guid}
                        offerId={offer.id}
                        offerType={EOfferType.Corp}
                      />
                    )
                  }
                />
              </InView>
            )}
            {offer.discriminator === EOfferShortDiscriminator.Booking && (
              <BookingOfferListItem
                offer={offer}
                favorite={
                  isAuthenticated && (
                    <OfferFavoriteCommonContainer
                      guid={guid}
                      offerId={offer.id}
                      offerType={EOfferType.Booking}
                    />
                  )
                }
              />
            )}
          </Fragment>
        ))}
      </CardPageableList>
    </div>
  );
};

export default SearchOffersDataContainer;
