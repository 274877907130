import { Typography } from '@mui/material';
import { ECorpOfferPromotionType, EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { PlusIcon } from '../../../../../../../media/icons';
import { MPLink } from '../../../../../../../theme/ui-kit/link';
import useCorpOfferSidebarDialogs from '../../dialogProvider/useDialogs';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';

type CorpOfferSidebarPromotionNewProps = {
  readonly disabled?: boolean;
  readonly activationIsFetching: boolean;
  readonly onOpenReactivationDialog: () => void;
};

const CorpOfferSidebarPromotionNew = (props: CorpOfferSidebarPromotionNewProps) => {
  const { disabled, activationIsFetching, onOpenReactivationDialog } = props;

  const isUnavailable = activationIsFetching || disabled;

  return (
    <MPLink
      color={isUnavailable ? 'secondary.main' : 'primary.main'}
      iconSize='small'
      startIcon={PlusIcon}
      onClick={isUnavailable ? undefined : onOpenReactivationDialog}
    >
      <Typography
        variant='body1'
        role='button'
      >
        обменять на новый
      </Typography>
      {activationIsFetching && <ContentLoader size={15} />}
    </MPLink>
  );
};

export const CorpOfferSidebarPromotionNewAdapter = () => {
  const { corpOffer, activation } = useCorpOfferSidebar();
  const { isActivationAvailable } = activation;
  const { openCertificateDialog } = useCorpOfferSidebarDialogs();

  const { isReactivationSupported, activationIsFetching } = activation;

  const isCodesEnded = (corpOffer?.notUsedOfferCount ?? 0) === 0;

  const isNotExternal = corpOffer?.promotionType !== ECorpOfferPromotionType.ExternalCertificate;

  return useMemo(
    () =>
      corpOffer?.status === EOfferStatus.Active &&
      !isCodesEnded &&
      isReactivationSupported &&
      isActivationAvailable &&
      (activation.lastActivation?.status === EOfferActivationStatus.Approved ||
        activation.lastActivation?.status === EOfferActivationStatus.Given) &&
      isNotExternal ? (
        <CorpOfferSidebarPromotionNew
          disabled={activation?.isUserBalanceNotEnough}
          activationIsFetching={activationIsFetching}
          onOpenReactivationDialog={openCertificateDialog}
        />
      ) : null,
    [
      corpOffer?.status,
      isCodesEnded,
      isReactivationSupported,
      isActivationAvailable,
      activation?.isUserBalanceNotEnough,
      activation.lastActivation?.status,
      isNotExternal,
      activationIsFetching,
      openCertificateDialog,
    ]
  );
};
