import { Typography } from '@mui/material';
import { MPChip } from 'presentation/theme/ui-kit/chip';

type SidebarTitleProps = {
  readonly text: string;
};

const SidebarTitle = ({ text }: SidebarTitleProps) => {
  return (
    <MPChip
      size='micro'
      label={
        <Typography
          fontWeight={500}
          variant='body2'
        >
          {text}
        </Typography>
      }
      color='warning'
    />
  );
};

export default SidebarTitle;
