import styled from '@emotion/styled/macro';
import { css, Typography } from '@mui/material';
import { MPButton, MPFab } from '../../../../theme/ui-kit/button';

export const StyledBackButton = styled(MPButton)(
  ({ theme }) => css`
    width: 100%;
    justify-content: flex-start;
    background: ${theme.palette.secondary.A400};
    border-radius: 0;
  `
);

export const BackIconWrapper = styled(MPFab)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
    min-width: auto;
    min-height: auto;

    margin-right: ${theme.spacing()};
    color: ${theme.palette.text.primary};

    z-index: 0;
  `
);

export const Text = styled(Typography)(
  () => css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
);
