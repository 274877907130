import styled from '@emotion/styled/macro';
import { buttonClasses, css, svgIconClasses, Typography } from '@mui/material';
import DottedButton from '../../../../components/common/buttons/dotted';
import { DownloadIcon } from '../../../../media/icons';

export const StyledDottedButton = styled(DottedButton)(
  ({ theme }) => css`
    &.${buttonClasses.root} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      background-color: ${theme.palette.primary.A500};
    }
  `
);

export const StyledDownloadIcon = styled(DownloadIcon)(
  () => css`
    &.${svgIconClasses.root} {
      margin-right: 0.25rem;
      margin-left: 0 !important;
    }
  `
);

export const TextLabel = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    font-weight: ${theme.typography.fontWeightMedium};
    align-items: center;
  `
);

export const DownloadLabel = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `
);
