import { Typography } from '@mui/material';
import { phoneFormatView } from 'presentation/utils/phone';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';
import ConfirmButtons from '../../../../../theme/ui-kit/dialog/buttons';
import { ConfirmPhoneNumberDialogInternalProps, ConfirmPhoneNumberDialogProps } from './types';

export const IsPhoneExistsDialog = ({
  phone,
  onSubmit,
  onChangeEditMode,
  onClose,
}: ConfirmPhoneNumberDialogProps & ConfirmPhoneNumberDialogInternalProps) => {
  return (
    <MPConfirmDialog
      title='Телефон'
      text={
        <Typography
          color='textPrimary'
          variant='body1'
        >
          Номер{' '}
          <Typography
            display='inline'
            variant='subtitle1'
          >
            {phoneFormatView(phone)}
          </Typography>{' '}
          ваш? Менеджер перезвонит на него для уточнения деталей по заказу.
        </Typography>
      }
      open={true}
      disableEscapeKeyDown
      maxWidth={'xs'}
      buttons={
        <ConfirmButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={() => onSubmit(phone)}
          >
            Да, это мой номер
          </MPButton>
          {onChangeEditMode && (
            <MPButton
              color='brand'
              variant='text'
              fullWidth
              size='large'
              onClick={() => onChangeEditMode(true)}
            >
              Указать номер телефона
            </MPButton>
          )}
        </ConfirmButtons>
      }
      onClose={onClose}
    />
  );
};
