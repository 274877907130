import { EOfferStatus } from 'domain/model/enums';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';
import { StyledDivider } from './controls';

const SidebarDividerAdapter = () => {
  const { tradeOffer } = useTradeOfferSidebar();

  const isVisible = tradeOffer?.status === EOfferStatus.Active;

  return isVisible ? <StyledDivider /> : null;
};

export default SidebarDividerAdapter;
