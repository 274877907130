import { LocationIcon } from 'presentation/media/icons';
import { FC, useState } from 'react';
import { AddressHelper } from 'utils/address';
import { UserProfileEditAttributeLocality } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';
import { useUserProfileAttributeEdit } from '../hooks/useAttributeEdit';
import { UserProfileDataAdapterProps } from './';

const label = 'Город';

export const UserProfileLocalityAdapter: FC<UserProfileDataAdapterProps> = ({ user }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { value, validation, isUpdating, update, reset, onChange } = useUserProfileAttributeEdit({
    attribute: 'locality',
    user,
  });

  const showEdit = () => setEditMode(true);

  const hideEdit = () => {
    setEditMode(false);
    reset();
  };

  const updateInternal = () => {
    update().then(result => result && hideEdit());
  };

  return (
    <>
      <UserProfileViewAttribute
        label={label}
        value={user.locality ? new AddressHelper(user.locality).getLocalitySimpleName() : '-'}
        icon={<LocationIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributeLocality
          label={label}
          value={value}
          validation={validation?.email}
          isFetching={isUpdating}
          onChange={onChange}
          onClose={hideEdit}
          onSave={updateInternal}
        />
      )}
    </>
  );
};
