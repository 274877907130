import { EDeliveryType, EOfferStatus, EPaymentType } from './enums';
import { FileLink } from './index';
import { Category } from './nsi';
import { PartnerView } from './partner';

export type ProductDeskShort = {
  readonly id: UUID;
  readonly partner: { id: UUID };
  readonly code: string;
};

export type ProductOfferShort = {
  readonly id: UUID;
  readonly name: string;
  readonly variantName: Nullable<string>;
  readonly images: Nullable<FileLink[]>;
  readonly brand: Nullable<Brand>;
  readonly partnerSKU: string;
  readonly producerSKU: string;
  readonly productDesk?: Nullable<ProductDeskShort>;
  readonly description: string;
  readonly originalPrice: Nullable<number>;
  readonly price: number;
  readonly attributes: Nullable<ProductAttributeValue[]>;
  readonly otherAttributes: Nullable<string>;
  readonly paymentType: Nullable<EPaymentType>;
  readonly paymentConditions: Nullable<string>;
  readonly deliveryType: Nullable<EDeliveryType>;
  readonly deliveryConditions: Nullable<string>;
  readonly category: ProductCategory;
  readonly categoryList: Nullable<Category[]>;
  readonly status: EOfferStatus;
  readonly partner: PartnerView;
  // NOTE(@Protopopov Ruslan): свойство присутствует на бэке, но было решено убрать, так как другая валюта,
  // врядли будет предусмотрена
  // readonly currencyType: ECurrencyType;
  readonly salePercent: Nullable<number>;
  readonly stock: Nullable<number>;
  readonly favorite: boolean;
  readonly subscribe: boolean;
};

export type ProductOffer = ProductOfferShort & {
  readonly createdAt: string;
  readonly updatedAt: Nullable<string>;
};

export type Brand = {
  readonly id: UUID;
  readonly name: string;
  readonly image: Nullable<FileLink>;
};

export type ProductCategory = {
  readonly id: UUID;
  readonly name: Nullable<string>;
  readonly parentId: Nullable<UUID>;
  readonly attributes: Nullable<ProductCategoryAttribute[]>;
};

type DictionaryValueLink = {
  readonly id: UUID;
};

export type ProductAttributeGroup = {
  readonly id: UUID;
  readonly value: Nullable<string>;
};

export type OptionalDictionaryValue = {
  readonly dictionaryValue: Nullable<DictionaryValueLink>;
  readonly value: Nullable<string>;
};

export enum ProductAttributeType {
  string = 'string',
  integer = 'integer',
  file = 'file',
}

type DictionaryLink = {
  readonly id: UUID;
};

export type ProductAttribute = {
  readonly id: UUID;
  readonly name: string;
  readonly description: string;
  readonly type: ProductAttributeType;
  readonly dictionary: Nullable<DictionaryLink>;
  readonly group: Nullable<ProductAttributeGroup>;
};

export type ProductAttributeLink = {
  readonly id: UUID;
};

export type ProductCategoryAttribute = {
  readonly attribute: ProductAttribute;
  readonly collection: boolean;
  readonly required: boolean;
};

export type ProductAttributeValue = {
  readonly attribute: ProductAttributeLink;
  readonly values: Nullable<OptionalDictionaryValue[]>;
};
