import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import { UserAvatar } from 'presentation/components/common/avatars';
import { getPersonFullName } from 'presentation/utils';
import { FC, useState } from 'react';
import { UserProfileEditAttributeCommonInfo } from '../../../components/attributeEdit/commonInfo';
import UserProfileViewAttribute from '../../../components/attributeView';
import { getUserGenderName } from '../../../utils';
import { useUserProfileCommonAttributesEdit } from '../hooks/useCommonAttributesEdit';
import { UserProfileDataAdapterProps } from './';

export const UserProfileCommonAdapter: FC<UserProfileDataAdapterProps> = ({ user }) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { values, validation, isUpdating, update, reset, onChange } = useUserProfileCommonAttributesEdit({ user });

  const showEdit = () => setEditMode(true);

  const hideEdit = () => {
    setEditMode(false);
    reset();
  };

  const onUpdateInternal = () => {
    update().then(result => result && hideEdit());
  };

  const fullNameNode = (
    <Typography
      variant='body1'
      color='text.primary'
    >
      {getPersonFullName(user)}
    </Typography>
  );

  const commonInfoNode = (
    <Typography
      component='div'
      variant='body2'
      color='text.secondary'
    >
      {user.gender && (
        <>
          {getUserGenderName(user.gender)}
          <br />
        </>
      )}
      {user.birthDate && `${moment(user.birthDate).format(EDateFormat.Human)} г.`}
    </Typography>
  );

  const avatarNode = (
    <UserAvatar
      user={user}
      variant='rounded'
      size='large'
    />
  );

  return (
    <>
      <UserProfileViewAttribute
        label={fullNameNode}
        value={commonInfoNode}
        icon={avatarNode}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributeCommonInfo
          label='Личные данные'
          user={values}
          isFetching={isUpdating}
          validation={validation}
          onChange={onChange}
          onClose={hideEdit}
          onSave={onUpdateInternal}
        />
      )}
    </>
  );
};
