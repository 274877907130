import { useRef, ForwardRefExoticComponent, RefAttributes, ReactNode } from 'react';

type OfferSlotSidebarProps<UOS extends object> = {
  readonly useOfferSidebar: () => UOS;
  readonly children: (args: UOS, wrapperRef: any) => ReactNode;
  readonly wrapper: ForwardRefExoticComponent<{ children: any } & RefAttributes<unknown>>;
};

const OfferSlotSidebar = <UOS extends object>({
  useOfferSidebar,
  children,
  wrapper: Wrapper,
}: OfferSlotSidebarProps<UOS>) => {
  const wrapperRef = useRef<any>();
  const args = useOfferSidebar();

  return <Wrapper ref={wrapperRef}>{children(args, wrapperRef)}</Wrapper>;
};

export default OfferSlotSidebar;
