import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { Locality, UserFeedBackForm } from 'domain/model';
import { Banner } from 'domain/model/banner';
import { OfferUnavailabilityReason } from 'domain/model/enums';
import { AccountBalance, User, UserData } from 'domain/model/user';
import { ApiCancellable, ApiQueryDsl } from '../types';
import { appendQueryDslParams, createCancelToken, getBonusesEndpoint } from '../utils';
import agreement, { UserAgreementApi } from './agreement';
import bookingOffer, { BookingOfferApi } from './bookingOffer';
import cart, { CartApi } from './cart';
import corpOffer, { UserCorpOffer } from './corpOffer';
import landing, { UserLanding } from './landing';
import order, { UserOrder } from './order';
import productOffer, { UserProductApi } from './productOffer';
import search, { UserSearchApi } from './search';
import tradeOffer, { UserTradeApi } from './tradeOffer';

type CurrentProps = ApiCancellable;

type BonusesBalanceProps = ApiCancellable & {
  readonly id: UUID;
  readonly cache?: boolean;
};

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

type UpdateProps = {
  readonly id: UUID;
  readonly data: UserData;
};
type UpdateEmailProps = {
  readonly id: UUID;
  readonly email: string;
};

type FeedBackProps = {
  readonly data: UserFeedBackForm;
};
type AddOfferToFavoritesProps = {
  readonly id: UUID;
};

type RemoveOfferFromFavoritesProps = {
  readonly id: UUID;
};

type BannersProps = ApiCancellable & {
  readonly localityId?: Nullable<UUID>;
  readonly queryDsl?: Nullable<ApiQueryDsl>;
};

type OfferAvailabilityProps = ApiCancellable & {
  readonly offerId: UUID;
  readonly reasons?: Nullable<OfferUnavailabilityReason[]>;
};

type ChangeLocationProps = {
  readonly userId: UUID;
  readonly location: Locality;
};

type ResetPasswordProps = {
  readonly id: UUID;
};

type SubscribeOfferProps = {
  readonly offerId: UUID;
};

type UserApi = {
  readonly current: (props: CurrentProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, User>>;
  readonly bonusesBalance: (
    props: BonusesBalanceProps
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, AccountBalance>>;
  readonly one: (props: OneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, User>>;
  readonly update: (props: UpdateProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, User>>;
  readonly resetPassword: (props: ResetPasswordProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly updateEmail: (props: UpdateEmailProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly addOfferToFavorites: (
    props: AddOfferToFavoritesProps
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, void>>;
  readonly removeOfferFromFavorites: (
    props: RemoveOfferFromFavoritesProps
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, void>>;
  readonly feedback: (props: FeedBackProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly assignCorpRole: () => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly banners: (props: BannersProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Banner[]>>;
  readonly offerAvailability: (
    props: OfferAvailabilityProps
  ) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferUnavailabilityReason[]>>;
  readonly changeLocation: (props: ChangeLocationProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly subscribeToOffer: (props: SubscribeOfferProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly unSubscribeFromOffer: (props: SubscribeOfferProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly favorites: (props: ApiCancellable) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig>>;
  readonly product: UserProductApi;
  readonly trade: UserTradeApi;
  readonly booking: BookingOfferApi;
  readonly corp: UserCorpOffer;
  readonly order: UserOrder;
  readonly landing: UserLanding;
  readonly cart: CartApi;
  readonly agreement: UserAgreementApi;
  readonly search: UserSearchApi;
};

/**
 * АПИ по работе с пользователями
 */
const user: UserApi = {
  bonusesBalance: ({ signal, cache = true }) => {
    const params = new URLSearchParams();
    params.append('cache', cache.toString());
    const baseURL = getBonusesEndpoint();
    return {
      baseURL,
      url: `/users/current/balance`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  current: ({ signal }) => ({
    url: `/users/current/userinfo`,
    method: 'GET',
    cancelToken: signal && createCancelToken(axios, signal),
  }),

  one: ({ id, signal }) => ({
    url: `/${id}`,
    method: 'GET',
    cancelToken: signal && createCancelToken(axios, signal),
  }),

  update: ({ id, data }) => {
    return { url: `/users/${id}`, method: 'PUT', data };
  },

  updateEmail: ({ id, email }) => {
    return { url: `/users/${id}/email`, method: 'PATCH', email, data: { email } };
  },

  changeLocation: ({ userId, location }) => {
    return { url: `/users/${userId}/location`, method: 'PATCH', data: { location } };
  },

  resetPassword: ({ id }) => {
    return {
      url: `/users/${id}/password`,
      method: 'PATCH',
    };
  },

  addOfferToFavorites: ({ id }) => {
    return { url: `/customers/current/favorites/offers`, method: 'POST', data: { offerId: id } };
  },

  removeOfferFromFavorites: ({ id }) => {
    return { url: `/customers/current/favorites/offers/${id}`, method: 'DELETE' };
  },

  feedback: ({ data }) => {
    return { url: `/feedback`, method: 'POST', data };
  },

  assignCorpRole: () => {
    return {
      url: `/users/current/corpLink`,
      method: 'PATCH',
    };
  },

  banners: props => {
    const { localityId, queryDsl, signal } = props;

    const params = new URLSearchParams();

    if (localityId) {
      params.append('localityId', localityId);
    }

    if (queryDsl) {
      appendQueryDslParams(params, queryDsl);
    }

    return {
      url: `/customers/current/banners`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  offerAvailability: ({ offerId, reasons, signal }) => {
    const params = new URLSearchParams();

    if (reasons) {
      reasons.forEach(reason => params.append('reasons', reason));
    }

    return {
      url: `/customers/current/offers/${offerId}/activation-availability`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },

  subscribeToOffer: ({ offerId }) => {
    return { url: `/customers/current/subscriptions/offers`, method: 'POST', data: { offerId } };
  },

  unSubscribeFromOffer: ({ offerId }) => {
    return { url: `/customers/current/subscriptions/offers/${offerId}`, method: 'DELETE' };
  },

  favorites: props => {
    return {
      url: '/customers/current/offers/favorite',
      method: 'Get',
      cancelToken: props?.signal && createCancelToken(axios, props.signal),
    };
  },

  product: productOffer,
  trade: tradeOffer,
  booking: bookingOffer,
  corp: corpOffer,
  landing,
  cart,
  order,
  agreement,
  search,
};

export default user;
