import { Link as MUILink, Typography, useMediaQuery } from '@mui/material';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import OfferListItem, { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import TradeOfferListItemInfo from 'presentation/features/offer/trade/components/listItem/info';
import { forwardRef, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getTradeOfferDetailsRoute } from '../../entry';
import { MPChip } from '../../../../../theme/ui-kit/chip';
import { OfferTags } from '../../../../../../domain/model/offer';
import { EOfferClientTagType, ETradeOfferPromotionType } from 'domain/model/enums';

export type TradeOfferListItemProps = {
  readonly offer: Pick<TradeOfferShort, 'id' | 'name' | 'title' | 'images' | 'partner' | 'tags' | 'promotionType'>;
  readonly direction?: OfferListItemProps['direction'];
  readonly favorite?: ReactNode;
};

const TradeOfferListItem = forwardRef<any, TradeOfferListItemProps>((props, ref) => {
  const { offer, direction, favorite } = props;
  const { id, images, name, tags } = offer;
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const tradeOfferTags: OfferTags = [...(tags ?? [])];

  if (offer.promotionType === ETradeOfferPromotionType.Asp) {
    tradeOfferTags.push({ type: EOfferClientTagType.ASP, name: 'КСП' });
  }

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getTradeOfferDetailsRoute({ id })}
    >
      <OfferListItem
        images={images}
        description={name}
        header={
          <MPChip
            size='micro'
            label={<Typography variant={isSmUp ? 'body2' : 'caption'}>{offer.title}</Typography>}
            color='warning'
          />
        }
        favorite={favorite}
        info={<TradeOfferListItemInfo {...offer} />}
        direction={direction}
        tags={tradeOfferTags}
      />
    </MUILink>
  );
});

TradeOfferListItem.displayName = 'TradeOfferListItem';

export default TradeOfferListItem;
