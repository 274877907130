import { skipToken } from '@reduxjs/toolkit/query';
import { useGetUserActivationsCountQuery } from 'data/api/user';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';

const useActivationsCounter = () => {
  const { userId } = useAuthUser();
  const { data } = useGetUserActivationsCountQuery(userId ? { userId } : skipToken);

  return data ?? 0;
};

export default useActivationsCounter;
