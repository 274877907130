import { isArray } from 'lodash';
import { CalendarProps, DateObject } from 'react-multi-date-picker';
import { permanentOptions, regularOptions, StyledCalendar } from '../configuration';
import { CalendarSelection } from '../types';
import { useLimitedDays } from '../hooks';

export type DatesRange = {
  readonly startDate: Nullable<Date>;
  readonly endDate: Nullable<Date>;
};

export type MPRangeCalendarPickerProps = Omit<CalendarProps, 'onChange' | 'multiple' | 'range'> &
  CalendarSelection & {
    readonly onChange?: (range: Nullable<DatesRange>) => false | void;
  };

export const MPRangeCalendarPicker = ({
  limitPastSelection,
  limitFutureSelection,
  ...props
}: MPRangeCalendarPickerProps) => {
  const onChangeInternal = (selectedDates: Nullable<DateObject | DateObject[]>): false | void => {
    if (selectedDates === null) {
      return props.onChange?.(null);
    }

    const result: Nullable<DatesRange> = !isArray(selectedDates)
      ? { startDate: selectedDates.toDate(), endDate: null }
      : { startDate: selectedDates?.[0]?.toDate() ?? null, endDate: selectedDates?.[1]?.toDate() ?? null };

    return props.onChange?.(result);
  };

  const mapDays = useLimitedDays({ limitPastSelection, limitFutureSelection });

  return (
    <StyledCalendar
      {...regularOptions}
      {...props}
      onChange={onChangeInternal}
      multiple={false}
      mapDays={mapDays}
      range
      {...permanentOptions}
    />
  );
};
