export enum DataFilterQueryDslOperator {
  Equals = '=',
  Less = '<',
  LessOrEquals = '<=',
  More = '>',
  MoreOrEquals = '>=',
  In = 'in',
  Like = 'like',
  Between = 'between', // only client
}
