import { FCC } from 'react';
import AppSvgIcon from '../../../../../components/common/icon';
import { WarningTriangleIcon } from '../../../../../media/icons';
import { InfoWrapper, WarnWrapper, WarnWrapperContent } from './controls';

type SidebarMessageProps = {
  type: 'warn' | 'info';
};

const WarnContainer: FCC = ({ children }) => {
  return (
    <WarnWrapper>
      <AppSvgIcon
        fontSize='small'
        color='warning'
        icon={WarningTriangleIcon}
      />
      <WarnWrapperContent>{children}</WarnWrapperContent>
    </WarnWrapper>
  );
};

const InfoContainer: FCC = ({ children }) => {
  return <InfoWrapper>{children}</InfoWrapper>;
};

const SidebarMessage: FCC<SidebarMessageProps> = ({ children, type }) => {
  const Container = type === 'warn' ? WarnContainer : InfoContainer;

  return <Container>{children}</Container>;
};

export default SidebarMessage;
