import styled from '@emotion/styled/macro';
import { css, typographyClasses } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: table;
    text-align: center;

    padding: ${theme.spacing(0, 0.5)};

    border-radius: ${theme.shape.borderRadius * 1.2}px;
    background: linear-gradient(224.37deg, #ff4040 14.05%, #ffaa2b 85.15%);

    .${typographyClasses.root} {
      line-height: 16px;
    }
  `
);
