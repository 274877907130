import HttpClient from 'data/network/http';
import { useSelector } from 'react-redux';
import { authProviderTokenParsedSelector, authProviderTokenSelector } from './store/selectors';

export const useAuthToken = () => {
  const token = useSelector(authProviderTokenSelector);
  const tokenParsed = useSelector(authProviderTokenParsedSelector);

  const refreshToken = async () => {
    await HttpClient.getInstance().getAuthService()?.updateToken(5);
  };

  return { token, tokenParsed, refreshToken };
};
