import styled from '@emotion/styled/macro';
import { css, IconButton as MuiIconButton, Paper, PaperProps, Typography } from '@mui/material';
import { forwardRef } from 'react';

export const SidebarWrapper = styled(
  forwardRef<any, PaperProps>((props, ref) => (
    <Paper
      {...props}
      ref={ref}
    />
  ))
)(
  () => css`
    width: 100%;
    min-height: 10rem;

    display: inline-flex;
    position: relative;
    box-shadow: none;
  `
);

type SidebarMessageWrapperProps = {
  readonly $error?: boolean;
  readonly $noBorder?: boolean;
};

export const SidebarMessageWrapper = styled.div<SidebarMessageWrapperProps>(
  ({ theme, $error, $noBorder }) => css`
    border-top: ${$noBorder ? 0 : 1}px solid ${$error ? theme.palette.error.main : theme.palette.secondary.main};

    padding-top: ${theme.spacing(0.75)};
    padding-bottom: ${theme.spacing(0.75)};
  `
);

export const SidebarMessageHeader = styled(Typography)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightBold};
  `
);

export const SidebarIconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};

    ${theme.breakpoints.down('md')} {
      background-color: ${theme.palette.secondary.light};
      border-radius: ${theme.shape.borderRadius}px;
    }
  `
) as typeof MuiIconButton;
