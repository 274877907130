import { Typography } from '@mui/material';
import { EOfferStatus } from '../../../../../../../../domain/model/enums';
import { InfoEmptyIcon } from '../../../../../../../media/icons';
import useCorpOfferSidebarDialogs from '../../dialogProvider/useDialogs';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { StyledFab, Wrapper } from './controls';

const NdflAdapter = () => {
  const { corpOffer } = useCorpOfferSidebar();
  const { openNdflHelpDialog } = useCorpOfferSidebarDialogs();

  const isVisible = corpOffer?.status === EOfferStatus.Active;

  if (!corpOffer?.hasNdfl) {
    return null;
  }

  return isVisible ? (
    <Wrapper>
      <Typography variant='body2'>Возможно, оплата НДФЛ</Typography>
      <StyledFab
        variant='squared'
        color='secondaryLight'
        size='medium'
        onClick={openNdflHelpDialog}
      >
        <InfoEmptyIcon />
      </StyledFab>
    </Wrapper>
  ) : null;
};

export default NdflAdapter;
