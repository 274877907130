import { chipClasses, getChipUtilityClass, typographyClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { OverrideThemeFunc } from './types';

export const chipCustomClasses = {
  colorDefault: getChipUtilityClass('colorDefault'),
  iconColorDefault: getChipUtilityClass('iconColorDefault'),
  colorBlack: getChipUtilityClass('colorBlack'),
  deleteIconColorBlack: getChipUtilityClass('deleteIconColorBlack'),
  deleteIconColorDefault: getChipUtilityClass('deleteIconColorDefault'),
  colorWhite: getChipUtilityClass('colorWhite'),
  deleteIconColorWhite: getChipUtilityClass('deleteIconColorWhite'),
  sizeMicro: getChipUtilityClass('sizeMicro'),
  labelMicro: getChipUtilityClass('labelMicro'),
  deleteIconMicro: getChipUtilityClass('deleteIconMicro'),
  iconMicro: getChipUtilityClass('iconMicro'),
  deleteIconSmall: getChipUtilityClass('deleteIconSmall'),
  deleteIconMedium: getChipUtilityClass('deleteIconMedium'),
  iconLarge: getChipUtilityClass('iconLarge'),
  sizeLarge: getChipUtilityClass('sizeLarge'),
  labelLarge: getChipUtilityClass('labelLarge'),
  deleteIconLarge: getChipUtilityClass('deleteIconLarge'),
};

const chip: OverrideThemeFunc<Components> = baseTheme => ({
  MuiChip: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        height: 'auto',

        transition: 'color 0.5s, background-color 0.5s, border-color 0.75s',

        ':active': {
          boxShadow: 'none',
        },
        [`.${chipCustomClasses.deleteIconMicro}, .${chipCustomClasses.iconMicro}`]: {
          fontSize: '16px',
        },
        [`.${chipCustomClasses.deleteIconSmall}, .${chipClasses.iconSmall}`]: {
          fontSize: '22px',
        },
        [`.${chipCustomClasses.deleteIconMedium}, .${chipClasses.iconMedium}`]: {
          fontSize: '24px',
        },
        [`.${chipCustomClasses.deleteIconLarge}, .${chipCustomClasses.iconLarge}`]: {
          fontSize: '24px',
        },
        [`.${chipClasses.iconSmall}`]: {
          marginLeft: baseTheme.spacing(0.25),
        },
        [`.${chipCustomClasses.deleteIconSmall}`]: {
          marginRight: baseTheme.spacing(0.25),
        },
        [`.${chipClasses.iconMedium}`]: {
          marginLeft: baseTheme.spacing(0.5),
        },
        [`.${chipCustomClasses.deleteIconMedium}`]: {
          marginRight: baseTheme.spacing(0.5),
        },
        [`.${chipCustomClasses.iconLarge}`]: {
          marginLeft: baseTheme.spacing(1.25),
        },
        [`.${chipCustomClasses.deleteIconLarge}`]: {
          marginRight: baseTheme.spacing(1.25),
        },
        [`.${chipClasses.deleteIcon}`]: {
          cursor: 'default',
          display: 'flex',
          alignItems: 'center',
        },
      },
      label: {
        [`.${typographyClasses.root}`]: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      sizeLarge: {
        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight,

        borderRadius: baseTheme.shape.borderRadius * 1.2,
      },
      sizeMedium: {
        fontSize: baseTheme.typography.body1.fontSize,
        lineHeight: baseTheme.typography.body1.lineHeight,
        fontWeight: baseTheme.typography.body1.fontWeight,

        borderRadius: baseTheme.shape.borderRadius * 1.2,
      },
      sizeSmall: {
        fontSize: baseTheme.typography.body2.fontSize,
        lineHeight: baseTheme.typography.body2.lineHeight,
        fontWeight: baseTheme.typography.body2.fontWeight,

        borderRadius: baseTheme.shape.borderRadius,
      },
      sizeMicro: {
        fontSize: baseTheme.typography.caption.fontSize,
        lineHeight: baseTheme.typography.caption.lineHeight,
        fontWeight: baseTheme.typography.caption.fontWeight,

        borderRadius: baseTheme.shape.borderRadius * 0.8,
      },
      icon: {},
      outlined: {
        [`&.${chipCustomClasses.colorBlack}`]: {
          borderColor: baseTheme.palette.black.main,
        },
        [`&.${chipCustomClasses.colorWhite}`]: {
          borderColor: baseTheme.palette.secondary.A900,
          color: baseTheme.palette.white.contrastText,
        },
        [`& .${chipCustomClasses.labelLarge}`]: {
          padding: baseTheme.spacing(1.125, 1.625),
        },
        [`& .${chipClasses.labelMedium}`]: {
          //сделано меньше на 1px, чтобы по размеру быть как filled
          padding: baseTheme.spacing(0.375, 1.375),
        },
        [`& .${chipClasses.labelSmall}`]: {
          padding: baseTheme.spacing(0.125, 1.4),
        },
        [`& .${chipCustomClasses.labelMicro}`]: {
          //сделано меньше на 1px, чтобы по размеру быть как filled
          padding: baseTheme.spacing(0.125, 1.4),
        },
        [`& .${chipCustomClasses.deleteIconColorDefault}, .${chipCustomClasses.iconColorDefault}`]: {
          color: baseTheme.palette.black.main,

          ':hover, :active': {
            color: baseTheme.palette.black.main,
          },
        },
      },
      [`& .${chipCustomClasses.iconColorDefault}`]: {
        background: baseTheme.palette.black.main,
      },
      filled: {
        [`& .${chipCustomClasses.labelLarge}`]: {
          padding: baseTheme.spacing(1.25, 1.75),
        },
        [`& .${chipClasses.labelMedium}`]: {
          padding: baseTheme.spacing(0.5, 1.75),
        },
        [`& .${chipClasses.labelSmall}`]: {
          padding: baseTheme.spacing(0.25, 1.4),
        },
        [`& .${chipCustomClasses.labelMicro}`]: {
          padding: baseTheme.spacing(0.25, 1.4),
        },

        [`&.${chipCustomClasses.colorDefault}`]: {
          background: baseTheme.palette.secondary.A600,
        },
        [`&.${chipCustomClasses.colorBlack}`]: {
          background: baseTheme.palette.black.main,
          color: baseTheme.palette.black.contrastText,

          [`&.${chipClasses.clickable}`]: {
            ':hover, :active': {
              background: baseTheme.palette.black.light,
            },
          },
        },
        [`&.${chipCustomClasses.colorWhite}`]: {
          background: baseTheme.palette.white.main,
          color: baseTheme.palette.white.contrastText,

          [`&.${chipClasses.clickable}`]: {
            ':hover, :active': {
              background: baseTheme.palette.white.dark,
            },
          },
        },
        [`.${chipClasses.deleteIcon}`]: {
          color: baseTheme.palette.white.main,
        },
        [`.${chipCustomClasses.deleteIconColorBlack}`]: {
          color: baseTheme.palette.white.main,

          // ':hover, :active': {
          //   color: baseTheme.palette.white.dark,
          // },
        },
        [`.${chipCustomClasses.deleteIconColorWhite}`]: {
          color: baseTheme.palette.white.contrastText,

          // ':hover, :active': {
          //   color: baseTheme.palette.black.light,
          // },
        },
        [`.${chipCustomClasses.deleteIconColorDefault}, .${chipCustomClasses.iconColorDefault}`]: {
          color: baseTheme.palette.black.main,

          ':hover, :active': {
            color: baseTheme.palette.black.main,
          },
        },
      },
    },
  },
});

export default chip;
