import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getBaseEndpoint } from '../openApi/utils';

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig> =>
  async ({ baseURL, url, method, params, data, headers }) => {
    const baseUrl = baseURL ?? getBaseEndpoint();
    try {
      const result = await axios({ url: baseUrl + url, method, params, data, headers });
      return {
        data: result,
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      // TODO(@Protopopov Ruslan): мб добавить return ошибки через самодельный класс AxiosException
      // чтобы на макро уровне обработки ошибок делать instanceof?
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };
