import * as H from 'history';
import { Location } from 'history';
import CorpOfferScreen from 'presentation/screen/corpOffer';
import CorpOffersScreen from 'presentation/screen/corpOffers';
import { Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nullable } from '../../../../domain/model/types';
import { EGlobalUrlParam } from '../../header/utils';
import { ETradeOfferUrlParam } from '../trade/utils';
import { ECorpOfferDetailsTab } from './details/utils';
import { ECorpOfferUrlParam } from './utils';
import CorpOffersSearchResultsScreen from '../../../screen/corpOffers/search';
import { routing } from './routes';

export type CorpOffersListLocationState = {
  readonly guid: UUID;
};

export type CorpOfferDetailsLocationState = {
  readonly guid: UUID;
};

type GetCorpOffersListRouteProps = {
  readonly guid?: Nullable<UUID>;
};

type GetCorpOfferDetailsRouteProps = {
  readonly id: UUID;
  readonly guid?: Nullable<UUID>;
  readonly tab?: ECorpOfferDetailsTab;
};

type GetCorpOffersSearchRouteProps = {
  readonly name?: Nullable<string>;
  readonly categoryId?: Nullable<UUID>;
  readonly partnerId?: Nullable<UUID>;
  readonly guid?: Nullable<UUID>;
};

export const getCorpOffersSearchRoute = (props: GetCorpOffersSearchRouteProps) => {
  const { name, categoryId, guid, partnerId } = props;
  const newGuid = guid ?? uuidv4();

  const params = new URLSearchParams();

  if (name) {
    params.append(EGlobalUrlParam.Search, name);
  }

  if (categoryId) {
    params.append(ECorpOfferUrlParam.Category, categoryId);
  }

  if (partnerId) {
    params.append(ECorpOfferUrlParam.PartnerId, partnerId);
  }

  return {
    pathname: routing.list,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getCorpOffersListRoute = (props?: GetCorpOffersListRouteProps): Location<CorpOffersListLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: routing.list,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const getCorpOfferDetailsRoute = ({
  id,
  tab,
  guid,
}: GetCorpOfferDetailsRouteProps): H.Location<CorpOfferDetailsLocationState> => {
  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();
  if (tab) {
    params.append(ETradeOfferUrlParam.Tab, tab);
  }

  return {
    pathname: `${routing.details.replace(':id', id)}`,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

const CorpOfferEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.list}
        component={CorpOffersScreen}
      />
      <Route
        exact
        path={routing.search}
        component={CorpOffersSearchResultsScreen}
      />
      <Route
        exact
        path={routing.details}
        component={CorpOfferScreen}
      />
    </Switch>
  );
};

export default CorpOfferEntry;
