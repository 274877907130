import { ReactNode } from 'react';
import { ETradeOfferPromotionType } from '../../../../../../../domain/model/enums';

type DialogContent = {
  readonly title: ReactNode;
  readonly text: ReactNode;
};

export const dialogContent: { [k in ETradeOfferPromotionType]: DialogContent } = {
  [ETradeOfferPromotionType.PublicPromocode]: {
    title: 'Как использовать общий промокод',
    text: 'Чтобы воспользоваться промокодом, скопируйте его и введите в специальное поле на сайте партнёра при оформлении заказ',
  },
  [ETradeOfferPromotionType.AccessCode]: {
    title: 'Как использовать индивидуальный код доступа',
    text: 'Чтобы использовать код доступа, скопируйте его и введите в специальное поле на сайте партнёра. Код доступа применяется единоразово на каждого пользователя',
  },
  [ETradeOfferPromotionType.Promocode]: {
    title: 'Как использовать уникальный промокод',
    text: 'Чтобы воспользоваться промокодом, скопируйте его и введите в специальное поле на сайте партнёра при оформлении заказа. Для нового заказа получите новый промокод',
  },
  [ETradeOfferPromotionType.Voucher]: {
    title: 'Как использовать ваучер',
    text: 'При покупке товара или услуги по акции предъявите ваучер в распечатанном или электронном формате',
  },
  [ETradeOfferPromotionType.ReferralLink]: {
    title: 'Как использовать реферальную ссылку',
    text: 'Перейдите на сайт партнера с Витрины, акция будет применена автоматически',
  },
  [ETradeOfferPromotionType.Widget]: {
    title: 'Как использовать виджет',
    text: 'Запустите виджет и внесите информацию в форму, чтобы отправить запрос для получения предложения',
  },
  [ETradeOfferPromotionType.Asp]: {
    title: 'Как использовать АСП',
    text: '',
  },
};
