import rootRouting from '../../../../routing';
import { GetSearchResultsRouteProps } from '../types';
import { Location } from 'history';
import { SearchResultsLocationState } from '../../search/entry';
import { v4 as uuidv4 } from 'uuid';
import { ESearchUrlParam } from '../../../types';

const root = rootRouting.bookingOffer;
// TODO разнести все entry для исключения циклических зависимостей
export const routing = {
  list: root,
  search: `${root}/search`,
  details: `${root}/:id`,
  order: `${root}/order/:id`,
};

export const getBookingOfferSearchResultsRoute = (
  props: GetSearchResultsRouteProps
): Location<SearchResultsLocationState> => {
  const { guid, query } = props;

  const newGuid = guid ?? uuidv4();

  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append(ESearchUrlParam.Query, query);
  }

  return {
    pathname: `${routing.search}`,
    search: `?${searchParams.toString()}`,
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};
