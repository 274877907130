import { EHeaders } from 'data/network/types';
import useWebStorage from 'presentation/hooks/useWebStorage';
import { setTargetParams } from 'presentation/screen/cms/store/slice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useWebStoredTargetParam = (initialValue: Nullable<string>) => {
  const dispatch = useDispatch();
  const [targetParamsEncoded, setTargetParamsEncoded] = useWebStorage<Nullable<string>>(
    EHeaders.PreviewTarget,
    initialValue,
    'sessionStorage'
  );

  const setTargetParamsEncodedHandler = useCallback(
    (value: Nullable<string>) => {
      setTargetParamsEncoded(value);
      dispatch(setTargetParams(value));
    },
    [dispatch, setTargetParamsEncoded]
  );

  return {
    targetParamsEncoded,
    setTargetParamsEncodedHandler,
  };
};

export default useWebStoredTargetParam;
