import { Typography } from '@mui/material';
import SidebarMessage from 'presentation/features/offer/components/sidebar/message';

export const TradeOfferSidebarPausedMessage = () => {
  return (
    <SidebarMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        Предложение временно недоступно
      </Typography>
    </SidebarMessage>
  );
};
