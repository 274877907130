import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { Banner } from 'domain/model/banner';
import { EBannerStatus } from 'domain/model/enums';
import { createCancelToken } from './index';
import { ApiCancellable, ApiQueryDsl, ApiRequestPageable } from './types';
import { appendQueryDslParams, getUserQueryParams } from './utils';

type AllProps = ApiRequestPageable &
  ApiCancellable & {
    readonly statuses?: Nullable<EBannerStatus[]>;
    readonly queryDsl?: Nullable<ApiQueryDsl>;
  };

type BannerApi = {
  readonly all: (props: AllProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, Banner[]>>;
};

/**
 * АПИ по работе с баннерами
 */
const banner: BannerApi = {
  all: props => {
    const { statuses, queryDsl, signal, ...queryParams } = props;

    const params = getUserQueryParams(queryParams);

    if (statuses) {
      statuses.forEach((status: EBannerStatus) => params.append('status', status));
    }

    if (queryDsl) {
      appendQueryDslParams(params, queryDsl);
    }

    return {
      url: `/banners`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default banner;
