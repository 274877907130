import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTradeOffersListRoute } from '../../entry';
import { tradeOfferDetailsStartSession } from '../store/slice';

export type UseTradeOfferDetails = {
  readonly onBack: () => void;
};

export type UseTradeOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const useTradeOfferDetails = ({ id, guid }: UseTradeOfferDetailsProps): UseTradeOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  useEffect(() => {
    webAnalytics.offerView(id);
  }, [webAnalytics, id]);

  useEffect(() => {
    dispatch(tradeOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  const onBack = () => {
    gotoPrevIndependentLocation(getTradeOffersListRoute());
  };

  return {
    onBack,
  };
};

export default useTradeOfferDetails;
