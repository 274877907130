import { CmsApiOffersRequest, useGetCmsCorpOffersCollectionQuery } from 'data/api/cms';
import ErrorHandler from 'data/network/errorHandler';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import CorpOfferListItem from 'presentation/features/offer/corp/components/listItem';
import React, { useEffect } from 'react';
import { CmsCollectionShowAllAdapter } from './adapters/showAll';
import { CmsOfferCollectionProps } from './types';

/**
 * фича отображения состава коллекции КП
 */
export const CmsCorpOfferCollection: React.FCC<CmsOfferCollectionProps> = props => {
  const {
    id,
    guid,
    header,
    footer: Footer,
    layout: Layout,
    stub,
    statuses,
    visibleCount,
    onLoadMore,
    onDataLoaded,
    onShowCard,
  } = props;

  //подключились к хуку для отслеживания изменения данных пользователя, конкретно тут интересен город
  const { user } = useAuthUser();

  const args: CmsApiOffersRequest = {
    guid,
    id,
    city: user?.locality?.name ?? null,
    statuses,
  };

  const { data: queryData, isFetching, isSuccess, error } = useGetCmsCorpOffersCollectionQuery(args);

  const data = queryData?.slice(0, visibleCount);
  const totalCount = queryData?.length ?? 0;

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  useEffect(() => {
    if ((data && !isFetching) || error) onDataLoaded?.();
  }, [data, isFetching, error, onDataLoaded]);

  const hasContent = data && data.length > 0;
  const sessionKey = JSON.stringify(args);

  return (
    <Layout
      header={hasContent && header}
      showAll={hasContent && totalCount > data.length && !onLoadMore && <CmsCollectionShowAllAdapter id={id} />}
      footer={Footer && <Footer hasContent={hasContent} />}
    >
      <CardPageableList
        totalCount={totalCount}
        isFetching={isFetching}
        onLoadMore={onLoadMore}
      >
        {data?.map(item => (
          <InView
            key={item.id}
            active={isSuccess && !isFetching}
            sessionKey={sessionKey}
            data={item}
            onShow={onShowCard}
          >
            <CorpOfferListItem offer={item} />
          </InView>
        ))}
      </CardPageableList>
      {!error && data?.length === 0 && stub}
    </Layout>
  );
};
