import { ButtonProps } from '@mui/material';
import { FC } from 'react';
import ContentLoader from '../../../../../../../components/common/loader';
import { StyledFileDownloadButton } from './controls';

type DownloadVoucherButtonProps = ButtonProps & {
  readonly isFetching: boolean;
  readonly onClick: () => void;
  readonly buttonComponent?: FC<ButtonProps>;
};

const DownloadVoucherButton = ({
  children,
  buttonComponent: ButtonComponent,
  isFetching,
  onClick,
  ...buttonProps
}: DownloadVoucherButtonProps) => {
  const progressSize = buttonProps.size === 'small' ? 20 : 25;

  const Wrapper: FC<ButtonProps> = ButtonComponent ? ButtonComponent : StyledFileDownloadButton;

  return (
    <Wrapper
      onClick={onClick}
      disabled={isFetching}
      {...buttonProps}
    >
      {children}
      {isFetching && <ContentLoader size={progressSize} />}
    </Wrapper>
  );
};

export default DownloadVoucherButton;
