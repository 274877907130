import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SearchOffersRequest } from 'data/api/search';
import { EOfferType, ETradeOfferSortType } from 'domain/model/enums';
import { searchOffersDefaultParams } from 'presentation/features/search/utils';

export type SearchOffersState = {
  readonly guid: Nullable<UUID>;
  readonly isNewFetching: boolean;
  readonly args: SearchOffersRequest;
};

type Reducer<T> = CaseReducer<SearchOffersState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<SearchOffersState> & {
  searchOffersStartSession: Reducer<{ guid: UUID; offerType?: Nullable<EOfferType[]> }>;
  searchOffersSetPageSize: Reducer<number>;
  searchOffersSetPage: Reducer<number>;
  searchOffersSetSort: Reducer<ETradeOfferSortType[]>;
  searchOffersSetArgs: Reducer<SearchOffersRequest>;
  searchOffersSetIsNewFetching: Reducer<boolean>;
};

const emptyParams = searchOffersDefaultParams;

const slice = createSlice<SearchOffersState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    guid: null,
    isNewFetching: true,
    args: emptyParams,
  },
  reducers: {
    searchOffersStartSession: (state, { payload }) => {
      const { guid } = payload;
      state.guid = guid;
      state.isNewFetching = true;
      state.args = {
        ...emptyParams,
        offerType: payload.offerType,
      };
    },
    searchOffersSetArgs: (state, { payload }) => {
      state.args = payload;
    },
    searchOffersSetPageSize: (state, { payload }) => {
      state.args.pageSize = payload;
    },
    searchOffersSetSort: (state, { payload }) => {
      state.args.sort = payload;
    },
    searchOffersSetPage: (state, { payload }) => {
      state.args.page = payload;
    },
    searchOffersSetIsNewFetching: (state, { payload }) => {
      state.isNewFetching = payload;
    },
  },
});

export const {
  searchOffersSetPageSize,
  searchOffersSetPage,
  searchOffersStartSession,
  searchOffersSetSort,
  searchOffersSetIsNewFetching,
  searchOffersSetArgs,
} = slice.actions;

export default slice.reducer;
