import { Typography } from '@mui/material';
import { TradePersonalPromotionType } from 'domain/model/enums';
import SidebarMessage from '../../../../../components/sidebar/message';
import { getOfferCodesEndsText } from '../../../../../utils';

type TradeOfferSidebarCodesEndsMessageProps = {
  readonly promotionType: TradePersonalPromotionType;
}

export const TradeOfferSidebarCodesEndsMessage = (props: TradeOfferSidebarCodesEndsMessageProps) => {
  const { promotionType } = props;

  return (
    <SidebarMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        {getOfferCodesEndsText(promotionType)}
      </Typography>
    </SidebarMessage>
  );
};
