import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';

type OfferSidebarSubscriptionDisabledProps = {
  readonly isFetching?: boolean;
  readonly onSubscribe: () => void;
};

const OfferSidebarSubscriptionDisabled = ({ isFetching, onSubscribe }: OfferSidebarSubscriptionDisabledProps) => {
  return (
    <MPButton
      disabled={isFetching}
      fullWidth
      size='large'
      color='primary'
      variant='outlined'
      onClick={onSubscribe}
    >
      <Typography variant='body1'>Уведомить о появлении по эл. почте </Typography>
      {isFetching && <ContentLoader size={15} />}
    </MPButton>
  );
};

export default OfferSidebarSubscriptionDisabled;
