import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCorpOffersListRoute } from '../../entry';
import { corpOfferDetailsStartSession } from '../store/slice';

export type UseCorpOfferDetails = {
  readonly onBack: () => void;
};

export type UseCorpOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const useCorpOfferDetails = ({ id, guid }: UseCorpOfferDetailsProps): UseCorpOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();

  useEffect(() => {
    webAnalytics.offerView(id);
  }, [webAnalytics, id]);

  useEffect(() => {
    dispatch(corpOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  const onBack = () => {
    gotoPrevIndependentLocation(getCorpOffersListRoute());
  };

  return {
    onBack,
  };
};

export default useCorpOfferDetails;
