import { RootState } from 'data/store/store';
import { sortByOther } from './utils';
import { NsiState } from './slice';

export const nsiSelector = (store: RootState) => store.nsi;
export const nsiDataSelector = (store: RootState): NsiState['data'] => store.nsi.data;
export const nsiDataOrderTypesSelector = (store: RootState) => {
  const orderTypes = [...store.nsi.data.cancelOrderTypes];
  return orderTypes.sort(sortByOther);
};
export const nsiDataCustomerTypesSelector = (store: RootState) => {
  const orderTypes = [...store.nsi.data.cancelCustomerTypes];
  return orderTypes.sort(sortByOther);
};
