import { AppOptionTyped } from 'domain/model';
import { EDeliveryType, EPaymentType } from 'domain/model/enums';
import { LoggedUserAdapter } from 'presentation/features/auth/provider/loggedUserAdapter';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useElementRect } from '../../../../hooks/useElementRect';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import ProductOfferDeliveryInfoDialog from '../components/dialogs/deliveryInfo';
import ProductOfferPaymentInfoDialog from '../components/dialogs/paymentInfo';
import ProductOfferSidebar from './component';

export type ProductOfferSidebarNsi = {
  readonly paymentTypes: AppOptionTyped<EPaymentType>[];
  readonly deliveryTypes: AppOptionTyped<EDeliveryType>[];
};

enum InfoDialogType {
  Payment,
  Delivery,
}

type ProductOfferSidebarContainerProps = {
  readonly id: UUID;
};

const ProductOfferSidebarContainer = ({ id }: ProductOfferSidebarContainerProps) => {
  const { productOffer: product } = useProductOfferDetailsData(id);
  const { paymentTypes, deliveryTypes } = useSelector(nsiDataSelector);

  const [infoDialogVisible, setInfoDialogVisible] = useState<Nullable<InfoDialogType>>(null);
  const [cartQuantity, setCartQuantity] = useState(Math.round(Math.random()));

  const { elementRect: bottomMenuRect } = useElementRect({ element: '[role="menubar"]' });

  const nsi = useMemo(() => ({ paymentTypes, deliveryTypes }), [paymentTypes, deliveryTypes]);

  const onShowDeliveryDialog = () => {
    setInfoDialogVisible(InfoDialogType.Delivery);
  };

  const onShowPaymentDialog = () => {
    setInfoDialogVisible(InfoDialogType.Payment);
  };

  const onHideInfoDialog = () => {
    setInfoDialogVisible(null);
  };

  const onUpdateCart = (count: number) => {
    setCartQuantity(count);
  };

  if (!product) {
    return null;
  }

  return (
    <LoggedUserAdapter
      component={({ user }) => (
        <>
          <ProductOfferSidebar
            user={user}
            product={product}
            nsi={nsi}
            bottomMenuRect={bottomMenuRect}
            cartQuantity={cartQuantity}
            onShowPaymentDialog={onShowPaymentDialog}
            onShowDeliveryDialog={onShowDeliveryDialog}
            onUpdateCart={onUpdateCart}
          />
          <ProductOfferPaymentInfoDialog
            open={infoDialogVisible === InfoDialogType.Payment}
            info={product.paymentConditions}
            onClose={onHideInfoDialog}
          />
          <ProductOfferDeliveryInfoDialog
            open={infoDialogVisible === InfoDialogType.Delivery}
            info={product.deliveryConditions}
            onClose={onHideInfoDialog}
          />
        </>
      )}
    />
  );
};

export default ProductOfferSidebarContainer;
