import { Fade, Typography, useMediaQuery, useTheme } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import UserDetailsToolbarLayout from 'presentation/layouts/userDetailsToolbar';
import { ContentWrapper, ListWrapper, Wrapper } from './controls';
import { UserOrdersLayoutType } from './types';

const UserOrdersLayout: UserOrdersLayoutType = props => {
  const { header, toolbar, loader, stub, children } = props;
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Fade in>
      <DefaultContentWrapper
        fixed={isLgUp}
        maxWidth={isLgUp ? 'sm' : undefined}
      >
        <Wrapper>
          <Typography variant='h1'>{header}</Typography>
          <Splitter size={3} />

          {toolbar && (
            <UserDetailsToolbarLayout>
              {toolbar}
              <Splitter size={3} />
            </UserDetailsToolbarLayout>
          )}

          <ContentWrapper>
            {stub}
            {children && <ListWrapper>{children}</ListWrapper>}
          </ContentWrapper>
          {loader}
        </Wrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default UserOrdersLayout;
