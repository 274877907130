import { EUserServicesTag } from 'data/api';
import { useGetUserActivationsQuery, UserActivationsRequest } from 'data/api/user';
import { OfferDataShort } from 'domain/model';
import { OfferActivation } from 'domain/model/activation';
import { ECorpOfferPromotionType, EOfferType, ETradeOfferPromotionType, OfferPromotionType } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import ActivationsStub from 'presentation/features/activation/components/stub';
import { nsiDataSelector } from 'presentation/features/general/nsi/store/selectors';
import usePageableArgsInRedux from 'presentation/features/general/pageable/argumentsStorage/redux/usePageableArgsInRedux';
import useRtkQueryCachedPageableData from 'presentation/features/general/pageable/cacheStorage/rtkQuery/useRtkQueryCachedPageableData';
import { EPaginationBehaviour } from 'presentation/features/general/pageable/types';
import usePageableList from 'presentation/features/general/pageable/usePageableList';
import CertificateDialog from 'presentation/features/offer/corp/details/dialogs/help/certificate';
import { getCorpOfferDetailsRoute } from 'presentation/features/offer/corp/entry';
import { getProductOfferDetailsRoute } from 'presentation/features/offer/product/entry';
import TradeOfferHelpDialog from 'presentation/features/offer/trade/components/dialogs/help';
import { getTradeOfferDetailsRoute } from 'presentation/features/offer/trade/entry';
import UserActivationsLayout from 'presentation/features/user/details/activations/layout';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useDialogInHistory from 'presentation/hooks/useDialogInHistory';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getBookingOffersDetailsRoute } from '../../../offer/booking/entry';
import UserActivations from './component';
import { EUserActivationsDialogTag } from './utils';

type UserActivationsContainerProps = {
  readonly guid: UUID;
};

const pageSize: number = 10 as const;

const Layout = UserActivationsLayout;

const UserActivationsContainer = (props: UserActivationsContainerProps) => {
  const { guid } = props;

  const history = useHistory();

  const { personalPromotionTypes, publicPromotionTypes } = useSelector(nsiDataSelector);

  const { webAnalytics } = useWebAnalytics();

  const {
    open: isAccessCodeHelpDialogOpened,
    onOpen: onOpenAccessCodeHelpDialog,
    onClose: onCloseAccessCodeHelpDialog,
  } = useDialogInHistory({ tag: EUserActivationsDialogTag.AccessCode });

  const {
    open: isPromoCodeHelpDialogOpened,
    onOpen: onOpenPromoCodeHelpDialog,
    onClose: onClosePromoCodeHelpDialog,
  } = useDialogInHistory({ tag: EUserActivationsDialogTag.PromoCode });

  const {
    open: isVoucherHelpDialogOpened,
    onOpen: onOpenVoucherHelpDialog,
    onClose: onCloseVoucherHelpDialog,
  } = useDialogInHistory({ tag: EUserActivationsDialogTag.Voucher });

  const {
    open: isCertificateHelpDialogOpened,
    onOpen: onOpenCertificateHelpDialog,
    onClose: onCloseCertificateHelpDialog,
  } = useDialogInHistory({ tag: EUserActivationsDialogTag.Certificate });

  const argsStorage = usePageableArgsInRedux<UserActivationsRequest>({
    guid,
    defaultState: {
      guid,
      page: 1,
      pageSize,
    },
  });

  const {
    data: activations,
    totalCount,
    isFetching,
    isPreparing,
    isEmpty,
    loadMore,
  } = usePageableList({
    guid,
    paginationBehaviour: EPaginationBehaviour.IncrementPage,
    cacheProvider: useRtkQueryCachedPageableData<OfferActivation>({
      guid,
      tag: EUserServicesTag.Activations,
      payload: argsStorage.currentState,
    }),
    argsStorage,
    useQuery: useGetUserActivationsQuery,
  });

  const onOpenHelpDialog = (type: OfferPromotionType) => {
    switch (type) {
      case ETradeOfferPromotionType.AccessCode:
        onOpenAccessCodeHelpDialog();
        break;
      case ETradeOfferPromotionType.Promocode:
        onOpenPromoCodeHelpDialog();
        break;
      case ETradeOfferPromotionType.Voucher:
        onOpenVoucherHelpDialog();
        break;
      case ECorpOfferPromotionType.Certificate:
      case ECorpOfferPromotionType.ExternalCertificate:
        onOpenCertificateHelpDialog();
        break;
      case ETradeOfferPromotionType.PublicPromocode:
      case ETradeOfferPromotionType.ReferralLink:
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.Asp:
        break;
    }
  };

  const getActivationCodeTypeName = (type: OfferPromotionType): string => {
    return [...personalPromotionTypes, ...publicPromotionTypes].find(p => p.id === type)?.name ?? 'Код';
  };

  const onOfferClick = ({ id, type }: OfferDataShort) => {
    switch (type) {
      case EOfferType.Trade:
        return history.push(getTradeOfferDetailsRoute({ id }));
      case EOfferType.Corp:
        return history.push(getCorpOfferDetailsRoute({ id }));
      case EOfferType.Product:
        return history.push(getProductOfferDetailsRoute({ id }));
      case EOfferType.Booking:
        return history.push(getBookingOffersDetailsRoute({ id }));
    }
  };

  const onPartnerLinkClick = (activation: OfferActivation) => {
    webAnalytics.openPartnerUrl(activation.offer.id);
    webAnalytics.offerJumpToPartnerSite(activation.offer.id);
  };

  return (
    <>
      <Layout
        header='Мои привилегии'
        loader={isFetching && !activations && <ContentLoader position='fixed' />}
      >
        {isEmpty && <ActivationsStub />}
        {!isEmpty && activations && (
          <UserActivations
            activations={activations}
            totalCount={totalCount}
            isFetching={isFetching || isPreparing}
            onLoadNextPage={loadMore}
            onOfferClick={onOfferClick}
            onPartnerLinkClick={onPartnerLinkClick}
            onOpenHelpDialog={onOpenHelpDialog}
            getActivationCodeTypeName={getActivationCodeTypeName}
          />
        )}
      </Layout>

      <TradeOfferHelpDialog
        promotionType={ETradeOfferPromotionType.AccessCode}
        open={isAccessCodeHelpDialogOpened}
        onClose={onCloseAccessCodeHelpDialog}
      />
      <TradeOfferHelpDialog
        promotionType={ETradeOfferPromotionType.Promocode}
        open={isPromoCodeHelpDialogOpened}
        onClose={onClosePromoCodeHelpDialog}
      />
      <TradeOfferHelpDialog
        promotionType={ETradeOfferPromotionType.Voucher}
        open={isVoucherHelpDialogOpened}
        onClose={onCloseVoucherHelpDialog}
      />
      <CertificateDialog
        open={isCertificateHelpDialogOpened}
        onClose={onCloseCertificateHelpDialog}
      />
    </>
  );
};

export default UserActivationsContainer;
