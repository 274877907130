import { EOfferActivationStatus, EOfferStatus } from 'domain/model/enums';
import { useMemo } from 'react';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { CorpOfferSidebarArchivedMessage } from './archived';
import { CorpOfferSidebarPausedMessage } from './paused';
import { CorpOfferSidebarPendingInfoMessage } from './pendingInfo';
import { CorpOfferSidebarUnavailableMessage } from './unavailableInfo';
import { CorpOfferSidebarUpcomingMessage } from './upcomingInfo';
import { CorpOfferSidebarRejectedInfoMessage } from './rejectedInfo/rejectedInfo';

export const CorpOfferSidebarInfoMessagesAdapter = () => {
  const { corpOffer, isActivationReceivedNow, activation } = useCorpOfferSidebar();

  const isActivationAvailable = activation.isActivationAvailable;

  return useMemo(() => {
    if (!corpOffer) {
      return null;
    } else {
      if (!isActivationAvailable) {
        return <CorpOfferSidebarUnavailableMessage />;
      } else {
        return (
          <>
            {corpOffer.status === EOfferStatus.Upcoming && (
              <CorpOfferSidebarUpcomingMessage startDate={corpOffer.startDate} />
            )}

            {corpOffer.status === EOfferStatus.Paused && !isActivationReceivedNow && <CorpOfferSidebarPausedMessage />}

            {corpOffer.status === EOfferStatus.Archived && <CorpOfferSidebarArchivedMessage />}

            {activation.lastActivation?.status === EOfferActivationStatus.Pending && (
              <CorpOfferSidebarPendingInfoMessage
                activationComment={activation.lastActivation?.lastStatusComment || 'Ваша заявка будет рассмотрена '}
              />
            )}

            {!isActivationReceivedNow && activation.lastActivation?.status === EOfferActivationStatus.Rejected && (
              <CorpOfferSidebarRejectedInfoMessage
                activationComment={activation.lastActivation?.lastStatusComment || 'Предложение отклонено'}
              />
            )}
          </>
        );
      }
    }
  }, [
    activation.lastActivation?.lastStatusComment,
    activation.lastActivation?.status,
    corpOffer,
    isActivationAvailable,
    isActivationReceivedNow,
  ]);
};
