import ContentLoader from 'presentation/components/common/loader';
import { FCC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifier from 'system/notifier';
import { nsiSelector } from '../nsi/store/selectors';
import { nsiFetch } from '../nsi/store/slice';
import { Wrapper } from './controls';

const MetadataContainer: FCC = ({ children }) => {
  const dispatch = useDispatch();
  const { isFetched, isFailed } = useSelector(nsiSelector);

  useEffect(() => {
    dispatch(nsiFetch());
  }, [dispatch]);

  useEffect(() => {
    if (isFailed) Notifier.getInstance().addError('Не удалось загрузить справочную информацию');
  }, [isFailed]);

  if (!isFetched)
    return (
      <Wrapper>
        <ContentLoader />
      </Wrapper>
    );

  return <>{children}</>;
};

export default MetadataContainer;
