import { AxiosResponse } from 'axios';
import { Pageable } from 'domain/model';
import { OfferActivation } from 'domain/model/activation';
import { CorpOffer, CorpOfferShort } from 'domain/model/corpOffer';
import { ECorpOfferSortType, EOfferActivationSortType, EOfferStatus } from 'domain/model/enums';
import { DataFilterQueryDslOperator } from 'domain/model/filter';
import { Category, CategoryTree } from 'domain/model/nsi';
import { categoriesBuildTree } from 'presentation/utils/category';
import apiDefinition from '../openApi';
import { ApiQueryDsl } from '../openApi/types';
import { getPageableFromResponseHeaders } from '../openApi/utils';
import { api, ECorpOfferServicesTag, OfferListRequest, OffersCountRequest, OffersCountResponse } from './index';

export type CorpOfferSearchRequest = {
  readonly search: {
    readonly name: Nullable<string>;
  };
  readonly pageSize: number;
};

export type CorpOfferCategoriesUsedRequest = {
  readonly name?: Nullable<string>;
  readonly onlyLeafCategories?: Nullable<boolean>;
};

export type CorpOfferCategoriesUsedTreeRequest = {
  readonly guid: UUID;
};

export type AddCorpOfferToFavoritesRequest = {
  readonly id: UUID;
};

export type GetCorpOfferDetailsRequest = {
  readonly id: UUID;
};

export type ActivateCorpOfferRequest = {
  readonly id: UUID;
};

export type GetCorpOfferActivationsRequest = {
  readonly id: UUID;
};

export type ActivateCorpOfferResponse = Nullable<OfferActivation>;

const defaultCorpOfferActiveStatuses: EOfferStatus[] = [EOfferStatus.Active];
const defaultCorpOfferValidStatuses: EOfferStatus[] = [EOfferStatus.Active, EOfferStatus.Upcoming];

const corpOfferCategoriesTransformer = (categories: Category[]): Category[] =>
  categories; /*.filter(item => !!item.parentId)*/

export const corpOfferApi = api.injectEndpoints({
  endpoints: builder => ({
    getCorpOfferCategories: builder.query<Category[], { ids?: UUID[] }>({
      transformResponse: (response: AxiosResponse<Category[]>) => corpOfferCategoriesTransformer(response.data),
      query: ({ ids }) => {
        return apiDefinition.corpOffer.categories({ ids });
      },
    }),
    getCorpOfferCategory: builder.query<Category, { id: UUID }>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id }) => {
        return apiDefinition.corpOffer.category({ id });
      },
    }),
    getCorpOfferCategoriesUsed: builder.query<Category[], CorpOfferCategoriesUsedRequest>({
      transformResponse: (response: AxiosResponse<Category[]>) => corpOfferCategoriesTransformer(response.data),
      query: ({ name, onlyLeafCategories }) => {
        return apiDefinition.user.corp.categoriesUsed({
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          onlyLeafCategories,
        });
      },
    }),
    getCorpOfferCategoriesUsedTree: builder.query<CategoryTree, CorpOfferCategoriesUsedTreeRequest>({
      transformResponse: (response: AxiosResponse<Category[]>) => categoriesBuildTree(response.data),
      query: () => {
        return apiDefinition.user.corp.categoriesUsed({
          statuses: defaultCorpOfferValidStatuses,
          onlyLeafCategories: false,
        });
      },
    }),
    getCorpOfferList: builder.query<Pageable<CorpOfferShort>, OfferListRequest>({
      transformResponse: (response: AxiosResponse): Pageable<CorpOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);
        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, page, pageSize, sort }) => {
        const { name, categories, statuses = defaultCorpOfferActiveStatuses, partnerId } = search;
        return apiDefinition.user.corp.all({
          page: 1,
          pageSize: page * pageSize,
          sort,
          query: name,
          statuses,
          categories,
          partnerId,
        });
      },
      providesTags: [ECorpOfferServicesTag.List],
    }),
    getCorpOffersCount: builder.query<number, OffersCountRequest>({
      transformResponse: (response: AxiosResponse<OffersCountResponse[]>): number => {
        return response.data[0]?.count ?? 0;
      },
      query: ({ search }) => {
        const { name, categories } = search;
        return apiDefinition.user.corp.count({
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          categories,
        });
      },
    }),
    getCorpOfferSearch: builder.query<Pageable<CorpOfferShort>, CorpOfferSearchRequest>({
      keepUnusedDataFor: 0,
      transformResponse: (response: AxiosResponse): Pageable<CorpOfferShort> => {
        const pageable = getPageableFromResponseHeaders(response);

        return {
          data: response.data,
          totalCount: pageable.totalCount,
          pageCount: pageable.pageCount,
          page: pageable.page,
        };
      },
      query: ({ search, pageSize }) => {
        const { name } = search;

        return apiDefinition.user.corp.all({
          page: 1,
          pageSize,
          query: name,
          statuses: defaultCorpOfferValidStatuses,
          sort: [ECorpOfferSortType.Default],
        });
      },
    }),
    getCorpOfferDetails: builder.query<CorpOffer, GetCorpOfferDetailsRequest>({
      query: ({ id }) => apiDefinition.user.corp.one({ id }),
      transformResponse: (response: AxiosResponse) => response.data,
      providesTags: (result, error, args) => [
        { type: ECorpOfferServicesTag.Details, id: args.id },
        ECorpOfferServicesTag.Details,
      ],
    }),
    addCorpOfferToFavorites: builder.mutation<number, AddCorpOfferToFavoritesRequest>({
      transformResponse: (response: AxiosResponse) => response.status,
      query: ({ id }) =>
        apiDefinition.user.addOfferToFavorites({
          id,
        }),
      invalidatesTags: [ECorpOfferServicesTag.Details],
    }),
    getCorpOfferActivations: builder.query<OfferActivation[], GetCorpOfferActivationsRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id }) => {
        const queryDsl: ApiQueryDsl = [
          {
            field: 'offer.id',
            operator: DataFilterQueryDslOperator.Equals,
            value: [id],
          },
        ];
        return apiDefinition.activation.all({
          page: 1,
          pageSize: 100000,
          sort: [EOfferActivationSortType.ByCreatedAt],
          queryDsl,
        });
      },
      providesTags: (result, error, args) => [{ type: ECorpOfferServicesTag.Activations, id: args.id }],
    }),
    activateCorpOffer: builder.mutation<ActivateCorpOfferResponse, ActivateCorpOfferRequest>({
      transformResponse: (response: AxiosResponse) => response.data,
      query: ({ id }) => apiDefinition.activation.create({ offerId: id }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: activation } = await queryFulfilled;
          dispatch(
            corpOfferApi.util.updateQueryData('getCorpOfferActivations', { id }, draft => {
              Object.assign(draft, [...(draft || []), activation]);
            })
          );
        } catch (e: any) {
          console.error('New corpOffer activation append error', e);
        }
      },
    }),
  }),
});

export const {
  useGetCorpOfferListQuery,
  useGetCorpOffersCountQuery,
  useGetCorpOfferSearchQuery,
  useGetCorpOfferCategoriesQuery,
  useGetCorpOfferCategoriesUsedQuery,
  useGetCorpOfferCategoriesUsedTreeQuery,
  useGetCorpOfferDetailsQuery,
  useAddCorpOfferToFavoritesMutation,
  useActivateCorpOfferMutation,
  useGetCorpOfferActivationsQuery,
  useGetCorpOfferCategoryQuery,
} = corpOfferApi;
