import { Typography } from '@mui/material';
import { EOfferActivateError, EOfferStatus } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import { useMemo } from 'react';
import { PlusIcon } from '../../../../../../../media/icons';
import { MPLink } from '../../../../../../../theme/ui-kit/link';
import { getIsTradeOfferPersonalPromotionType } from '../../../../utils';
import useTradeOfferSidebarDialogs from '../../dialogProvider/useDialogs';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';

type TradeOfferSidebarPromotionNewProps = {
  readonly promotionDayLimitError: boolean;
  readonly activationIsFetching: boolean;
  readonly onOpenReactivationDialog: () => void;
};

const TradeOfferSidebarPromotionNew = (props: TradeOfferSidebarPromotionNewProps) => {
  const { promotionDayLimitError, activationIsFetching, onOpenReactivationDialog } = props;

  const isUnavailable = promotionDayLimitError || activationIsFetching;

  return (
    <MPLink
      color='primary.main'
      iconSize='small'
      startIcon={PlusIcon}
      onClick={isUnavailable ? undefined : onOpenReactivationDialog}
    >
      <Typography
        variant='body1'
        role='button'
      >
        получить новый
      </Typography>
      {activationIsFetching && <ContentLoader size={15} />}
    </MPLink>
  );
};

export const TradeOfferSidebarPromotionNewAdapter = () => {
  const { tradeOffer, isActivationAvailable, activation, isTimerActive } = useTradeOfferSidebar();
  const { openReactivationDialog } = useTradeOfferSidebarDialogs();

  const { lastActivation, isReactivationSupported, activationIsFetching } = activation;
  const lastPromotion = lastActivation?.promotion;

  const isCodesEnded = (tradeOffer?.notUsedOfferCount ?? 0) === 0;
  const promotionDayLimitError = activation?.activationError?.type === EOfferActivateError.PromotionDayCountLimit;

  return useMemo(
    () =>
      tradeOffer?.status === EOfferStatus.Active &&
      lastPromotion &&
      !isCodesEnded &&
      isReactivationSupported &&
      isActivationAvailable &&
      !(isTimerActive && lastActivation?.appointmentDate) &&
      getIsTradeOfferPersonalPromotionType(tradeOffer.promotionType) ? (
        <TradeOfferSidebarPromotionNew
          activationIsFetching={activationIsFetching}
          promotionDayLimitError={promotionDayLimitError}
          onOpenReactivationDialog={openReactivationDialog}
        />
      ) : null,
    [
      tradeOffer?.status,
      tradeOffer?.promotionType,
      lastActivation,
      lastPromotion,
      promotionDayLimitError,
      isActivationAvailable,
      isReactivationSupported,
      isCodesEnded,
      isTimerActive,
      activationIsFetching,
      openReactivationDialog,
    ]
  );
};
