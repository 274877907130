import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const OfferTagsWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${theme.spacing(2)};
    left: ${theme.spacing(2)};

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * + * {
      margin-top: ${theme.spacing()};
    }
  `
);
