import axios, { AxiosResponse } from 'axios';
import { AppOption } from 'domain/model';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { getBaseEndpoint } from './utils';

type OrderCancelReasonType = AppOption;

type OrderOfferApi = {
  readonly cancelCustomerReasonTypes: (props: ApiCancellable) => Promise<AxiosResponse<OrderCancelReasonType[]>>;
  readonly cancelReasonTypes: (props: ApiCancellable) => Promise<AxiosResponse<OrderCancelReasonType[]>>;
};

/**
 * АПИ по работе с заказми
 */
const order: OrderOfferApi = {
  cancelCustomerReasonTypes: ({ signal }) => {
    return axios.get(`${getBaseEndpoint()}/customer/order/cancel-reason-types`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  cancelReasonTypes: ({ signal }) => {
    return axios.get(`${getBaseEndpoint()}/orders/cancel-reason-types`, {
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
};

export default order;
