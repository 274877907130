import { useChangeUserLocationMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { UserLocalSettings, UserSettings } from 'system/userLocalSettings';
import useErrorTracking from '../../../hooks/useErrorTracking';

type UserSettingsProviderProps = {
  readonly userId: Nullable<UUID>;
};

export type UserSettingsContextType = {
  readonly settings: UserSettings;
  readonly setCity: (fiasId: UUID, name: string) => void;
  readonly setUserCookieAccepted: (value: boolean) => void;
  readonly isCurrentUserAcceptedCookies: boolean;
};

type UserSettingsProviderType = (props: PropsWithChildren<UserSettingsProviderProps>) => JSX.Element;

export const UserSettingsContext = React.createContext<UserSettingsContextType>({} as UserSettingsContextType);

const anonymousUserId = 'anonymous';

const UserSettingsProvider: UserSettingsProviderType = ({ children, ...others }) => {
  const userId = others.userId || anonymousUserId;
  const isAnonymous = userId === anonymousUserId;

  const userLocalSettings = useMemo<UserLocalSettings>(() => UserLocalSettings.getInstance(userId), [userId]);
  const settings = userLocalSettings.getSettings();

  const { captureException } = useErrorTracking();

  const [changeUserLocation, { error: changeUserLocationError }] = useChangeUserLocationMutation();

  const isCurrentUserAcceptedCookies = isAnonymous || settings?.cookieAccepted === true;

  const setCity = useCallback(
    (fiasId: UUID, name: string) => {
      if (isAnonymous) {
        const newSettings = {
          ...settings,
          city: {
            fiasId,
            name,
          },
        };
        userLocalSettings.setSettings(newSettings);
      } else {
        changeUserLocation({ userId, location: { id: fiasId, name } })
          .unwrap()
          .then(() => {
            const newSettings = {
              ...settings,
              city: {
                fiasId,
                name,
              },
            };
            userLocalSettings.setSettings(newSettings);
          })
          .catch(e => {
            console.error(e);
            captureException(e);
          });
      }
    },
    [isAnonymous, settings, userLocalSettings, changeUserLocation, userId, captureException]
  );

  const setUserCookieAccepted = useCallback(
    (value: boolean) => {
      const newSettings: UserSettings = {
        ...settings,
        cookieAccepted: value,
      };
      userLocalSettings.setSettings(newSettings);
    },
    [settings, userLocalSettings]
  );

  const value: UserSettingsContextType = useMemo(
    () => ({
      settings,
      setCity,
      setUserCookieAccepted,
      isCurrentUserAcceptedCookies,
    }),
    [settings, setCity, setUserCookieAccepted, isCurrentUserAcceptedCookies]
  );

  useEffect(() => {
    if (changeUserLocationError) {
      ErrorHandler.handleHttpError(changeUserLocationError);
    }
  }, [changeUserLocationError]);
  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
};

export default UserSettingsProvider;
