import { Typography } from '@mui/material';
import SidebarMessage from '../../../../../components/sidebar/message';

export const CurrentActivateErrrorPausedOfferWarnMessage = () => {
  return (
    <SidebarMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        Ошибка при получении. Попробуйте ещё раз позже
      </Typography>
    </SidebarMessage>
  );
};
