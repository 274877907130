import { useUpdateCurrentUserEmailMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { User, UserData } from 'domain/model/user';
import { userProfileValidation } from 'presentation/features/user/validation';
import useValidation from 'presentation/hooks/validation/useValidation';
import { ValidationResult } from 'presentation/utils/validation';
import { useEffect, useState } from 'react';

type Attribute = 'email';

type UseUserProfileEmailEditProps = {
  readonly user: User;
};

type UseUserProfileEmailEdit = {
  readonly value: UserData[Attribute];
  readonly isUpdating: boolean;
  readonly validation: Nullable<ValidationResult<UserData>>;
  readonly update: () => Promise<boolean>;
  readonly reset: () => void;
  readonly onChange: (newValue: UserData[Attribute]) => void;
};

export const useUserProfileEmailEdit = (props: UseUserProfileEmailEditProps): UseUserProfileEmailEdit => {
  const { user } = props;

  const [value, setValue] = useState<UserData[Attribute]>(() => user.email);

  const [
    updateCurrentUserEmail,
    {
      error: updateCurrentUserEmailError,
      isSuccess: isEmailUpdated,
      isLoading: isEmailUpdating,
      reset: updateCurrentUserEmailReset,
    },
  ] = useUpdateCurrentUserEmailMutation();

  const data: UserData = { ...user, email: value };

  const {
    validate,
    validationResult: validation,
    resetValidationResult,
  } = useValidation({
    object: data,
    rules: { email: userProfileValidation.email },
  });

  const update = () => {
    const isValid = validate();
    if (isValid) {
      return updateCurrentUserEmail({ id: user.id, email: data.email })
        .unwrap()
        .then(result => result === 200);
    }
    return Promise.resolve(false);
  };

  const reset = () => {
    resetValidationResult();
    setValue(user.email);
  };

  useEffect(() => {
    setValue(user.email);
  }, [user.email]);

  useEffect(() => {
    if (isEmailUpdated) {
      updateCurrentUserEmailReset();
    }
  }, [isEmailUpdated, updateCurrentUserEmailReset]);

  useEffect(() => {
    if (updateCurrentUserEmailError) {
      ErrorHandler.handleHttpError(updateCurrentUserEmailError);
    }
  }, [updateCurrentUserEmailError]);

  return {
    value,
    isUpdating: isEmailUpdating,
    validation,
    update,
    reset,
    onChange: setValue,
  };
};
