import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    width: 420px;

    margin: auto;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `
);

export const SubtitleWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > :not(:nth-child(1)) {
      margin-left: ${theme.spacing()};
    }

    margin-bottom: ${theme.spacing(2)};
  `
);

export const ActionsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > :not(:nth-child(1)) {
      margin-top: ${theme.spacing(2)};
    }

    & > :nth-last-child(1) {
      margin-bottom: ${theme.spacing(2)};
    }
  `
);
