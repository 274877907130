import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import moment from 'moment-timezone';
import SidebarMessage from 'presentation/features/offer/components/sidebar/message';

type TradeOfferSidebarUpcomingMessageProps = {
  readonly startDate: Nullable<string>;
}

export const TradeOfferSidebarUpcomingMessage = (props: TradeOfferSidebarUpcomingMessageProps) => {
  const { startDate } = props;

  return (
    <SidebarMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        Предложение будет доступно {startDate && <>с {moment(startDate).format(EDateFormat.Human)} года</>}
      </Typography>
    </SidebarMessage>
  );
};
