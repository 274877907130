export enum ENetworkErrorCode {
  UserBlocked = 'USER_BLOCKED',
  MissingUserAgreement = 'MISSING_USER_AGREEMENT',
}

export enum EHeaders {
  PreviewTarget = 'x-target-value',
  AppId = 'x-app-id',
}

export type ServerError = {
  readonly code: string;
  readonly field: string;
  readonly message: string;
};

export type ServerErrorResponse = {
  readonly code: string;
  readonly message: string;
  readonly description: string;
  readonly errors: ServerError[];
};
