import { CaseReducer, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { EHeaders } from 'data/network/types';

export type PreviewModeState = {
  targetParams: Nullable<string>;
};

type Reducer<T = undefined> = CaseReducer<PreviewModeState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<PreviewModeState> & {
  setTargetParams: Reducer<Nullable<string>>;
};

const slice = createSlice<PreviewModeState, Reducers, 'previewMode'>({
  name: 'previewMode',
  initialState: {
    targetParams: sessionStorage.getItem(EHeaders.PreviewTarget)
      ? JSON.parse(sessionStorage.getItem(EHeaders.PreviewTarget) as string)
      : null,
  },
  reducers: {
    setTargetParams: (state, { payload }) => {
      state.targetParams = payload;
    },
  },
});

export const { setTargetParams } = slice.actions;

export default slice.reducer;
