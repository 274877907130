import { AnalyticsInitializer } from '@mp-npm/mp-analytics-client';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useWebAnalytics } from '../hooks/useWebAnalytics';

export const WebAnalyticsInitializer = () => {
  const { isAuthenticated } = useAuth();
  const { webAnalytics } = useWebAnalytics();
  const history = useHistory();

  const adapters = useRef(isAuthenticated ? webAnalytics.getAdapters(history) : []);

  return useMemo(() => <AnalyticsInitializer adapters={adapters.current} />, []);
};
