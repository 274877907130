import { isValidPhoneFormat } from 'presentation/utils/phone';
import { useState } from 'react';
import { IsPhoneExistsDialog } from './exists';
import { IsPhoneInvalidFormatDialog } from './invalidFormat';
import { IsPhoneNotExistsDialog } from './notExists';
import { ConfirmPhoneNumberDialogProps } from './types';

const ConfirmPhoneNumberDialog = (props: ConfirmPhoneNumberDialogProps) => {
  const { phone } = props;

  const [editMode, setEditMode] = useState<boolean>(false);

  const isValidPhone = phone && isValidPhoneFormat(phone);

  const internalDialogProps = { ...props, editMode, onChangeEditMode: setEditMode };

  if (!phone || editMode) {
    return <IsPhoneNotExistsDialog {...internalDialogProps} />;
  }

  if (phone && !isValidPhone) {
    return <IsPhoneInvalidFormatDialog {...internalDialogProps} />;
  }

  return <IsPhoneExistsDialog {...internalDialogProps} />;
};

export default ConfirmPhoneNumberDialog;
