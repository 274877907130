import { Typography } from '@mui/material';
import { EOfferStatus } from 'domain/model/enums';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { EDateFormat } from '../../../../../../../../domain/model/formats';
import { getLeftSeconds } from '../../../../../../../utils/date';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';

const TradeOfferSidebarLeftPeriod = ({ endDate }: { endDate: string }) => {
  return <Typography variant='body2'>Действует до {moment(endDate).locale('ru').format(EDateFormat.Human)}</Typography>;
};

export const TradeOfferSidebarLeftPeriodAdapter = () => {
  const { tradeOffer } = useTradeOfferSidebar();

  const endDate = tradeOffer ? moment(tradeOffer.endDate).endOf('day') : null;
  const leftSeconds = endDate ? getLeftSeconds(endDate.endOf('day')) : null;

  const isActiveOfferByTime = leftSeconds && leftSeconds > 0;

  return useMemo(
    () =>
      tradeOffer?.endDate && tradeOffer?.status === EOfferStatus.Active && isActiveOfferByTime ? (
        <TradeOfferSidebarLeftPeriod endDate={tradeOffer.endDate} />
      ) : null,
    [tradeOffer?.endDate, tradeOffer?.status, isActiveOfferByTime]
  );
};
