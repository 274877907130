import { OfferListRequest } from 'data/api';
import { useGetBookingOfferListQuery } from 'data/api/bookingOffer';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { OfferFavoriteCommonContainer } from 'presentation/features/offer/favorite';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BookingOfferListItem from '../components/listItem';
import { BookingOffersSearchListLocationState } from '../types';
import { bookingOffersDefaultParams } from '../utils';
import useCategoriesData from './hooks/useCategoriesData';
import { useContextHandlers } from './hooks/useContextHandlers';
import { bookingOfferIsFilterFetchingSelector } from './store/selectors';
import { BookingOfferListFilter } from './store/types';

type BookingOfferListDataProps = BookingOfferListFilter &
  Pick<BookingOffersSearchListLocationState, 'categoryId'> & {
    readonly guid: UUID;
    readonly args: OfferListRequest;
  };

const BookingOfferListData: FC<BookingOfferListDataProps> = ({
  args,
  args: { page },
  priceRange,
  services,
  guid,
  categoryId,
}) => {
  const { onChangePage } = useContextHandlers();

  const { isAuthenticated } = useAuth();
  const { subCategories } = useCategoriesData();

  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);

  // Запрос зависит только от аргументов, дублированных в стейт для корректности работы селекторов
  const { data, error } = useGetBookingOfferListQuery(
    {
      ...args,
      ...(categoryId ? priceRange : bookingOffersDefaultParams.priceRange),
      services: categoryId ? services : [],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !!categoryId && isFilterFetching,
    }
  );

  const onLoadMore = useCallback(() => {
    onChangePage(page + 1);
  }, [page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const offers = data?.data;
  const totalCount = data?.totalCount ?? 0;
  const hasOffers = !!offers && offers.length > 0;

  const hasSubCategories = !!subCategories?.length;

  if (!hasOffers) {
    return null;
  }

  return (
    <CardPageableList
      span={categoryId || hasSubCategories ? 4 : 3}
      lgSpan={categoryId || hasSubCategories ? 6 : 4}
      mdSpan={categoryId || hasSubCategories ? 6 : 4}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
    >
      {offers.map(offer => (
        <BookingOfferListItem
          key={offer.id}
          offer={offer}
          favorite={
            isAuthenticated && (
              <OfferFavoriteCommonContainer
                guid={guid}
                offerId={offer.id}
                offerType={EOfferType.Booking}
              />
            )
          }
        />
      ))}
    </CardPageableList>
  );
};

export default BookingOfferListData;
