import { Typography } from '@mui/material';
import SidebarMessage from 'presentation/features/offer/components/sidebar/message';

export const CorpOfferSidebarRejectedWarnMessage = () => {
  return (
    <SidebarMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        Отказ
      </Typography>
    </SidebarMessage>
  );
};
