import { Typography } from '@mui/material';
import { EOfferActivateError, TradePersonalPromotionType } from 'domain/model/enums';
import { TradeOffer } from 'domain/model/tradeOffer';
import UserNeedFillProfileDialog from 'presentation/features/user/components/needFillProfileDialog';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { FCC, useCallback, useEffect, useState } from 'react';
import useDialogInHistory from '../../../../../../hooks/useDialogInHistory';
import OfferUnavailableDialog from '../../../../components/dialogs/unavailable';
import { getPersonalPromotionInstrumentalLabel, getTradeOfferPromotionLabel } from '../../../../utils';
import TradeOfferHelpDialog from '../../../components/dialogs/help';
import { ETradeOfferDetailsDialogTag } from '../../utils';
import useTradeOfferSidebar from '../provider/useTradeOfferSidebar';
import TradeOfferSidebarDialogsContext, { TradeOfferSidebarDialogsContextType } from './context';

type ConfirmDialogButtonsProps = {
  readonly onAccept: () => void;
  readonly onReject: () => void;
};

const ConfirmDialogButtons = ({ onAccept, onReject }: ConfirmDialogButtonsProps) => (
  <MPGrid
    container
    spacing={2}
  >
    <MPGrid
      item
      xs
    >
      <MPButton
        fullWidth
        color='brand'
        size='large'
        onClick={onAccept}
      >
        Да
      </MPButton>
    </MPGrid>
    <MPGrid item>
      <MPButton
        color='brand'
        size='large'
        onClick={onReject}
        variant='outlined'
      >
        Нет
      </MPButton>
    </MPGrid>
  </MPGrid>
);

type TradeOfferSidebarDialogsProviderProps = {
  readonly tradeOffer: TradeOffer;
  readonly onShowAllOffers: () => void;
};

const TradeOfferSidebarDialogsProvider: FCC<TradeOfferSidebarDialogsProviderProps> = props => {
  const { children, tradeOffer, onShowAllOffers } = props;
  // TODO (@Protopopov Ruslan): странновато в одном провайдере зависеть от другого провайдера так явно
  const { activation, stopTimer, onActivate, onFillProfile } = useTradeOfferSidebar();

  const [profileDialogState, setProfileDialogState] = useState<Nullable<{ message?: string }>>();
  const [reactivationDialogVisible, setReactivationDialogVisible] = useState<boolean>(false);

  const {
    open: isHelpDialogOpened,
    onOpen: onOpenHelpDialog,
    onClose: onCloseHelpDialog,
  } = useDialogInHistory({ tag: ETradeOfferDetailsDialogTag.Help });

  const {
    open: isUnavailableDialogOpened,
    onOpen: onOpenUnavailableDialog,
    onClose: onCloseUnavailableDialog,
  } = useDialogInHistory({ tag: ETradeOfferDetailsDialogTag.Unavailable });

  const label = getTradeOfferPromotionLabel(tradeOffer.promotionType);
  const instrumentalLabel = getPersonalPromotionInstrumentalLabel(
    tradeOffer.promotionType as TradePersonalPromotionType
  );

  const openReactivationDialog = useCallback(() => setReactivationDialogVisible(true), [setReactivationDialogVisible]);

  const onActivateInternal = useCallback(() => {
    setReactivationDialogVisible(false);
    stopTimer();
    onActivate();
  }, [stopTimer, onActivate]);

  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.ProfileNotFilled) {
      setProfileDialogState({ message: activation.activationError.message });
    }
  }, [activation.activationError, setProfileDialogState]);

  const value: TradeOfferSidebarDialogsContextType = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openReactivationDialog,
    openHelpDialog: onOpenHelpDialog,
    onActivate,
    onShowAllOffers,
  };

  return (
    <TradeOfferSidebarDialogsContext.Provider value={value}>
      {children}
      <OfferUnavailableDialog
        open={isUnavailableDialogOpened}
        onShowOtherOffers={onShowAllOffers}
        onClose={onCloseUnavailableDialog}
      />
      <TradeOfferHelpDialog
        promotionType={tradeOffer.promotionType}
        open={isHelpDialogOpened}
        onClose={onCloseHelpDialog}
      />
      <MPConfirmDialog
        maxWidth='xs'
        title={`Получение нового ${instrumentalLabel.toLowerCase()}`}
        open={reactivationDialogVisible}
        onClose={() => setReactivationDialogVisible(false)}
        buttons={
          <ConfirmDialogButtons
            onAccept={onActivateInternal}
            onReject={() => setReactivationDialogVisible(false)}
          />
        }
      >
        <Typography
          color='textPrimary'
          component='span'
        >
          У вас есть {label.toLowerCase()}. Хотите получить новый?
        </Typography>
      </MPConfirmDialog>
      <UserNeedFillProfileDialog
        open={!!profileDialogState}
        message={profileDialogState?.message}
        onFill={onFillProfile}
        onClose={() => setProfileDialogState(null)}
      />
    </TradeOfferSidebarDialogsContext.Provider>
  );
};

export default TradeOfferSidebarDialogsProvider;
