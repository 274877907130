import styled from '@emotion/styled/macro';
import { buttonBaseClasses, buttonClasses, ButtonProps, css, svgIconClasses } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import DottedButton from '../../../../../../../components/common/buttons/dotted';

export const SPButton = styled(MPButton)(
  ({ theme }) =>
    css`
      ${theme.breakpoints.up('md')} {
        font-size: ${theme.typography.body2.fontSize};
      }
    `
) as typeof MPButton;

type StyledButtonProps = ButtonProps & {
  readonly fetching: boolean;
};

export const CertificateButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  .${buttonBaseClasses.root} {
    padding: 0.75rem 1.25rem;

    ${p => p.theme.breakpoints.down('sm')} {
      padding: 0.75rem 1.25rem;
    }
  }
`;

export const StyledDottedButton = styled(({ disabled, ...others }: StyledButtonProps) => (
  <DottedButton
    disabled={disabled}
    {...others}
  />
))(
  ({ theme, disabled, fetching }) => css`
    &.${buttonClasses.root} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      background-color: ${disabled ? theme.palette.secondary.A600 : theme.palette.primary.A500};

      ${disabled && !fetching && 'border-color: transparent;'}
      ${disabled && `border-color: ${theme.palette.secondary.A900};'`}
    }

    .${svgIconClasses.root} {
      margin-left: ${theme.spacing()};
    }
  `
);
