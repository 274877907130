import { Theme, useMediaQuery } from '@mui/material';
import SearchControlContainer from 'presentation/features/search/control/container';
import { useContextHandlers } from '../hooks/useContextHandlers';

const SearchAdapter = () => {
  const handlers = useContextHandlers();

  const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const onClick = isSmUp ? undefined : handlers.showCatalog;

  return (
    <SearchControlContainer
      placeholder='Искать предложения'
      /*suggestions={SearchSuggestionsContainer}*/
      onClick={onClick}
    />
  );
};

export default SearchAdapter;
