import { PopoverProps, useMediaQuery } from '@mui/material';
import { User } from 'domain/model/user';
import React, { useMemo } from 'react';
import Splitter from '../../../../components/common/splitter';
import HeaderUserMenuAdapter from '../../adapters/userMenu';
import { HeaderUserFull } from '../user';
import { Menu, SwipeableDrawer, UserInfoWrapper } from './controls';

type HeaderMenuProps = {
  readonly user: User;
  readonly balance: {
    readonly value: Nullable<number>;
    readonly isFetching: boolean;
    readonly onClick: () => void;
  };
  readonly open: boolean;
  readonly anchorEl: Nullable<HTMLElement>;
  readonly onClose: () => void;
  readonly onOpen: () => void;
  readonly onLocationClick: () => void;
};

type UserMenuWrapperProps = {
  readonly open: boolean;
  readonly anchorEl: Nullable<HTMLElement>;
  readonly drawer?: boolean;
  readonly children: React.ReactNode;
  readonly onClose: () => void;
  readonly onOpen: () => void;
};

const UserMenuWrapper = ({ drawer, open, anchorEl, children, onClose, onOpen }: UserMenuWrapperProps) => {
  const isMdOnly = useMediaQuery(theme => theme.breakpoints.only('md'));

  if (drawer) {
    return (
      <SwipeableDrawer
        open={open}
        keepMounted
        disableSwipeToOpen={false}
        anchor='right'
        onClose={onClose}
        onOpen={onOpen}
      >
        {children}
      </SwipeableDrawer>
    );
  }

  const menuPositionProps: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'> = {};

  if (isMdOnly) {
    menuPositionProps.anchorOrigin = {
      vertical: 'top',
      horizontal: 'right',
    };
    menuPositionProps.transformOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    };
  } else {
    menuPositionProps.anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    };
    menuPositionProps.transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    };
  }

  return (
    <Menu
      open={open}
      keepMounted
      anchorEl={anchorEl}
      onClose={onClose}
      {...menuPositionProps}
    >
      {children}
    </Menu>
  );
};

const HeaderMenu = (props: HeaderMenuProps) => {
  const { user, balance, open, anchorEl, onClose, onOpen, onLocationClick } = props;

  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isLgDown = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const userInfo = useMemo(
    () => (
      <UserInfoWrapper>
        <HeaderUserFull
          user={user}
          balance={balance.value}
          isFetching={balance.isFetching}
        />
      </UserInfoWrapper>
    ),
    [balance.isFetching, balance.value, user]
  );

  return (
    <UserMenuWrapper
      drawer={isMdDown}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      onOpen={onOpen}
    >
      {userInfo}
      <Splitter size={isMdDown ? 2 : 1.5} />
      <HeaderUserMenuAdapter onLocationClick={isLgDown ? onLocationClick : null} />
    </UserMenuWrapper>
  );
};

export default HeaderMenu;
