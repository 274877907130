import { CorpOffer } from 'domain/model/corpOffer';
import { EOfferActivateError, EOfferActivationStatus } from 'domain/model/enums';
import UserNeedFillProfileDialog from 'presentation/features/user/components/needFillProfileDialog';
import { FCC, useCallback, useEffect, useState } from 'react';
import OfferUnavailableDialog from '../../../../components/dialogs/unavailable';
import CorpOfferGetCertDialog from '../../dialogs/getCert';
import CorpOfferGetNewCertDialog from '../../dialogs/getNewCert';
import NdflHelpDialog from '../../dialogs/help/ndfl';
import useCorpOfferSidebar from '../provider/useCorpOfferSidebar';
import CorpOfferSidebarDialogsContext, { CorpOfferSidebarDialogsContextType } from './context';

type CorpOfferSidebarDialogsProviderProps = {
  readonly corpOffer: CorpOffer;
  readonly onShowAllOffers: () => void;
};

const CorpOfferSidebarDialogsProvider: FCC<CorpOfferSidebarDialogsProviderProps> = props => {
  const { children, corpOffer, onShowAllOffers } = props;
  const { activation, onFillProfile, onActivate } = useCorpOfferSidebar();

  const [ndflHelpDialogVisible, setNdflHelpDialogVisible] = useState<boolean>(false);
  const [unavailableDialogVisible, setUnavailableDialogVisible] = useState<boolean>(false);
  const [certificateDialogVisible, setCertificateDialogVisible] = useState<boolean>(false);
  const [newCertificateDialogVisible, setNewCertificateDialogVisible] = useState<boolean>(false);
  const [profileDialogState, setProfileDialogState] = useState<Nullable<{ message?: string }>>(null);

  const onOpenNdflHelpDialog = useCallback(() => setNdflHelpDialogVisible(true), [setNdflHelpDialogVisible]);
  const onCloseNdflHelpDialog = useCallback(() => setNdflHelpDialogVisible(false), [setNdflHelpDialogVisible]);

  const onOpenUnavailableDialog = useCallback(() => setUnavailableDialogVisible(true), [setUnavailableDialogVisible]);
  const onCloseUnavailableDialog = useCallback(() => setUnavailableDialogVisible(false), [setUnavailableDialogVisible]);

  const onOpenCertificateDialog = useCallback(() => setCertificateDialogVisible(true), [setCertificateDialogVisible]);
  const onCloseCertificateDialog = useCallback(() => setCertificateDialogVisible(false), [setCertificateDialogVisible]);

  const onOpenNewCertificateDialog = useCallback(
    () => setNewCertificateDialogVisible(true),
    [setNewCertificateDialogVisible]
  );
  const onCloseNewCertificateDialog = useCallback(
    () => setNewCertificateDialogVisible(false),
    [setNewCertificateDialogVisible]
  );

  const onActivateCertificate = useCallback(() => {
    onCloseCertificateDialog();
    onCloseNewCertificateDialog();
    onActivate();
  }, [onActivate, onCloseCertificateDialog, onCloseNewCertificateDialog]);

  const openCertificateDialog = useCallback(() => {
    if (activation.lastActivation?.status === EOfferActivationStatus.Approved || activation.lastActivation?.status === EOfferActivationStatus.Given) {
      onOpenNewCertificateDialog();
    } else {
      onOpenCertificateDialog();
    }
  }, [activation.lastActivation?.status, onOpenNewCertificateDialog, onOpenCertificateDialog]);

  useEffect(() => {
    if (activation.activationError?.type === EOfferActivateError.ProfileNotFilled) {
      setProfileDialogState({ message: activation.activationError.message });
    }
  }, [activation.activationError]);

  const value: CorpOfferSidebarDialogsContextType = {
    openUnavailableDialog: onOpenUnavailableDialog,
    openNdflHelpDialog: onOpenNdflHelpDialog,
    openCertificateDialog,
    onShowAllOffers,
  };

  return (
    <CorpOfferSidebarDialogsContext.Provider value={value}>
      {children}
      <NdflHelpDialog
        open={ndflHelpDialogVisible}
        description={corpOffer.ndflDescription}
        onClose={onCloseNdflHelpDialog}
      />
      <OfferUnavailableDialog
        open={unavailableDialogVisible}
        onShowOtherOffers={onShowAllOffers}
        onClose={onCloseUnavailableDialog}
      />
      <CorpOfferGetCertDialog
        open={certificateDialogVisible}
        price={corpOffer.price}
        offerName={corpOffer.name}
        offerEndDate={corpOffer.endDate}
        activationExpiryDays={corpOffer.activationExpiryDays ?? 0}
        onGetCertificate={onActivateCertificate}
        onClose={onCloseCertificateDialog}
      />
      <CorpOfferGetNewCertDialog
        open={newCertificateDialogVisible}
        price={corpOffer.price}
        offerEndDate={corpOffer.endDate}
        activationExpiryDays={corpOffer.activationExpiryDays ?? 0}
        onGetCertificate={onActivateCertificate}
        onClose={onCloseNewCertificateDialog}
      />
      <UserNeedFillProfileDialog
        open={!!profileDialogState}
        message={profileDialogState?.message}
        onFill={onFillProfile}
        onClose={() => setProfileDialogState(null)}
      />
    </CorpOfferSidebarDialogsContext.Provider>
  );
};

export default CorpOfferSidebarDialogsProvider;
