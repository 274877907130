import { FC } from 'react';
import { Divider, Typography } from '@mui/material';
import { EOrderByDateType } from 'domain/model/enums';
import { toRubCurrency } from 'presentation/utils/currency';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import { ESlotNameArea, SlotsGridArea } from '../../../components/offerSlotSidebar/layout/controls';
import { BookingCartItem, BookingModalSlotItem } from '../../details/types';
import { BookingSlotItem } from './slotItem';
import BookingSidebarSlotRange from './slotRange';
import BookingSidebarSlotMultiple from './slotMultiple';
import BookingSidebarSlotActions from './slotActions';
import { SlotProps } from './types';

type BookingSidebarSlotProps = Omit<BookingCartItem, 'index'> &
  Omit<SlotProps, 'index'> & {
    slotIndex: number;
    divider: boolean;
  };

const BookingSidebarSlot: FC<BookingSidebarSlotProps> = ({ priceUnit, orderItem, divider, slotIndex, ...actions }) => {
  const item = priceUnit.priceItems?.find(({ id }) => id === orderItem.priceItem.id);

  if (!item) {
    return null;
  }

  const isNoDate = priceUnit.orderByDateType === EOrderByDateType.None;

  return (
    <SlotsGridArea
      area={ESlotNameArea.title}
      key={ESlotNameArea.title}
    >
      <Typography variant='subtitle1'>{priceUnit.name}</Typography>

      <Typography
        variant='body1'
        mb={1.5}
      >
        {item.name} &mdash; {toRubCurrency(item.price)} {item.unit?.name?.toLocaleLowerCase() ?? ''}
      </Typography>

      {priceUnit.orderByDateType === EOrderByDateType.Period && (
        <BookingSidebarSlotRange
          {...actions}
          price={item.price ?? 0}
          item={orderItem}
          index={slotIndex}
        />
      )}

      {priceUnit.orderByDateType === EOrderByDateType.Days && (
        <BookingSidebarSlotMultiple
          {...actions}
          price={item.price ?? 0}
          item={orderItem}
          index={slotIndex}
        />
      )}

      {isNoDate && (
        <>
          <MPGrid
            container
            spacing={1}
          >
            <BookingSlotItem title='Количество'>{orderItem.qty}</BookingSlotItem>
          </MPGrid>
          <BookingSidebarSlotActions
            {...actions}
            unitType={EOrderByDateType.None}
            price={item.price ?? 0}
            slots={[{ qty: orderItem.qty } as BookingModalSlotItem]}
            index={slotIndex}
          />
        </>
      )}

      {divider && (
        <MPGrid
          item
          xs={12}
        >
          <Splitter size={2} />
          <Divider color='secondaryLight' />
        </MPGrid>
      )}
    </SlotsGridArea>
  );
};

export default BookingSidebarSlot;
