import { CatalogContainer } from 'presentation/features/catalog/container';
import { ECatalogUrlParam } from 'presentation/features/catalog/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { headerCatalogDialogVisibleSelector } from '../store/selectors';
import { getBookingOffersListRoute } from '../../offer/booking/entry';
import { getTradeOffersListRoute } from '../../offer/trade/entry';
import { getCorpOffersListRoute } from '../../offer/corp/entry';
import { getProductOfferListRoute } from '../../offer/product/entry';

const CatalogAdapter = () => {
  const location = useLocation();

  const matchedOfferSectionRoute = useRouteMatch([
    getBookingOffersListRoute({}).pathname,
    getTradeOffersListRoute({}).pathname,
    getCorpOffersListRoute({}).pathname,
    getProductOfferListRoute({}).pathname,
  ]);

  const handlers = useContextHandlers();

  const selectedCategory = useMemo<Nullable<UUID>>(() => {
    const searchParams = new URLSearchParams(location.search);
    const selected: Nullable<UUID> = searchParams.get(ECatalogUrlParam.Category) ?? null;
    return selected;
  }, [location.search]);

  const catalogVisible = useSelector(headerCatalogDialogVisibleSelector);

  return (
    <CatalogContainer
      matchedOfferSectionPath={matchedOfferSectionRoute?.path ?? null}
      open={catalogVisible}
      selected={selectedCategory}
      onClose={handlers.hideCatalog}
    />
  );
};

export default CatalogAdapter;
