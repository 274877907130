import { ECorpOfferPromotionType, EOfferActivationStatus } from 'domain/model/enums';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { getOfferPromotionDownloadUrl } from '../../../../../utils';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { OfferPromotionDownload } from '../../../../../components/promotionDownload/downloadUrl';

export const CorpOfferSidebarPromotionAdapter = () => {
  const { corpOffer, activation, onTakeActivation, isTaking } = useCorpOfferSidebar();

  const { lastActivation } = activation;

  const promotionDownloadUrl = activation.lastActivation
    ? getOfferPromotionDownloadUrl(activation.lastActivation)
    : null;

  const renderPromotions = () => {
    if (!lastActivation) {
      return null;
    }

    switch (lastActivation.promotion.type) {
      case ECorpOfferPromotionType.Certificate:
        return (
          corpOffer &&
          (activation.lastActivation?.status === EOfferActivationStatus.Approved ||
            activation.lastActivation?.status === EOfferActivationStatus.Given) &&
          promotionDownloadUrl && (
            <MPGrid
              container
              alignItems='center'
              spacing={1}
            >
              <MPGrid
                item
                zero={12}
              >
                <OfferPromotionDownload
                  label={lastActivation.promotion.code}
                  onClick={() => onTakeActivation(lastActivation)}
                  isFetching={isTaking}
                />
              </MPGrid>
            </MPGrid>
          )
        );
      default:
        return null;
    }
  };

  return renderPromotions();
};
