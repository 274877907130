import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const StyledFileDownloadButton = styled(MPButton)(
  ({ theme }) => css`
    &.MuiButtonBase-root {
      padding-left: ${theme.spacing(2)};
      padding-right: ${theme.spacing(2)};

      border: 0;
      background-color: ${theme.palette.primary.A500};

      font-weight: ${theme.typography.fontWeightMedium};

      .MuiTypography-root {
        font-weight: ${theme.typography.fontWeightMedium};
      }
    }
  `
);
