import { MPChip } from '../../../../theme/ui-kit/chip';
import { OfferTag as OfferTagType } from '../../../../../domain/model/offer';
import { EOfferClientTagType, EOfferServerTagType, OfferTagsType } from '../../../../../domain/model/enums';
import { ReactElement } from 'react';
import { CoinIcon } from '../../../../media/icons';
import { ChipProps } from '@mui/material';

type OfferTagProps = {
  readonly offerTag: OfferTagType;
};

export const getOfferTagIcon = (offerTagType: OfferTagsType): ReactElement => {
  switch (offerTagType) {
    case EOfferServerTagType.HIT:
    case EOfferServerTagType.SALE:
    case EOfferServerTagType.NEW:
      return <></>;
    case EOfferClientTagType.ASP:
      return <CoinIcon />;
  }
};

export const getOfferTagColor = (offerTagType: OfferTagsType): ChipProps['color'] => {
  switch (offerTagType) {
    case EOfferServerTagType.HIT:
      return 'warning';
    case EOfferServerTagType.SALE:
      return 'error';
    case EOfferServerTagType.NEW:
      return 'success';
    case EOfferClientTagType.ASP:
      return 'brand';
  }
};

const OfferTag = (props: OfferTagProps) => {
  const { offerTag } = props;
  return (
    <MPChip
      size='small'
      icon={getOfferTagIcon(offerTag.type)}
      color={getOfferTagColor(offerTag.type)}
      label={offerTag.name}
    />
  );
};

export default OfferTag;
