import { Typography } from '@mui/material';
import SidebarMessage from '../../../../../components/sidebar/message';

type CorpOfferSidebarPendingMessageProps = {
  readonly activationComment: Nullable<string>;
};

export const CorpOfferSidebarPendingInfoMessage = (props: CorpOfferSidebarPendingMessageProps) => {
  const { activationComment } = props;

  return (
    <SidebarMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        {activationComment}
      </Typography>
    </SidebarMessage>
  );
};
