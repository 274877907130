import { buildAuthIDPBindProfileUrl } from '@mp-npm/mp-auth-client';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetCurrentUserQuery } from 'data/api/user';
import { EUserRole } from 'domain/model/enums';
import { User } from 'domain/model/user';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { getAllowedRoles, getUserDefaultRoute } from 'presentation/features/user/utils';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { authCheckIdpLinkPage } from 'presentation/utils/auth';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppConfigurator } from 'system/appConfigurator';
import { authProviderTokenParsedAccessSelector } from './store/selectors';
import HttpClient from '../../../../data/network/http';

type UseAuthUser = {
  readonly user: Nullable<User>;
  readonly userId: Nullable<UUID>;
  readonly isCorpUser: boolean;
  readonly roles: EUserRole[];
  readonly defaultRoute: string;
  readonly bindRzhdProfile: () => void;
  readonly onResetPassword: () => Promise<boolean | Error>;
};

const hasRole = (roles: EUserRole[]) => (role: EUserRole) => roles?.some(allowedRole => role === allowedRole) ?? false;

export const useAuthUser = (): UseAuthUser => {
  const { hasFeature } = useTechConfig();
  const { tokenParsed } = useAuthToken();

  const accessRoles = useSelector(authProviderTokenParsedAccessSelector);
  const roles = useMemo(() => getAllowedRoles(accessRoles ?? []), [accessRoles]);
  const defaultRoute = useMemo(() => getUserDefaultRoute(hasRole(roles), hasFeature), [hasFeature, roles]);
  const isCorpUser = roles.some(r => r === EUserRole.Corp);

  const { data: user } = useGetCurrentUserQuery(roles?.length ? {} : skipToken);

  const bindRzhdProfile = () => {
    if (!tokenParsed) {
      return;
    }

    const { realm, idpRzhdPortal, clientId, url: authServiceUrl } = AppConfigurator.getInstance().getApiAuthService();
    const { nonce, session_state: sessionState } = tokenParsed;

    const url = buildAuthIDPBindProfileUrl({
      authUrl: authServiceUrl,
      nonce: nonce ?? '',
      sessionState: sessionState ?? '',
      idpId: idpRzhdPortal,
      clientId,
      realm,
      authCheckIdpLinkPage,
    });
    console.log('Запрос на привязку профиля стороннего IDP', url);
    const newWindow = window.open(
      url,
      'bindRzhdProfile',
      'width=500,height=600,menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes'
    );

    if (!newWindow) {
      console.warn(
        'В браузере установлен запрет на открытие всплывающих окон, будет выполнена попытка синхронной авторизации'
      );
      window.location.href = url;
    }
  };

  const onResetPassword = async () => {
    const fn = HttpClient.getInstance().getAuthService()?.onResetPassword;
    if (!fn) {
      return Promise.reject(new Error('Функция не определена'));
    }

    if (user?.id) {
      return fn(user.id);
    } else {
      return Promise.reject(new Error('Текущий пользователь не определен'));
    }
  };

  return {
    user: user ?? null,
    userId: user?.id ?? null,
    roles,
    isCorpUser,
    defaultRoute,
    bindRzhdProfile,
    onResetPassword,
  };
};
