import React from 'react';
import { Wrapper } from './controls';

type SidebarWrapperProps = {
  readonly children: React.ReactNode;
};

const TradeOfferSidebarWrapper = React.forwardRef(({ children }: SidebarWrapperProps, ref: any) => {
  return <Wrapper ref={ref}>{children}</Wrapper>;
});

export default TradeOfferSidebarWrapper;
