import { Link } from '@mui/material';
import { EOfferStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import CorpLinkProxy from 'presentation/components/common/links/proxy';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { useMemo } from 'react';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';

const TradeOfferSidebarPartnerLink = ({ link, onLinkClick }: { link: string; onLinkClick: () => void }) => {
  return (
    <CorpLinkProxy link={link}>
      {linkByProxy => (
        <MPButton
          component={Link}
          size='large'
          href={linkByProxy}
          fullWidth
          color='brand'
          target='_blank'
          onClick={onLinkClick}
        >
          Перейти на сайт партнёра
        </MPButton>
      )}
    </CorpLinkProxy>
  );
};

export const TradeOfferSidebarPartnerLinkAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, onPartnerButtonClick } = useTradeOfferSidebar();

  return useMemo(() => {
    const displayPartnerLink =
      tradeOffer?.partnerLink &&
      (tradeOffer?.status === EOfferStatus.Active || isActivationReceivedNow) &&
      tradeOffer?.promotionType !== ETradeOfferPromotionType.ReferralLink;

    return displayPartnerLink ? (
      <TradeOfferSidebarPartnerLink
        link={tradeOffer.partnerLink}
        onLinkClick={onPartnerButtonClick}
      />
    ) : null;
  }, [
    tradeOffer?.partnerLink,
    tradeOffer?.status,
    tradeOffer?.promotionType,
    isActivationReceivedNow,
    onPartnerButtonClick,
  ]);
};
