export enum ETradeOfferDetailsTab {
  Description = 'description',
  AboutCompany = 'about-company',
  Contacts = 'contacts',
}

export const createPartnerLinkWithAccessCode = (url: string, code: Nullable<string>) => {
  if (!code) return null;

  return code ? `${url}?code=${code}` : '';
};

export enum ETradeOfferDetailsDialogTag {
  Help = 'help',
  Unavailable = 'unavailable',
}
