import { Typography } from '@mui/material';
import { Wrapper } from './controls';

type UserBalanceProps = {
  readonly value: Nullable<number>;
};

const UserBalance = ({ value }: UserBalanceProps) => {
  return (
    value !== null && (
      <Wrapper>
        <Typography
          variant='subtitle2'
          component='div'
          color='white.main'
        >
          {value}
        </Typography>
      </Wrapper>
    )
  );
};

export default UserBalance;
