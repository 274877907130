import { paginationSizeVariant } from 'domain/model/constants';

export type PaginationSize = (typeof paginationSizeVariant)[number];

export type OnChangeObjectAttribute<T> = <A extends keyof T>(name: A, value: T[A]) => void;

export enum EAppFeature {
  PartnerWindow = 'cmsPartner',
  LandingWindow = 'cmsLanding',
  Product = 'product',
  Trade = 'trade',
  PersonalDataPolicy = 'pdp',
  TermsOfUse = 'terms',
  Cookies = 'cookies',
  PersonalDataAgreement = 'pda',
  WebAnalytics = 'webAnalytics',
  Booking = 'booking',
  Asp = 'asp',
}

export enum ELocaleShort {
  Ru = 'ru',
  En = 'en',
}

export type Alphabet = {
  readonly locale: ELocaleShort;
  readonly value: string[];
};

/** Перечень названий существующих категорий */
export enum EAppCategories {
  TradeOffers = 'Скидки и акции',
  ProductOffers = 'Товары',
  CorpOffers = 'Бонусный пакет',
  BookingOffers = 'Досуг и отдых',
}

export enum ESearchUrlParam {
  Query = 'text',
}
