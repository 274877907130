import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { EOfferType } from 'domain/model/enums';
import { OfferSearchSuggestion } from '../../../domain/model/offer';
import { ApiCancellable, ApiRequestPageable } from '../types';
import { createCancelToken } from '../utils';

type OffersProps = ApiRequestPageable &
  ApiCancellable & {
    readonly query?: Nullable<string>;
    readonly offerTypes?: Nullable<EOfferType[]>;
  };

export type UserSearchApi = {
  readonly offers: (props: OffersProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, OfferSearchSuggestion[]>>;
};

const search: UserSearchApi = {
  offers: props => {
    const { query, offerTypes, page, pageSize, sort, signal } = props;

    const params = new URLSearchParams({
      page: (page - 1).toString(10),
      size: pageSize.toString(10),
    });

    if (query) {
      params.append('q', query);
    }

    if (sort) {
      sort.forEach(item => params.append('sort', item));
    }

    if (offerTypes) {
      offerTypes.forEach(item => params.append('offerType', item));
    }

    return {
      url: `/customers/current/offers`,
      method: 'GET',
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    };
  },
};

export default search;
