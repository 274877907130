import { forwardRef, ReactNode } from 'react';
import { Wrapper } from './controls';
import { Breakpoint } from '@mui/system';

type DefaultContentWrapperProps = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly style?: any;
  readonly fixed?: boolean;
  readonly maxWidth?: Breakpoint | false;
};

const DefaultContentWrapper = forwardRef<any, DefaultContentWrapperProps>(
  ({ children, className, style, fixed, maxWidth }, ref) => {
    return (
      <Wrapper
        ref={ref}
        className={className}
        fixed={fixed}
        maxWidth={maxWidth}
        style={style}
      >
        {children}
      </Wrapper>
    );
  }
);

export default DefaultContentWrapper;
