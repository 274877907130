import useCorpOfferDetailsData from 'presentation/features/offer/corp/details/hooks/useCorpOfferDetailsData';
import useCorpOfferPartnerDeskData from 'presentation/features/offer/corp/details/hooks/useCorpOfferPartnerDeskData';
import CorpOfferTabs from 'presentation/features/offer/corp/details/tabs';
import { ECorpOfferDetailsTab } from 'presentation/features/offer/corp/details/utils';
import { getCorpOfferDetailsRoute } from 'presentation/features/offer/corp/entry';
import { useHistory } from 'react-router';

type CorpOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ECorpOfferDetailsTab;
};

const CorpOfferDetailsTabsAdapter = ({ guid, id, tab }: CorpOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const onChangeTab = (event: React.SyntheticEvent, newTab: ECorpOfferDetailsTab) => {
    history.replace(getCorpOfferDetailsRoute({ id, tab: newTab, guid }));
  };

  const { corpOffer } = useCorpOfferDetailsData(id);
  const { partnerDesk } = useCorpOfferPartnerDeskData(id);

  if (!corpOffer || !partnerDesk) {
    return null;
  }

  return (
    <CorpOfferTabs
      tab={tab}
      corpOffer={corpOffer}
      partnerDesk={partnerDesk}
      onChange={onChangeTab}
    />
  );
};

export default CorpOfferDetailsTabsAdapter;
