import { toCurrency } from 'presentation/utils/currency';
import { OriginalPriceText, PriceText, SidebarPriceWrapper, StyledUnitsIcon } from './controls';

type OfferSidebarPriceInfo = {
  readonly price: number;
  readonly originalPrice: Nullable<number>;
};

const OfferSidebarPriceInfo = ({ price, originalPrice }: OfferSidebarPriceInfo) => {
  return (
    <SidebarPriceWrapper>
      <StyledUnitsIcon />
      <PriceText>{toCurrency(price)}</PriceText>
      {originalPrice !== null && originalPrice > price && (
        <OriginalPriceText
          variant='body1'
          color='textSecondary'
        >
          {toCurrency(originalPrice)}
        </OriginalPriceText>
      )}
    </SidebarPriceWrapper>
  );
};

export default OfferSidebarPriceInfo;
