import SidebarTitle from '../../../../../components/sidebar/title';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';

const TradeOfferTitleAdapter = () => {
  const { tradeOffer } = useTradeOfferSidebar();

  if (!tradeOffer) {
    return null;
  }

  return <SidebarTitle text={tradeOffer.title} />;
};

export default TradeOfferTitleAdapter;
