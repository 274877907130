import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from 'data/network/errorHandler';
import apiDefinition from 'data/openApi';
import { AppThunkAPIConfig } from 'data/store/store';
import { Fetchable, fetchableDefault } from 'data/store/types';
import { AppOptionTyped } from 'domain/model';
import {
  ECorpOfferPromotionType,
  EDeliveryType,
  EOrderItemStatus,
  EOrderStatus,
  EPaymentType,
  ETradeOfferPromotionType,
  PersonalPromotionType,
  TradePublicPromotionType,
} from 'domain/model/enums';

export const nsiFetch = createAsyncThunk<NsiData, undefined, AppThunkAPIConfig>(
  'nsi/fetch',
  async (props, { rejectWithValue }) => {
    try {
      const generalOrderStatuses: AppOptionTyped<EOrderStatus>[] = Object.values(GENERAL_ORDER_STATUS);
      const bookingOrderStatuses: AppOptionTyped<EOrderStatus>[] = Object.values(BOOKING_ORDER_STATUS);
      const orderItemStatuses: AppOptionTyped<EOrderItemStatus>[] = Object.values(ORDER_ITEM_STATUS);
      const personalPromotionTypes: AppOptionTyped<PersonalPromotionType>[] = Object.values(PERSONAL_PROMOTION_TYPE);
      const publicPromotionTypes: AppOptionTyped<TradePublicPromotionType>[] = Object.values(PUBLIC_PROMOTION_TYPE);
      const paymentTypes: AppOptionTyped<EPaymentType>[] = Object.values(PAYMENT_TYPE);
      const deliveryTypes: AppOptionTyped<EDeliveryType>[] = Object.values(DELIVERY_TYPE);

      let cancelOrderTypes: AppOptionTyped<UUID>[] = [];
      try {
        cancelOrderTypes = (await apiDefinition.order.cancelReasonTypes({})).data;
      } catch (e: any) {
        console.log(e);
      }

      let cancelCustomerTypes: AppOptionTyped<UUID>[] = [];
      try {
        cancelCustomerTypes = (await apiDefinition.order.cancelCustomerReasonTypes({})).data;
      } catch (e: any) {
        console.log(e);
      }

      return {
        generalOrderStatuses,
        bookingOrderStatuses,
        orderItemStatuses,
        personalPromotionTypes,
        paymentTypes,
        deliveryTypes,
        cancelOrderTypes,
        cancelCustomerTypes,
        publicPromotionTypes,
      };
    } catch (e: any) {
      ErrorHandler.handleHttpErrorLegacy(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

type NsiData = {
  // тп и кп
  readonly generalOrderStatuses: AppOptionTyped<EOrderStatus>[];
  // букинг и товары
  readonly bookingOrderStatuses: AppOptionTyped<EOrderStatus>[];
  readonly orderItemStatuses: AppOptionTyped<EOrderItemStatus>[];
  readonly personalPromotionTypes: AppOptionTyped<PersonalPromotionType>[];
  readonly publicPromotionTypes: AppOptionTyped<TradePublicPromotionType>[];
  readonly paymentTypes: AppOptionTyped<EPaymentType>[];
  readonly deliveryTypes: AppOptionTyped<EDeliveryType>[];
  readonly cancelOrderTypes: AppOptionTyped<UUID>[];
  readonly cancelCustomerTypes: AppOptionTyped<UUID>[];
};

export type NsiState = Fetchable & {
  readonly data: NsiData;
};

const slice = createSlice<NsiState, SliceCaseReducers<NsiState>, 'nsi'>({
  name: 'nsi',
  initialState: {
    ...fetchableDefault,
    data: {
      generalOrderStatuses: [],
      bookingOrderStatuses: [],
      orderItemStatuses: [],
      personalPromotionTypes: [],
      publicPromotionTypes: [],
      paymentTypes: [],
      deliveryTypes: [],
      cancelOrderTypes: [],
      cancelCustomerTypes: [],
    },
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(nsiFetch.pending, state => {
        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;
        state.data = {
          generalOrderStatuses: [],
          bookingOrderStatuses: [],
          orderItemStatuses: [],
          personalPromotionTypes: [],
          publicPromotionTypes: [],
          paymentTypes: [],
          deliveryTypes: [],
          cancelOrderTypes: [],
          cancelCustomerTypes: [],
        };
      })
      .addCase(nsiFetch.fulfilled, (state, { payload }) => {
        const {
          generalOrderStatuses,
          orderItemStatuses,
          bookingOrderStatuses,
          personalPromotionTypes,
          paymentTypes,
          deliveryTypes,
          cancelOrderTypes,
          cancelCustomerTypes,
          publicPromotionTypes,
        } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;
        state.data = {
          generalOrderStatuses,
          bookingOrderStatuses,
          orderItemStatuses,
          personalPromotionTypes,
          publicPromotionTypes,
          paymentTypes,
          deliveryTypes,
          cancelOrderTypes,
          cancelCustomerTypes,
        };
      })
      .addCase(nsiFetch.rejected, state => {
        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = true;
        state.data = {
          generalOrderStatuses: [],
          bookingOrderStatuses: [],
          orderItemStatuses: [],
          personalPromotionTypes: [],
          publicPromotionTypes: [],
          paymentTypes: [],
          deliveryTypes: [],
          cancelOrderTypes: [],
          cancelCustomerTypes: [],
        };
      });
  },
});

export default slice.reducer;

export const GENERAL_ORDER_STATUS: { [name in EOrderStatus]: AppOptionTyped<EOrderStatus> } = {
  [EOrderStatus.New]: {
    id: EOrderStatus.New,
    name: 'Принят',
  },
  [EOrderStatus.Confirmed]: {
    id: EOrderStatus.Confirmed,
    name: 'Подтверждён',
  },
  [EOrderStatus.Returned]: {
    id: EOrderStatus.Returned,
    name: 'Возвращён',
  },
  [EOrderStatus.Cancelled]: {
    id: EOrderStatus.Cancelled,
    name: 'Отменен',
  },
  [EOrderStatus.Given]: {
    id: EOrderStatus.Given,
    name: 'Выполнен',
  },
  [EOrderStatus.Active]: {
    id: EOrderStatus.Active,
    name: 'Выполняется',
  },
  [EOrderStatus.Sent]: {
    id: EOrderStatus.Sent,
    name: 'Отправлен',
  },
  [EOrderStatus.Paid]: {
    id: EOrderStatus.Paid,
    name: 'Оплачен',
  },
  [EOrderStatus.PartiallyReturned]: {
    id: EOrderStatus.PartiallyReturned,
    name: 'Частично возвращён',
  },
} as const;

export const BOOKING_ORDER_STATUS: { [name in EOrderStatus]: AppOptionTyped<EOrderStatus> } = {
  ...GENERAL_ORDER_STATUS,
  [EOrderStatus.New]: {
    id: EOrderStatus.New,
    name: 'Создан',
  },
  [EOrderStatus.Confirmed]: {
    id: EOrderStatus.Confirmed,
    name: 'Принят',
  },
} as const;

const ORDER_ITEM_STATUS: { [name in EOrderItemStatus]: AppOptionTyped<EOrderItemStatus> } = {
  [EOrderItemStatus.New]: {
    id: EOrderItemStatus.New,
    name: 'Принят',
  },
  [EOrderItemStatus.Confirmed]: {
    id: EOrderItemStatus.Confirmed,
    name: 'Подтверждён',
  },
  [EOrderItemStatus.Returned]: {
    id: EOrderItemStatus.Returned,
    name: 'Возвращён',
  },
  [EOrderItemStatus.Cancelled]: {
    id: EOrderItemStatus.Cancelled,
    name: 'Отменен',
  },
  [EOrderItemStatus.Given]: {
    id: EOrderItemStatus.Given,
    name: 'Выполнен',
  },
  [EOrderItemStatus.Changed]: {
    id: EOrderItemStatus.Changed,
    name: 'Состав изменён',
  },
  [EOrderItemStatus.PartiallyReceived]: {
    id: EOrderItemStatus.PartiallyReceived,
    name: 'Частично получен',
  },
} as const;

const PERSONAL_PROMOTION_TYPE: { [name in PersonalPromotionType]: AppOptionTyped<PersonalPromotionType> } = {
  [ETradeOfferPromotionType.Promocode]: {
    id: ETradeOfferPromotionType.Promocode,
    name: 'Промокод',
  },
  [ETradeOfferPromotionType.Voucher]: {
    id: ETradeOfferPromotionType.Voucher,
    name: 'Ваучер',
  },
  [ETradeOfferPromotionType.AccessCode]: {
    id: ETradeOfferPromotionType.AccessCode,
    name: 'Код доступа',
  },
  [ETradeOfferPromotionType.Asp]: {
    id: ETradeOfferPromotionType.Asp,
    name: 'АСП',
  },
  [ECorpOfferPromotionType.Certificate]: {
    id: ECorpOfferPromotionType.Certificate,
    name: 'Сертификат',
  },
  [ECorpOfferPromotionType.ExternalCertificate]: {
    id: ECorpOfferPromotionType.ExternalCertificate,
    name: 'Документ',
  },
};

const PUBLIC_PROMOTION_TYPE: { [name in TradePublicPromotionType]: AppOptionTyped<TradePublicPromotionType> } = {
  [ETradeOfferPromotionType.PublicPromocode]: {
    id: ETradeOfferPromotionType.PublicPromocode,
    name: 'Публичный промокод',
  },
  [ETradeOfferPromotionType.ReferralLink]: {
    id: ETradeOfferPromotionType.ReferralLink,
    name: 'Реферальная ссылка',
  },
  [ETradeOfferPromotionType.Widget]: {
    id: ETradeOfferPromotionType.Widget,
    name: 'Виджет',
  },
};

const PAYMENT_TYPE: { [name in EPaymentType]: AppOptionTyped<EPaymentType> } = {
  [EPaymentType.Cash]: {
    id: EPaymentType.Cash,
    name: 'Наличными',
  },
  [EPaymentType.Card]: {
    id: EPaymentType.Card,
    name: 'Банковской картой',
  },
  [EPaymentType.CashOrCard]: {
    id: EPaymentType.CashOrCard,
    name: 'Наличными и банковской картой',
  },
  [EPaymentType.Invoice]: {
    id: EPaymentType.Invoice,
    name: 'Онлайн (выставление счета)',
  },
  [EPaymentType.Other]: {
    id: EPaymentType.Other,
    name: 'Другое',
  },
};

const DELIVERY_TYPE: { [name in EDeliveryType]: AppOptionTyped<EDeliveryType> } = {
  [EDeliveryType.Delivery]: {
    id: EDeliveryType.Delivery,
    name: 'Доставка',
  },
  [EDeliveryType.Self]: {
    id: EDeliveryType.Self,
    name: 'Только самовывоз',
  },
  [EDeliveryType.SelfOrDelivery]: {
    id: EDeliveryType.SelfOrDelivery,
    name: 'Доставка и самовывоз',
  },
  [EDeliveryType.Other]: {
    id: EDeliveryType.Other,
    name: 'Другое',
  },
};
