import styled from '@emotion/styled';
import { Badge, badgeClasses } from '@mui/material';

import { CarouselCommonWrapper } from '../../../../../../components/common/carousels/gallery/controls';

export const StyledBadge = styled(Badge)`
  display: block;
  top: 0;
  right: 0;

  .${badgeClasses.badge} {
    z-index: 1;
    transform: translate(0px, 0px);
    padding: 0;
  }
`;

export const BadgeContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > * {
    position: absolute;
    top: 0;
    right: 0;
  }

  > *:first-child {
    width: 50px;
    font-size: 51px;
    color: ${p => p.theme.palette.white.main};
    path {
      fill: ${p => p.theme.palette.white.main};
    }
  }

  > *:last-child {
    top: 7px;
    right: 7px;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${CarouselCommonWrapper} {
    //max-height: 80vh;
  }
`;
