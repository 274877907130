import { Typography } from '@mui/material';
import SidebarMessage from '../../../../../components/sidebar/message';

export const CorpOfferSidebarPausedMessage = () => {
  return (
    <SidebarMessage type='info'>
      <Typography
        variant='body2'
        color='black'
      >
        Предложение временно недоступно
      </Typography>
    </SidebarMessage>
  );
};
