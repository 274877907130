import { ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { ArrowLeftIcon } from '../../../../media/icons';
import { BackIconWrapper, StyledBackButton, Text } from './controls';

export type BackButtonProps = Pick<ButtonProps, 'onClick'> & {
  readonly label?: ReactNode;
};

const BackButton = ({ label, onClick }: BackButtonProps) => {
  return (
    <StyledBackButton
      size='small'
      color='secondary'
      startIcon={
        <BackIconWrapper
          variant='squared'
          size='small'
          disableRipple
        >
          <ArrowLeftIcon color='inherit' />
        </BackIconWrapper>
      }
      onClick={onClick}
    >
      <Text>{label}</Text>
    </StyledBackButton>
  );
};

export default BackButton;
