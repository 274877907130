import styled from '@emotion/styled/macro';
import { buttonBaseClasses } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PromotionButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  .${buttonBaseClasses.root} {
    padding: 0.75rem 1.25rem;

    ${p => p.theme.breakpoints.down('sm')} {
      padding: 0.75rem 1.25rem;
    }
  }
`;

export const BottomBar = styled.div`
  width: 100%;
  display: flex;
`;
