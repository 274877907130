import { Typography } from '@mui/material';
import TillDateCountdown from 'presentation/components/common/countdown/tillDate';
import SidebarMessage from '../../../../../components/sidebar/message';

type TradeOfferSidebarReactivationMessageProps = {
  readonly date: string;
  readonly stop: () => void;
}

export const TradeOfferSidebarReactivationMessage = (props: TradeOfferSidebarReactivationMessageProps) => {
  const { date, stop } = props;

  return (
    <SidebarMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        Получить новый можно будет через{' '}
        <TillDateCountdown
          date={date}
          active={true}
          stop={stop}
        />
      </Typography>
    </SidebarMessage>
  );
};
