import { Typography } from '@mui/material';
import PromotionTypeText from 'presentation/features/offer/components/sidebar/obtainingMethod';
import { EDateTimeFormat } from '../../../../../../../../domain/model/formats';
import { utcToLocalTimeZone } from '../../../../../../../utils/date';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';
import { Wrapper } from './controls';

const SidebarPromotionDateActivateNow = () => {
  return (
    <Typography
      variant='body2'
      color='textSuccess'
    >
      получен только что
    </Typography>
  );
};

type TradeOfferSidebarPromotionDateProps = {
  readonly appointmentDate: string;
};

const SidebarPromotionDate = (props: TradeOfferSidebarPromotionDateProps) => {
  const { appointmentDate } = props;
  return (
    <Typography variant='body2'>
      от {utcToLocalTimeZone(appointmentDate).format(EDateTimeFormat.DisplayDefault)}
    </Typography>
  );
};

const TradeOfferPromotionTypeAdapter = () => {
  const { tradeOffer, isActivationReceivedNow, activation, isReceiptDateSupported } = useTradeOfferSidebar();

  if (!tradeOffer) {
    return null;
  }

  return (
    <Wrapper>
      <PromotionTypeText promotionType={tradeOffer.promotionType} />
      {isReceiptDateSupported && isActivationReceivedNow && <SidebarPromotionDateActivateNow />}
      {isReceiptDateSupported && !isActivationReceivedNow && activation.lastActivation?.appointmentDate && (
        <SidebarPromotionDate appointmentDate={activation.lastActivation.appointmentDate} />
      )}
    </Wrapper>
  );
};

export default TradeOfferPromotionTypeAdapter;
