import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { useState } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';

type OfferSidebarSubscriptionEnabledProps = {
  readonly isFetching?: boolean;
  readonly onUnSubscribe?: () => void;
};

const OfferSidebarSubscriptionEnabled = ({ isFetching, onUnSubscribe }: OfferSidebarSubscriptionEnabledProps) => {
  const [hover, setHover] = useState(false);

  const setDefaultState = () => setHover(false);

  const setHoverState = () => setHover(true);

  const text = hover || isFetching ? 'Отписаться от уведомления' : 'Уведомление придёт на эл. почту';

  return (
    <MPButton
      disabled={isFetching}
      fullWidth
      variant='contained'
      color='success'
      size='large'
      onClick={onUnSubscribe}
      onFocus={setHoverState}
      onMouseEnter={setHoverState}
      onTouchStart={setHoverState}
      onTouchEnd={setDefaultState}
      onMouseLeave={setDefaultState}
      onBlur={setDefaultState}
    >
      <Typography variant='body1'>{text}</Typography>
      {isFetching && <ContentLoader size={15} />}
    </MPButton>
  );
};

export default OfferSidebarSubscriptionEnabled;
