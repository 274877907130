import {
  SkirtWrapper,
  SlotsGrid,
  Wrapper,
} from 'presentation/features/offer/components/offerSlotSidebar/layout/controls';
import React from 'react';

type OfferSidebarLayoutProps = {
  readonly isFirst?: boolean;
  readonly slots: Nullable<React.JSX.Element>[];
  readonly skirtSlots?: Nullable<React.JSX.Element>[];
};

const OfferSidebarLayout = ({ isFirst = true, slots, skirtSlots }: OfferSidebarLayoutProps) => {
  return (
    <Wrapper>
      {slots?.length > 0 && (
        <SlotsGrid>
          {slots.map((s, i) => {
            return s ? <React.Fragment key={i}>{s}</React.Fragment> : null;
          })}
        </SlotsGrid>
      )}
      {skirtSlots && (
        <SkirtWrapper isFirst={isFirst}>
          {skirtSlots.map((s, i) => {
            return s ? <React.Fragment key={i}>{s}</React.Fragment> : null;
          })}
        </SkirtWrapper>
      )}
    </Wrapper>
  );
};

export default OfferSidebarLayout;
