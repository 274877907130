import { Typography } from '@mui/material';
import ContentLoader from 'presentation/components/common/loader';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPConfirmDialog } from 'presentation/theme/ui-kit/dialog';

type UserSuccessEmailUpdateDialogProps = {
  readonly open: boolean;
  readonly email: string;
  readonly isFetching: boolean;
  readonly onClose: () => void;
  readonly onRepeatUpdate: () => void;
};

const UserSuccessEmailUpdateDialog = (props: UserSuccessEmailUpdateDialogProps) => {
  const { email, open, isFetching, onRepeatUpdate, onClose } = props;

  return (
    <MPConfirmDialog
      title='Подтвердите электронную почту'
      open={open}
      maxWidth='xs'
      onClose={onClose}
      buttons={
        <MPButton
          fullWidth
          color='brand'
          size='large'
          disabled={isFetching}
          onClick={onRepeatUpdate}
        >
          Отправить повторно
          {isFetching && <ContentLoader />}
        </MPButton>
      }
    >
      На почту{' '}
      <Typography
        variant='subtitle1'
        component='span'
      >
        {email}
      </Typography>{' '}
      отправлено письмо. Нажмите на кнопку подтверждения в письме
    </MPConfirmDialog>
  );
};

export default UserSuccessEmailUpdateDialog;
