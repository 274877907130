import PartnerLink from '../../../../../../../components/common/partnerLink';

type TradeOfferSidebarReferralLinkPromotionProps = {
  readonly link: string;
  readonly onClick: () => void;
};

export const TradeOfferSidebarReferralLinkPromotion = (props: TradeOfferSidebarReferralLinkPromotionProps) => {
  const { link, onClick } = props;

  return (
    <PartnerLink
      link={link}
      onClick={onClick}
    />
  );
};
