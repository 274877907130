import { Typography } from '@mui/material';
import { EOfferStatus } from '../../../../../../../../domain/model/enums';
import { InfoEmptyIcon } from '../../../../../../../media/icons';
import { dialogContent } from '../../../../components/dialogs/help/constants';
import useTradeOfferSidebarDialogs from '../../dialogProvider/useDialogs';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';
import { StyledFab, Wrapper } from './controls';

const HelpAdapter = () => {
  const { tradeOffer } = useTradeOfferSidebar();
  const { openHelpDialog } = useTradeOfferSidebarDialogs();

  if (!tradeOffer) {
    return null;
  }

  const title = dialogContent[tradeOffer.promotionType].title;

  const isVisible = tradeOffer.status === EOfferStatus.Active;

  return isVisible ? (
    <Wrapper>
      <Typography variant='body2'>{title}</Typography>
      <StyledFab
        variant='squared'
        color='secondaryLight'
        size='medium'
        onClick={openHelpDialog}
      >
        <InfoEmptyIcon />
      </StyledFab>
    </Wrapper>
  ) : null;
};

export default HelpAdapter;
