import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';
import { UnitsIcon } from '../../../../../media/icons';

export const StyledUnitsIcon = styled(UnitsIcon)`
  margin-right: 4px;
`;

export const SidebarPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceText = styled(Typography)`
  margin-right: 6px;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
`;

export const OriginalPriceText = styled(Typography)`
  text-decoration: line-through;
`;
