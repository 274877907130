import { Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPTab, MPTabs } from 'presentation/theme/ui-kit/tab';
import { EUserFavoritesTab, UserFavoritesTabInfo } from '../utils';

type UserFavoritesTabsProps = {
  readonly tab: Nullable<UserFavoritesTabInfo>;
  readonly tabs: UserFavoritesTabInfo[];
  readonly onChangeTab: (newTab: EUserFavoritesTab) => void;
};

export const UserFavoritesTabs = ({ tab, tabs, onChangeTab }: UserFavoritesTabsProps) => {
  return (
    <MPGrid
      container
      spacing={2}
      alignItems='center'
      justifyContent='space-between'
    >
      <MPGrid
        item
        zero={12}
        md
      >
        <MPTabs
          value={tab?.value}
          textColor='primary'
          variant='scrollable'
          scrollButtons={false}
          onChange={(event, tab) => onChangeTab(tab)}
        >
          {tabs.map(item => (
            <MPTab
              label={
                <Typography component='div'>
                  {item.label}
                  {isNumber(item.count) && item.count > 0 && (
                    <Typography
                      color='secondary'
                      component='span'
                    >
                      {'\u00A0\u00A0'}
                      {item.count}
                    </Typography>
                  )}
                </Typography>
              }
              value={item.value}
              key={item.value}
            />
          ))}
        </MPTabs>
      </MPGrid>
    </MPGrid>
  );
};

export * from './corpOffers';
export * from './productOffers';
export * from './tradeOffers';
export * from './bookingsOffers';
