import { Fade, useMediaQuery, useTheme } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { FC, ReactNode } from 'react';
import BackgroundContainer from '../backgroundContainer';
import { FitContainer, InnerLayoutGrid, InnerLayoutGridArea, ParentLayoutGrid, ParentLayoutGridArea } from './controls';
import { EInnerGridAreas, EParentGridAreas } from './types';
import useVisibleSectionLinks from '../../features/sectionLinks/hooks/useVisibleSectionLinks';
import useHideHeader from '../../features/header/hooks/useHideHeader';

type OfferDetailsLayoutProps = {
  readonly id: UUID;
  readonly tabs: ReactNode;
  readonly breadcrumb: ReactNode;
  readonly smartphoneFixedBar?: ReactNode;
  readonly description: ReactNode;
  readonly toolbar?: ReactNode;
  readonly close?: ReactNode;
  readonly title: ReactNode;
  readonly favorite: ReactNode;
  readonly image: ReactNode;
  readonly sidebar: ReactNode;
  readonly partnerInfo: ReactNode;
  readonly useLoading: ({ id }: { id: UUID }) => boolean;
};

const OfferDetailsLayout: FC<OfferDetailsLayoutProps> = props => {
  const {
    id,
    image,
    toolbar,
    breadcrumb,
    sidebar,
    tabs,
    partnerInfo,
    title,
    description,
    close,
    smartphoneFixedBar,
    favorite,
    useLoading,
  } = props;

  const isLoading = useLoading({ id });
  const theme = useTheme();

  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  useVisibleSectionLinks(isDownMd);
  useHideHeader(isDownMd);
  useVisibleSectionLinks(isDownMd);

  return (
    <>
      <ScreenNavWrapper>{toolbar}</ScreenNavWrapper>
      <Fade in>
        <DefaultContentWrapper>
          <FitContainer>
            {isLoading ? null : (
              <ParentLayoutGrid>
                <ParentLayoutGridArea area={EParentGridAreas.Inner}>
                  <BackgroundContainer close={close}>
                    <InnerLayoutGrid>
                      <InnerLayoutGridArea area={EInnerGridAreas.SmartphoneFixedBar}>
                        {smartphoneFixedBar}
                      </InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Title}>{title}</InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Favorite}>{favorite}</InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Breadcrumbs}>{breadcrumb}</InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Image}>{image}</InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Description}>{description}</InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Sidebar}>
                        {sidebar}
                        {partnerInfo}
                      </InnerLayoutGridArea>
                      <InnerLayoutGridArea area={EInnerGridAreas.Tabs}>{tabs}</InnerLayoutGridArea>
                    </InnerLayoutGrid>
                  </BackgroundContainer>
                </ParentLayoutGridArea>
              </ParentLayoutGrid>
            )}
          </FitContainer>
        </DefaultContentWrapper>
      </Fade>
    </>
  );
};

export default OfferDetailsLayout;
