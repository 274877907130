import styled from '@emotion/styled/macro';
import { AppBar as MuiAppBar, AppBarProps, badgeClasses, Container, css, Grid } from '@mui/material';

export enum EHeaderGridAreas {
  Logo = 'logo',
  Links = 'links',
  Location = 'location',
  User = 'user',
  Catalog = 'catalog',
  Search = 'search',
  Cancel = 'cancel',
  Options = 'options',
}

export const Wrapper = styled(({ ...others }: AppBarProps) => <MuiAppBar {...others} />)(() => css``);

export const bottomMenuHeight = '8rem';

export const BottomMenuWrapper = styled.footer(
  ({ theme }) => css`
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 64px;

    padding: ${theme.spacing()} ${theme.spacing(1.5)};

    background: ${theme.palette.background.default};
  `
);

type HeaderContentAreaProps = {
  readonly area: EHeaderGridAreas;
};

export const ContentWrapperArea = styled(Grid)<HeaderContentAreaProps>(
  ({ area }) => css`
    grid-area: ${area};
  `
);

export const ContentWrapper = styled.div(
  () => css`
    width: 100%;

    display: flex;
  `
);

export const TopBar = styled.div(
  ({ theme }) => css`
    display: flex;
    background-color: ${theme.palette.white.light};
    padding: ${theme.spacing(0.5, 0)};
  `
);

export const TopBarContainer = styled(Container)(
  () => css`
    display: flex;
    justify-content: space-between;
  `
);

export const BottomBar = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(1, 0)};

    ${theme.breakpoints.up('lg')} {
      padding: ${theme.spacing(2, 0)};
    }

    > [area=${EHeaderGridAreas.Logo}] {
      display: flex;
      align-items: center;
      margin-right: ${theme.spacing(3)};

      ${theme.breakpoints.up('xl')} {
        margin-right: ${theme.spacing(4)};
      }
    }

    > [area=${EHeaderGridAreas.Search}] {
      flex: 1;
    }

    > [area=${EHeaderGridAreas.Options}] {
      display: flex;
      justify-content: space-between;

      .MuiBox-root {
        margin: 0 -${theme.spacing(0.5)};
      }

      .${badgeClasses.root} {
        margin: 0 ${theme.spacing(0.5)};

        &:last-child {
          margin-right: 0;
        }
      }

      > :first-of-type {
        ${theme.breakpoints.up('lg')} {
          margin-left: ${theme.spacing(3)};
        }
      }
    }

    > [area=${EHeaderGridAreas.Cancel}] {
      margin-left: ${theme.spacing()};
    }

    > [area=${EHeaderGridAreas.Catalog}] {
      margin-right: ${theme.spacing()};

      ${theme.breakpoints.up('lg')} {
        margin-right: ${theme.spacing(1.5)};
      }
    }
  `
);
