import { useCheckUserCurrentFetchQuery } from 'data/api/agreements';
import { ServerErrorResponse } from 'data/network/types';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { isMissingUserAgreementError, isSecurityBusinessError } from 'presentation/utils/auth';
import { FCC, useEffect } from 'react';
import Notifier from 'system/notifier';

const CheckSignsContainer: FCC = ({ children }) => {
  const { logOut } = useAuth();
  const { tokenParsed } = useAuthToken();
  const { data: user, error: userFetchError, isFetching } = useCheckUserCurrentFetchQuery({}, { skip: !tokenParsed });

  const isMissingUserAgreementsError = isMissingUserAgreementError(
    (userFetchError as any)?.data as ServerErrorResponse
  );

  // ошибка загрузки текущего специфического юзера
  useEffect(() => {
    if (userFetchError && !isSecurityBusinessError((userFetchError as any)?.data as ServerErrorResponse)) {
      Notifier.getInstance().addError('При получении информации о текущем пользователе произошла ошибка');
      console.error('Error at request current user');
      logOut({ withTimeout: true });
    }
  }, [logOut, userFetchError]);

  if (isFetching) {
    return null;
  }

  if (!user && isMissingUserAgreementsError) {
    return null;
  }

  return <>{children}</>;
};

export default CheckSignsContainer;
