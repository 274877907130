import ContentLoader from 'presentation/components/common/loader';
import OfferSlotSidebar from 'presentation/features/offer/components/offerSlotSidebar/container';
import OfferSidebarLayout from 'presentation/features/offer/components/offerSlotSidebar/layout';
import { ESlotNameArea, SlotsGridArea } from '../../../components/offerSlotSidebar/layout/controls';
import { CorpOfferSidebarActivationAdapter } from './adapters/activation';
import ActivityEndDateAdapter from './adapters/activityEndDate';
import SidebarDividerAdapter from './adapters/divider';
import { CorpOfferSidebarLeftCodesAdapter } from './adapters/leftCodes';
import { CorpOfferSidebarInfoMessagesAdapter } from './adapters/messages/infoMessages';
import CorpOfferPromotionTypeAdapter from './adapters/messages/obtainingMethod';
import { CorpOfferSidebarWarnMessagesAdapter } from './adapters/messages/warnMessages';
import NdflAdapter from './adapters/ndfl';
import OfferCodeAdapter from './adapters/offerCode';
import { CorpOfferSidebarPriceAdapter } from './adapters/price';
import { CorpOfferSidebarPromotionAdapter } from './adapters/promotion';
import { CorpOfferSidebarPromotionNewAdapter } from './adapters/promotionNew';
// import { CorpOfferSidebarSubscriptionAdapter } from './adapters/subscription';
import { CorpOfferSidebarContextType } from './provider/context';
import useCorpOfferSidebar from './provider/useCorpOfferSidebar';
import CorpOfferSidebarWrapper from './wrapper';

const CorpOfferSlotSidebar = () => {
  return (
    <OfferSlotSidebar<CorpOfferSidebarContextType>
      useOfferSidebar={useCorpOfferSidebar}
      wrapper={CorpOfferSidebarWrapper}
    >
      {({ isFetching }) => {
        if (isFetching) {
          return <ContentLoader />;
        }

        return (
          <OfferSidebarLayout
            slots={[
              <SlotsGridArea
                area={ESlotNameArea.promotionType}
                key={ESlotNameArea.promotionType}
              >
                <CorpOfferPromotionTypeAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.price}
                key={ESlotNameArea.price}
              >
                <CorpOfferSidebarPriceAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.activation}
                key={ESlotNameArea.activation}
              >
                <CorpOfferSidebarActivationAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.promotion}
                key={ESlotNameArea.promotion}
              >
                <CorpOfferSidebarPromotionAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.newPromotion}
                key={ESlotNameArea.newPromotion}
              >
                <CorpOfferSidebarPromotionNewAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.warnMessages}
                key={ESlotNameArea.warnMessages}
              >
                <CorpOfferSidebarWarnMessagesAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.infoMessages}
                key={ESlotNameArea.infoMessages}
              >
                <CorpOfferSidebarInfoMessagesAdapter />
              </SlotsGridArea>,
              // <SlotsGridArea
              //   area={ESlotNameArea.subscriptions}
              //   key={ESlotNameArea.subscriptions}
              // >
              //   <CorpOfferSidebarSubscriptionAdapter />
              // </SlotsGridArea>,
            ]}
            skirtSlots={[
              <>
                <NdflAdapter key='ndfl' />
                <SidebarDividerAdapter key='divider' />
                <OfferCodeAdapter key='code' />
                <ActivityEndDateAdapter key='endDate' />
                <CorpOfferSidebarLeftCodesAdapter key='leftCodes' />
              </>,
            ]}
          />
        );
      }}
    </OfferSlotSidebar>
  );
};

export default CorpOfferSlotSidebar;
