import { useMediaQuery } from '@mui/material';
import { Category } from 'domain/model/nsi';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import OfferListAside from '../../../components/listAside';
import OfferSubCategories from '../../../components/subCategories';
import BookingOfferListFilter from '../../components/filter';
import { getBookingOffersSearchRoute } from '../../entry';
import useCategoriesData from '../hooks/useCategoriesData';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  bookingOfferAllServicesSelector,
  bookingOfferIsFilterFetchingSelector,
  bookingOfferListFilterDataSelector,
  bookingOfferListPriceFilterSelector,
  bookingOfferListServicesFilterSelector,
} from '../store/selectors';
import { BookingOfferListFilterCount } from '../types';

type BookingOfferListAsideAdapterProps = BookingOfferListFilterCount & {
  readonly categoryId?: Nullable<UUID>;
};

export const BookingOfferListAsideAdapter: FC<BookingOfferListAsideAdapterProps> = ({ filterCount, categoryId }) => {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  const { onChangePriceRange, onSelectService, onResetFilters } = useContextHandlers();

  const { subCategories, isCategoriesFetching } = useCategoriesData();

  const isFilterFetching = useSelector(bookingOfferIsFilterFetchingSelector);
  const allServices = useSelector(bookingOfferAllServicesSelector);
  const { priceRange: availableRange, services: availableServices } = useSelector(bookingOfferListFilterDataSelector);
  const services = useSelector(bookingOfferListServicesFilterSelector);
  const priceFilter = useSelector(bookingOfferListPriceFilterSelector);

  const linkFactory = (category: Category) => getBookingOffersSearchRoute({ categoryId: category.id });

  if (isMdUp && categoryId) {
    return (
      <BookingOfferListFilter
        isFilterFetching={isFilterFetching}
        allServices={allServices}
        services={services}
        availableServices={availableServices}
        availableRange={availableRange}
        priceFilter={priceFilter}
        filterCount={filterCount}
        onChangePriceRange={onChangePriceRange}
        onSelectService={onSelectService}
        onResetFilters={onResetFilters}
      />
    );
  }

  if (subCategories?.length && !isCategoriesFetching) {
    return (
      <OfferListAside>
        <OfferSubCategories
          categories={subCategories}
          linkFactory={linkFactory}
        />
      </OfferListAside>
    );
  }
};
