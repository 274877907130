import { useAuthUser } from 'presentation/features/auth/provider/useAuthUser';
import { ECatalogTab } from 'presentation/features/catalog/types';
import { getBookingOffersListRoute } from 'presentation/features/offer/booking/entry';
import { getCorpOffersListRoute } from 'presentation/features/offer/corp/entry';
import { getProductOfferListRoute } from 'presentation/features/offer/product/entry';
import { getTradeOffersListRoute } from 'presentation/features/offer/trade/entry';
import LandingLink from 'presentation/features/sectionLinks/components/link';
import useTechConfig from 'presentation/hooks/useTechConfig';
import { EAppCategories, EAppFeature } from 'presentation/types';
import { useMemo } from 'react';
import { PartitionIcon } from '../../../components/common/partitionIcon';

const useLinks = () => {
  const { hasFeature } = useTechConfig();
  const { isCorpUser } = useAuthUser();

  const hasTradeOffers = hasFeature(EAppFeature.Trade);
  const hasCorpOffers = isCorpUser;
  const hasProductOffers = hasFeature(EAppFeature.Product);
  const hasBookingOffers = hasFeature(EAppFeature.Booking);

  return useMemo(() => {
    const links = [];

    if (hasCorpOffers) {
      links.push(
        <LandingLink
          key='corp'
          to={getCorpOffersListRoute({})}
          icon={<PartitionIcon type={EAppCategories.CorpOffers} />}
          label={EAppCategories.CorpOffers}
          value={ECatalogTab.CorpOffers}
        />
      );
    }

    if (hasTradeOffers) {
      links.push(
        <LandingLink
          key='trade'
          to={getTradeOffersListRoute()}
          icon={<PartitionIcon type={EAppCategories.TradeOffers} />}
          label={EAppCategories.TradeOffers}
          value={ECatalogTab.TradeOffers}
        />
      );
    }

    if (hasProductOffers) {
      links.push(
        <LandingLink
          key='product'
          to={getProductOfferListRoute()}
          icon={<PartitionIcon type={EAppCategories.ProductOffers} />}
          label={EAppCategories.ProductOffers}
          value={ECatalogTab.ProductOffers}
        />
      );
    }

    if (hasBookingOffers) {
      links.push(
        <LandingLink
          key='service'
          to={getBookingOffersListRoute()}
          icon={<PartitionIcon type={EAppCategories.BookingOffers} />}
          label={EAppCategories.BookingOffers}
          value={ECatalogTab.BookingOffers}
        />
      );
    }

    return links;
  }, [hasCorpOffers, hasTradeOffers, hasProductOffers, hasBookingOffers]);
};

export default useLinks;
