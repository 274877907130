import { Typography } from '@mui/material';
import { ActionWrapper, ContentWrapper, IconWrapper, TextWrapper, Wrapper } from './controls';
import { UserAttributeLayoutType } from './types';

const UserAttributeLayout: UserAttributeLayoutType = props => {
  const { icon, label, action, children } = props;

  const labelNode = (
    <Typography
      variant='body2'
      color='text.secondary'
    >
      {label}
    </Typography>
  );

  const valueNode = <Typography color='text.primary'>{children}</Typography>;

  const actionNode = action && <ActionWrapper>{action}</ActionWrapper>;

  return (
    <Wrapper>
      <ContentWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper secondary={valueNode}>{labelNode}</TextWrapper>
        {actionNode}
      </ContentWrapper>
    </Wrapper>
  );
};

export default UserAttributeLayout;
