import { EOfferStatus } from '../../../../../../../../domain/model/enums';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { StyledDivider } from './controls';

const SidebarDividerAdapter = () => {
  const { corpOffer } = useCorpOfferSidebar();
  const isVisible = corpOffer?.status === EOfferStatus.Active;
  return isVisible ? <StyledDivider /> : null;
};

export default SidebarDividerAdapter;
