import { Typography } from '@mui/material';
import { UserFeedBackForm } from 'domain/model';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import React from 'react';
import useTechConfig from '../../../hooks/useTechConfig';
import { EAppFeature, OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import AboutPersonalDataAgreementPublicLink from '../components/personalDataAgreementLink/public';

type AboutFeedBackProps = {
  readonly data: UserFeedBackForm;
  readonly isSending: boolean;
  readonly validation: Nullable<ValidationResult<UserFeedBackForm>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<UserFeedBackForm>;
};

const AboutFeedBack = (props: AboutFeedBackProps) => {
  const { data, isSending, validation, onChangeAttribute } = props;

  const { hasFeature } = useTechConfig();

  return (
    <MPGrid
      container
      spacing={2}
      direction='column'
    >
      <MPGrid item>
        <Typography variant='body2'>Напишите нам, если у вас есть чем поделиться. Это сделает нас лучше</Typography>
      </MPGrid>
      <MPGrid item>
        <MPFormInput
          label='Фамилия и имя'
          value={data.name ?? ''}
          error={!!validation?.name?.hasError}
          helperText={validation?.name?.message}
          disabled={isSending}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAttribute('name', event.target.value)}
        />
      </MPGrid>
      <MPGrid item>
        <MPFormInput
          label='Электронная почта'
          value={data.email ?? ''}
          error={!!validation?.email?.hasError}
          helperText={validation?.email?.message}
          disabled={isSending}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAttribute('email', event.target.value)}
        />
      </MPGrid>
      <MPGrid item>
        <MPFormInput
          multiline
          rows={5}
          label='Сообщение'
          value={data.message ?? ''}
          error={!!validation?.message?.hasError}
          helperText={validation?.message?.message}
          disabled={isSending}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAttribute('message', event.target.value)}
        />
      </MPGrid>
      {hasFeature(EAppFeature.PersonalDataAgreement) && (
        <MPGrid item>
          <Typography variant='caption'>
            Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, в соответствии с
            Федеральным законом от 27.07.2006 г. №152-ФЗ «О персональных данных», на условиях и для целей, определенных
            в{' '}
            <AboutPersonalDataAgreementPublicLink
              color='primary'
              underline='hover'
            >
              Согласии на обработку персональных данных
            </AboutPersonalDataAgreementPublicLink>
          </Typography>
        </MPGrid>
      )}
    </MPGrid>
  );
};

export default AboutFeedBack;
