import { Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPTab, MPTabs } from 'presentation/theme/ui-kit/tab';
import { EPartnerDeskDetailsTab, PartnerDeskDetailsTabInfo } from '../utils';

type PartnerDeskDetailsTabsProps = {
  readonly tab: Nullable<PartnerDeskDetailsTabInfo>;
  readonly tabs: PartnerDeskDetailsTabInfo[];
  readonly onChangeTab: (newTab: EPartnerDeskDetailsTab) => void;
};

export const PartnerDeskDetailsTabs = ({ tab, tabs, onChangeTab }: PartnerDeskDetailsTabsProps) => {
  return (
    <MPGrid
      container
      spacing={2}
      alignItems='center'
      justifyContent='space-between'
    >
      <MPGrid
        item
        zero={12}
        md
      >
        <MPTabs
          value={tab?.value}
          textColor='primary'
          variant='scrollable'
          scrollButtons={false}
          onChange={(event, tab) => onChangeTab(tab)}
        >
          {tabs.map(item => (
            <MPTab
              label={
                <Typography component='div'>
                  {item.label}
                  {item.count && (
                    <Typography
                      color='secondary'
                      component='span'
                    >
                      {'\u00A0\u00A0'}
                      {item.count}
                    </Typography>
                  )}
                </Typography>
              }
              value={item.value}
              key={item.value}
            />
          ))}
        </MPTabs>
      </MPGrid>
    </MPGrid>
  );
};

export * from './contacts';
export * from './aboutCompany';
export * from './corpOffers';
export * from './productOffers';
export * from './tradeOffers';
export * from './window';
export * from './bookingOffers';
