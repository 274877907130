import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import CorpOfferDetailsContainer from 'presentation/features/offer/corp/details/container';
import { ECorpOfferDetailsTab } from 'presentation/features/offer/corp/details/utils';
import { ECorpOfferUrlParam } from 'presentation/features/offer/corp/utils';
import { useLocation, useParams } from 'react-router';
import ScreenLayout from '../../layouts/screen';

const CorpOfferScreen = () => {
  const location = useLocation();

  const { id } = useParams<{ id: UUID }>();
  const tab = (new URLSearchParams(location.search).get(ECorpOfferUrlParam.Tab) ??
    ECorpOfferDetailsTab.Description) as ECorpOfferDetailsTab;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <CorpOfferDetailsContainer
            id={id}
            guid={guid}
            tab={tab}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default CorpOfferScreen;
