import SidebarMessage from '../../../../../../components/sidebar/message';
import { useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { ContainerLimiter } from 'presentation/features/offer/components/conditions/controls';
import { Text } from './controls';
import DangerouslyHtmlWrapper from 'presentation/components/common/wrappers/dangerouslyHtml';
import { MPLink } from '../../../../../../../../theme/ui-kit/link';
import { DocIcon } from '../../../../../../../../media/icons';
import Splitter from 'presentation/components/common/splitter';
import { MPConfirmDialog } from '../../../../../../../../theme/ui-kit/dialog';
import { MPButton } from '../../../../../../../../theme/ui-kit/button';

type CorpOfferSidebarRejectedMessageProps = {
  readonly activationComment: Nullable<string>;
};

export const CorpOfferSidebarRejectedInfoMessage = (props: CorpOfferSidebarRejectedMessageProps) => {
  const { activationComment } = props;

  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const rowCount = 10;
  const contentElementRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);

  useEffect(() => {
    const el = contentElementRef.current;
    if (el) {
      const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = el;
      setIsOverflowed(scrollWidth > offsetWidth || scrollHeight > offsetHeight);
    }
  }, []);

  return (
    <SidebarMessage type='info'>
      <Stack>
        <ContainerLimiter rowCount={rowCount}>
          <DangerouslyHtmlWrapper ref={contentElementRef}>
            <Text
              variant='body2'
              color='black'
              rowCount={rowCount}
            >
              {activationComment}
            </Text>
          </DangerouslyHtmlWrapper>
        </ContainerLimiter>
        {isOverflowed && (
          <>
            <Splitter size={1} />
            <MPLink
              startIcon={DocIcon}
              onClick={onOpen}
            >
              <Typography variant='body2'>посмотреть полностью</Typography>
            </MPLink>
          </>
        )}
      </Stack>
      <MPConfirmDialog
        open={open}
        title='Отказ'
        maxWidth='xs'
        buttons={
          <MPButton
            size='large'
            fullWidth
            color='brand'
            variant='contained'
            onClick={onClose}
          >
            Понятно
          </MPButton>
        }
        onClose={onClose}
      >
        <Typography>{activationComment}</Typography>
      </MPConfirmDialog>
    </SidebarMessage>
  );
};
