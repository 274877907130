import { useState } from 'react';
import { MPButton } from '../../../../../../../theme/ui-kit/button';
import WidgetDialog from '../widgetModal';

type TradeOfferSidebarWidgetPromotionProps = {
  readonly wrapperRef: any;
  readonly title: string;
  readonly srcDoc: string;
};

const TradeOfferSidebarWidgetPromotion = ({ wrapperRef, title, srcDoc }: TradeOfferSidebarWidgetPromotionProps) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  return (
    <>
      <MPButton
        color='brand'
        fullWidth
        size='medium'
        onClick={onOpen}
      >
        Открыть
      </MPButton>
      <WidgetDialog
        title={title}
        srcDoc={srcDoc}
        wrapperRef={wrapperRef}
        onClose={onClose}
        open={open}
      />
    </>
  );
};

export default TradeOfferSidebarWidgetPromotion;
