import OfferSidebarSubscriptionDisabled from './disabled';
import OfferSidebarSubscriptionEnabled from './enabled';

type OfferSidebarSubscriptionProps = {
  readonly enabled?: boolean;
  readonly isFetching?: boolean;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe?: () => void;
};

const OfferSidebarSubscription = (props: OfferSidebarSubscriptionProps) => {
  const { enabled, isFetching, onSubscribe, onUnSubscribe } = props;

  return enabled ? (
    <OfferSidebarSubscriptionEnabled
      isFetching={isFetching}
      onUnSubscribe={onUnSubscribe}
    />
  ) : (
    <OfferSidebarSubscriptionDisabled
      isFetching={isFetching}
      onSubscribe={onSubscribe}
    />
  );
};

export default OfferSidebarSubscription;
