import { StompClientProvider } from '@mp-npm/mp-stomp-client';
import { useAuthToken } from 'presentation/features/auth/provider/useAuthToken';
import { FCC, useCallback } from 'react';
import { ENoticeStatus } from '../../../domain/model/enums';
import Notifier from '../../../system/notifier';
import useWsClient from './useWsClient';

export const WsClientProvider: FCC = ({ children }) => {
  const { token } = useAuthToken();

  const { apiWs, debug, isWsEnabled, wsEndpoint } = useWsClient();

  const onStompConnectionFailure = useCallback((message: Nullable<string>) => {
    Notifier.getInstance().addNotice(
      ENoticeStatus.Error,
      `Ошибка подключения websocket: ${message ?? 'неизвестная ошибка'}`
    );
  }, []);

  if (isWsEnabled && token) {
    return (
      <StompClientProvider
        endpoint={wsEndpoint}
        wsPath={apiWs?.path}
        sockjsPath={apiWs?.sockjsPath}
        debug={debug}
        getToken={() => token}
        onConnectionFailure={onStompConnectionFailure}
      >
        {children}
      </StompClientProvider>
    );
  }

  return <>{children}</>;
};
