import { useGetUserFavoritesTradeOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType } from 'domain/model/enums';
import CardList from 'presentation/components/common/cardList';
import { OfferFavoriteCommonContainer } from 'presentation/features/offer/favorite';
import TradeOfferListItem from 'presentation/features/offer/trade/components/listItem';
import { FC, useEffect } from 'react';
import { TabProps } from './types';

export const UserFavoritesTradeOffersContainer: FC<TabProps> = ({ guid, queryGuid }) => {
  const { data, error: tradeOffersError } = useGetUserFavoritesTradeOffersQuery({ guid: queryGuid });
  const tradeOffers = data?.data;

  useEffect(() => {
    if (tradeOffersError) {
      ErrorHandler.handleHttpError(tradeOffersError);
    }
  }, [tradeOffersError]);

  if (!tradeOffers) {
    return null;
  }

  return (
    <CardList>
      {tradeOffers.map(offer => (
        <TradeOfferListItem
          key={offer.id}
          offer={offer}
          favorite={
            <OfferFavoriteCommonContainer
              stored /*чтобы не скрывался таб после убирания всех сердечек*/
              guid={guid}
              offerId={offer.id}
              offerType={EOfferType.Trade}
            />
          }
        />
      ))}
    </CardList>
  );
};
