import { EBookingOfferSortType } from 'domain/model/enums';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import ScreenLayout from 'presentation/layouts/screen';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { ECatalogUrlParam } from '../../features/catalog/types';
import { EGlobalUrlParam } from '../../features/header/utils';
import BookingOfferListContainer from '../../features/offer/booking/list/container';
import { EBookingUrlParam } from '../../features/offer/booking/types';
import { TradeOffersListLocationState } from '../../features/offer/trade/entry';

const BookingOffersScreen = () => {
  const location = useLocation<Nullable<TradeOffersListLocationState>>();

  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get(EGlobalUrlParam.Search);
  const categoryId = searchParams.get(ECatalogUrlParam.Category) ?? null;
  const minPrice = searchParams.get(EBookingUrlParam.MinPrice) ?? null;
  const maxPrice = searchParams.get(EBookingUrlParam.MaxPrice) ?? null;

  const searchSort = searchParams.get(EBookingUrlParam.Sort);
  const searchServices = searchParams.get(EBookingUrlParam.Services);

  const [sort, services] = useMemo(() => {
    let foundServices = searchServices !== null ? searchServices.split(',') : undefined;

    if (searchServices === '') {
      foundServices = [];
    }

    return [searchSort ? (searchSort.split('+') as EBookingOfferSortType[]) : undefined, foundServices];
  }, [searchServices, searchSort]);

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <BookingOfferListContainer
            guid={guid}
            name={name}
            categoryId={categoryId}
            minPrice={minPrice ? Number(minPrice) : null}
            maxPrice={maxPrice ? Number(maxPrice) : null}
            sort={sort as unknown as EBookingOfferSortType[]}
            services={services}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default BookingOffersScreen;
