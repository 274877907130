import AuthEntry from 'presentation/features/auth/entry';
import { useAuth } from 'presentation/features/auth/provider/useAuth';
import { FCC, ReactNode } from 'react';

type AuthProtectProps = {
  readonly fallback?: ReactNode;
};

const AuthProtect: FCC<AuthProtectProps> = ({ fallback, children }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : fallback ?? <AuthEntry />;
};

export default AuthProtect;
