import { Typography } from '@mui/material';
import SidebarMessage from 'presentation/features/offer/components/sidebar/message';
import { OfferActivationError } from '../../../../../types';
import { getOfferActivationErrorText } from '../../../../../utils';

type CorpOfferSidebarBusinessErrorMessageProps = {
  readonly error: OfferActivationError;
};

export const CorpOfferSidebarBusinessErrorMessage = ({ error }: CorpOfferSidebarBusinessErrorMessageProps) => {
  return (
    <SidebarMessage type='warn'>
      <Typography
        variant='body2'
        color='black'
      >
        {getOfferActivationErrorText(error)}
      </Typography>
    </SidebarMessage>
  );
};
