import useTradeOfferDetailsData from 'presentation/features/offer/trade/details/hooks/useTradeOfferDetailsData';
import useTradeOfferPartnerDeskData from 'presentation/features/offer/trade/details/hooks/useTradeOfferPartnerDeskData';
import TradeOfferTabs from 'presentation/features/offer/trade/details/tabs';
import { ETradeOfferDetailsTab } from 'presentation/features/offer/trade/details/utils';
import { getTradeOfferDetailsRoute } from 'presentation/features/offer/trade/entry';
import { useHistory } from 'react-router';

type TradeOfferDetailsTabsAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ETradeOfferDetailsTab;
}

const TradeOfferDetailsTabsAdapter = ({ tab, id, guid }: TradeOfferDetailsTabsAdapterProps) => {
  const history = useHistory();

  const { tradeOffer } = useTradeOfferDetailsData(id);

  const { partnerDesk } = useTradeOfferPartnerDeskData(id);
  const onChangeTab = (event: React.SyntheticEvent, newTab: ETradeOfferDetailsTab) => {
    history.replace(getTradeOfferDetailsRoute({ id, tab: newTab, guid }));
  };

  if (!tradeOffer || !partnerDesk) {
    return null;
  }

  return (
    <TradeOfferTabs
      tab={tab}
      tradeOffer={tradeOffer}
      partnerDesk={partnerDesk}
      onChange={onChangeTab}
    />
  );
};

export default TradeOfferDetailsTabsAdapter;
