import { Typography } from '@mui/material';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';
import { Wrapper } from './controls';

const OfferCodeAdapter = () => {
  const { tradeOffer } = useTradeOfferSidebar();
  if (!tradeOffer) {
    return null;
  }

  return (
    <Wrapper>
      <Typography variant='body2'>Предложение: {tradeOffer.code}</Typography>
    </Wrapper>
  );
};

export default OfferCodeAdapter;
