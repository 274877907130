import { ButtonProps, Fade, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PromotionButtonWrapper } from '../../../../features/offer/trade/details/sidebar/controls';
import { ButtonWrapper, CopyLabel, Overlay, StyledCopyIcon, StyledDottedButton, TextLabel } from './controls';

type CopyButtonProps = ButtonProps & {
  readonly label: string;
  readonly copied: boolean;
  readonly needCopy: boolean;
  readonly onNeedCopy: (value: boolean) => void;
  readonly onCopy?: () => void;
};

const OfferPromotionCopy = ({ label, onCopy, copied, needCopy, onNeedCopy, ...buttonProps }: CopyButtonProps) => {
  return (
    <ButtonWrapper
      onMouseEnter={() => onNeedCopy(true)}
      onMouseLeave={() => onNeedCopy(false)}
    >
      <CopyToClipboard
        text={label}
        onCopy={onCopy}
      >
        <PromotionButtonWrapper>
          <StyledDottedButton
            variant='outlined'
            fullWidth
            copied={copied}
            {...buttonProps}
          >
            <TextLabel color='textPrimary'>{label}</TextLabel>

            <CopyLabel>
              <StyledCopyIcon fontSize='micro' />
              <Typography
                variant='body2'
                color='primary.main'
              >
                скопировать
              </Typography>
            </CopyLabel>
            {buttonProps.children}
            <Fade in={copied && !needCopy}>
              <Overlay>
                <Typography
                  color='success.contrastText'
                  variant='body1'
                  fontWeight={300}
                >
                  Код скопирован
                </Typography>
              </Overlay>
            </Fade>
          </StyledDottedButton>
        </PromotionButtonWrapper>
      </CopyToClipboard>
    </ButtonWrapper>
  );
};

export default OfferPromotionCopy;
